import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.user, action) {
    let wishlisted;
    switch(action.type) {
        case types.LOGIN_USER + types.PENDING:
            return Object.assign({},
                {
                    loading: true
                }
            );
        case types.LOGIN_USER + types.REJECTED:
            return {};
        case types.LOGIN_USER + types.FULFILLED:
            return Object.assign({},
                action.payload.data,
                {
                    loading: false
                }
            );
        case types.UPDATE_USER:
            return Object.assign({},
                action.payload,
                {
                    loading: false
                }
            );
        case types.LOAD_WISHLISTED_PRODUCTS + types.PENDING:
            wishlisted = state.wishlisted === undefined ? {list: [], loading: true} : Object.assign(state.wishlisted, {loading: true});
            return Object.assign({},
                state,
                {
                    wishlisted
                }
            );
        case types.LOAD_WISHLISTED_PRODUCTS + types.REJECTED:
            return Object.assign({},
                state,
                {
                    wishlisted: {
                        list: [],
                        loading: false
                    }
                }
            );
        case types.LOAD_WISHLISTED_PRODUCTS + types.FULFILLED:
            return Object.assign({},
                state,
                {
                    wishlisted: {
                        list: action.payload.data.data,
                        loading: false
                    }
                }
            );
        case types.ADD_TO_WISHLIST + types.FULFILLED:
            wishlisted = state.wishlisted === undefined ? {list: [], loading: false} : state.wishlisted;
            wishlisted.list.push(action.payload.data);
            return Object.assign({},
                state,
                {
                    wishlisted
                }
            );
        case types.REMOVE_TO_WISHLIST + types.FULFILLED:
                let updatedWishlist = state.wishlisted === undefined ? [] : state.wishlisted.splice(0);
                updatedWishlist = updatedWishlist.filter(item => {
                    return item.product_id != action.payload.data.product_id;
                });
                
                return Object.assign({},
                    state,
                    {
                        wishlisted: updatedWishlist
                    }
                );
        case types.LOGOUT_USER:
            return {};
        default: 
            return state;
    }
}
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
            getCustomerDetails,
            updateCustomerDetails
        } from '../../actions/customer.actions';
import history from '../../History';

import UserInfo from './UserInfo';
import UserOrders from './UserOrders';
import UserWishlist from './UserWishlist';

class Account extends React.PureComponent {

    state = {
        activePage: '',
        fetchingData: false
    }

    componentDidMount() {
        if (!this.props.user.hasOwnProperty('name')) {
            history.push('/');
        } else {
            this.getUserDetails();
            this.getRouteSection();
        }
    }

    componentWillUpdate(nextProps) {
        if (!nextProps.user.hasOwnProperty("name")) {
            history.replace("/");
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname != prevProps.location.pathname) {
            this.getUserDetails();
            this.getRouteSection();
        }
    }

    getUserDetails = () => {
        this.setState({
            fetchingData: true
        }, () => {
            getCustomerDetails({id: this.props.user.customer.id}).then(res => {
                if (res.data) {
                    const user = Object.assign({}, this.props.user, {customer: res.data});
                    updateCustomerDetails(user);
                }

                this.setState({
                    fetchingData: false
                });
            });
        });
    }

    render() {
        return (
            <div className="account page-body-container">
                <div className="container margin-top-50">
                    {/* <div className="flex">
                        <button
                            className={`account-page-control ${this.state.activePage === "user-info" ? "active" : ''}`}
                            onClick={() => history.push("/manage-account/user-info")}
                        >
                            USER INFO
                        </button>
                        <button
                            className={`account-page-control ${this.state.activePage === "orders" ? "active" : ''}`}
                            onClick={() => history.push("/manage-account/orders")}
                        >
                            ORDERS
                        </button>
                        <button
                            className={`account-page-control ${this.state.activePage === "wishlists" ? "active" : ''}`}
                            onClick={() => history.push("/manage-account/wishlists")}
                        >
                            WISHLISTS
                        </button>
                    </div> */}
                    <div className="margin-top-30">
                        {
                            this.state.activePage === "user-info" && (
                                <UserInfo loading={this.state.fetchingData}/>
                            )
                        }
                        {
                            this.state.activePage === "orders" && (
                                <UserOrders loading={this.state.fetchingData}/>
                            )
                        }
                        {
                            this.state.activePage === "wishlists" && (
                                <UserWishlist loading={this.state.fetchingData}/>
                            )
                        }
                    </div>
                </div>
            </div>
       )
    }

    getRouteSection() {
        if (this.props.user.hasOwnProperty("name")) {
            const activePage = this.props.location.pathname.replace("/manage-account/", "");
            this.setState({
                activePage
            });
        } else {
            history.push('/');
        }
    }
};


function mapStateToProps(state, ownProps) {
	return {
        user: state.user
	};
}

Account.propTypes = {
    user: PropTypes.object
};

export default connect(mapStateToProps, null)(Account);
import React, {Fragment} from 'react';
import {
        extractAssetObj,
        slugify,
        returnTest
    } from '../../../helpers/caboodleHelper';
import {
        setSelectedBlog
    } from '../../../actions/blogs.actions';
import history from '../../../History';

const ArticleCard = ({loading, article, onSelectBlog}) => {
    return (
        <div className="article-card">
            {
                loading ? (
                    <Fragment>
                        <div className="img-container ph-item">
                            <img
                                src=''
                                className="article-image"
                            />
                        </div>
                        <div className="article-text-info pad-30">
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        <div className="ph-col-8 big" />
                                        <div className="ph-col-6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="img-container">
                            <img
                                src={extractAssetObj(article, ["image", "path"])}
                                className="article-image"
                            />
                        </div>
                        <div className="article-text-info pad-30">
                            <div className="txt-small txt-uppercase">
                                BLOG
                            </div>
                            <div className="txt-large txt-bold margin-top-10">
                                {article.title}
                            </div>
                            <button
                                className="btn btn-clear black btn-sqr txt-bold txt-uppercase margin-top-30"
                                onClick={() => goToBlog(article)}
                            >
                                READ NOW
                            </button>
                        </div>
                    </Fragment>
                )
            }
        </div>
    )
};

const goToBlog = (article) => {
    const slug = slugify(article.title);
    setSelectedBlog(article);
    history.push(`/blogs/${slug}/view`);

}

export default ArticleCard;
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.shipping_zone, action) {
    switch(action.type) {

        case types.GET_SHIPPING_ZONE_LIST +types.FULFILLED: {
            return Object.assign({},
                state,
                {
                    list: action.payload.data
                }
            );
        }

        default: 
            return state;
    }
}
import * as types from '../constants/actionTypes';
import {store} from '../index';

export function setSelectedBlog(blog) {
    return store.dispatch({
        type: types.SET_SELECTED_BLOG,
        payload: blog
    });
}

export function getSelectedBlog(product) {
    console.log(123);
}
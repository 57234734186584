import React from 'react';
import { Collapse } from 'reactstrap';
import asiaPayImg from '../../../assets/img/asiapay.png';
class PaymentMethod extends React.PureComponent {
    state = {
        slug: 'cod'
    }
    
    componentDidMount() {
        if (!this.props.codValid) {
            this.selectPayment("paypal");
        }
    }

    selectPayment = (slug) => {
        this.setState({
            slug
        }, () => {
            this.props.onSelectPayment(slug);
        })
    }

    render() {
        const { slug } = this.state;

        return (
            <div className="payment-method">
                {
                    this.props.codValid  && (
                        <div onClick={() => {
                            this.selectPayment("cod");
                        }} 
                            className={`collapse-header ${slug === 'cod' ? 'active' : ''}`}>
                            <i className={this.props.order.delivery_option === "pickup" ? "far fa-handshake" : "fas fa-truck"} />
                            <div className="text">
                                Cash on {this.props.order.delivery_option === "pickup" ? "Pickup" : "Delivery"}
                            </div>
                            <i className="fas fa-check-circle" />
                        </div>
                    )
                }
                {/* <Collapse
                    isOpen={slug === 'cod'}
                >
                    <div className="collapse-content">
                        <div className="flex flex-flow-column">
                            <p className="flex-1">
                                COD Shipping Fee (Additional)
                            </p>
                            <p>
                                PHP 45.00
                            </p>
                            <p className="flex-1">
                                COD Added Fee
                            </p>
                            <p>
                                PHP 25.00
                            </p>
                            <p className="flex-1">
                                COD Insurance Fee
                            </p>
                            <p>
                                PHP 5.00
                            </p>
                        </div>
                    </div>
                </Collapse> */}
                <div onClick={() => {
                    this.selectPayment("paypal");
                }} 
                    className={`collapse-header ${slug === 'paypal' ? 'active' : ''}`}>
                    <i className="fab fa-cc-paypal" />
                    <div className="text">
                        Credit or Debit Card via Paypal
                    </div>
                    <i className="fas fa-check-circle" />
                </div>
                <div onClick={() => {
                    this.selectPayment("asiapay");
                }} 
                    className={`collapse-header ${slug === 'asiapay' ? 'active' : ''}`}>
                    <img src={asiaPayImg} />
                    <div className="text">
                        Credit or Debit Card via Asiapay
                    </div>
                    <i className="fas fa-check-circle" />
                </div>
            </div>
       );
    }
}


export default PaymentMethod;
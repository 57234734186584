import React from 'react';
import TextInput from '../Form/TextInput';
import SuccessMessage from '../Modal/SuccessMessage';
import {
            validateFormObj,
            userRegister,
            toggleModal,
            toggleLoginModal,
            setModalContent
        } from '../../../helpers/caboodleHelper';

class Registration extends React.PureComponent {

    state = {
        registrationForm: {
            email: "",
            password: "",
            cpassword: ""
        },
        isLaoding: false,
        errorMessage: ''
    }

    componentDidMount() {}

    componentWillMount() {}

    onInputChange = (e) => {
        let registrationForm = Object.assign({}, this.state.registrationForm);
        
        if (e.target.name !== "remember") {
            registrationForm[e.target.name] = e.target.value;
        } else {
            registrationForm.remember = e.target.checked;
        }

        this.setState({
            registrationForm
        });
    }

    openLoginModal() {
        toggleModal();
        setTimeout(() => {
            toggleLoginModal();
        }, 300);
    }

    doRegistration = () => {
        const registrationForm = Object.assign({}, this.state.registrationForm);

        if (registrationForm.password === registrationForm.cpassword) {
            delete registrationForm.cpassword;
            this.setState({
                isLoading: true,
                errorMessage: ''
            }, () => {
                userRegister(registrationForm)
                    .then(res => {
                        // console.log(res);
                        const successMessage = (
                            <SuccessMessage
                                title="Successful!"
                                message={res.message}
                            />
                        );

                        toggleModal();
                        this.setState({
                            registrationForm: {
                                email: "",
                                password: "",
                                remember: false
                            },
                            isLoading: false,
                            errorMessage: ''
                        });

                        setTimeout(() => {
                            setModalContent(false, false, successMessage);
                            toggleModal();
                        }, 300);
                        
                    }).catch(err => {
                        this.setState({
                            isLoading: false,
                            errorMessage: "Email address is already registered."
                        });
                    });
            });
        } else {
            this.setState({
                errorMessage: "Password confirmation did not match."
            });
        }
    }

    render() {
        return (
            <div className="login">
                <div className="icon-badge">
                    <i className="far fa-pencil" />
                </div>
                <div className="login-header txt-header-2 text-center">
                    REGISTER
                </div>
                <div className="login-form">
                    <TextInput
                        placeholder="Email Address"
                        name="email"
                        value={this.state.registrationForm.email}
                        onChange={this.onInputChange}
                        onKeyEnter={validateFormObj(this.state.registrationForm) ? this.doRegistration : false}
                    />
                    <TextInput
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.registrationForm.password}
                        onChange={this.onInputChange}
                        onKeyEnter={validateFormObj(this.state.registrationForm) ? this.doRegistration : false}
                    />
                    <TextInput
                        placeholder="Re-type Password"
                        type="password"
                        name="cpassword"
                        value={this.state.registrationForm.cpassword}
                        onChange={this.onInputChange}
                        onKeyEnter={validateFormObj(this.state.registrationForm) ? this.doRegistration : false}
                    />
                    <div className="text-center txt-error txt-small">
                        {this.state.errorMessage}
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-black btn-sqr btn-block btn-block-center submit-btn"
                            disabled={!validateFormObj(this.state.registrationForm) || this.state.isLoading}
                            onClick={() => this.doRegistration()}
                        >
                            Register
                        </button>
                    </div>
                    <div className="registration-link text-center txt-small">
                        <span>Already a member? </span>
                        {/* eslint-disable-next-line */}
                        <a
                            className="giga-link clickable"
                            onClick={() => this.openLoginModal()}
                        >
                            Login
                        </a>
                    </div>
                </div>
            </div>
       )
    }
};

export default Registration;
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {matchPath} from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
        makeASquare,
        getPage,
        extractAssetObj,
        slugify
    } from '../../helpers/caboodleHelper';
import {
        setSelectedBlog
    } from '../../actions/blogs.actions';
import ArticleCard from '../_global/ArticleCard/ArticleCard';
import ProductCard from '../_global/ProductCard/ProductCard';

class BlogView extends React.Component {

    componentDidMount() {
        if (this.props.page.blogs === undefined) {
            getPage('blogs').then(res => {
                let slug = (this.props.location.pathname.replace("/blogs/", '')).replace("/view", '');
                const selectedBlog = this.props.page.blogs.contents.find(blog => {
                                            return slugify(blog.title) === slug;
                                        });

                setSelectedBlog(selectedBlog);
            });
        }
        
        makeASquare(document.getElementsByClassName("article-image"), "width", 0.8);
        makeASquare(document.getElementsByClassName("product-container-image"));
        window.addEventListener("resize", this.handleMakeASquare);
    }
    
    componentDidUpdate() {
        makeASquare(document.getElementsByClassName("article-image"), "width", 0.8);
        makeASquare(document.getElementsByClassName("product-container-image"));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleMakeASquare);
    }

    handleMakeASquare = () => {
        makeASquare(document.getElementsByClassName("article-image"), "width", 0.8);
        makeASquare(document.getElementsByClassName("product-container-image"));
    }

    render() {
        const {page} = this.props;
        const {blogs} = page;
        const selected = this.props.selectedBlog;

        if (!blogs || page.loading || Object.keys(selected).length === 0) {
            return (
                <div className="blogs page-body-container">
                    <div className="page-banner-image ph-item" />
                    <div className="page-container ph-item">
                        <div className="ph-col-6">
                            <div className="ph-row">
                                <div className="ph-col-12 big" />
                                <div className="ph-col-6" />
                            </div>
                            <div className="margin-top-30" />
                            <div className="ph-row">
                                {
                                    [...Array(15)].map((arr, i) => {
                                        return(
                                            // eslint-disable-next-line
                                            <div key={i} className={`ph-col-${i%2 == 0 ? '12' : (parseInt(Math.random() * (10 - 1) + 1)%2 == 0 ? '10' : '8')}`} />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="blogs page-body-container">
                {
                    Object.keys(selected).length > 0 ? (
                        <Fragment>
                            <div
                                className="page-banner-image flex align-center"
                                style={{backgroundImage: `url(${extractAssetObj(selected, ["image", "path"])})`}}
                            />
                            <div className="page-container">
                                <div className="flex justify-center blog-view-outer-container">
                                    <div className="margin-right-100 blog-content">
                                        <div className="txt-header margin-top-50 txt-bold">
                                            {selected.title}
                                        </div>
                                        <h1 className="txt-gray txt-large">
                                            {moment(selected.created_at.date).format('MMMM DD, YYYY')} {selected.author && ("by " + selected.author)}
                                        </h1>
                                        <div
                                            className="margin-top-50"
                                            dangerouslySetInnerHTML={{__html: selected.content}}
                                        />
                                        <div className="author-details flex align-center margin-top-100">
                                            <div className="author-image">
                                                <img src={extractAssetObj(selected, ["author_image", "path"])} />
                                            </div>
                                            <div className="flex-1 margin-left-20">
                                                <h2 className="txt-xlarge txt-bold">
                                                    {selected.author}
                                                </h2>
                                                <div className="txt-large">
                                                    {selected.author_title}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="share-list flex align-center margin-top-50">
                                            <div className="margin-right-30 txt-bold">
                                                Share:
                                            </div>
                                            <i className="fab fa-facebook-f margin-right-20 txt-large clickable" />
                                            <i className="fab fa-twitter txt-large clickable" />
                                        </div>
                                    </div>
                                    <div className="related-products">
                                        <div className="txt-xxlarge margin-bottom-20 margin-top-50">
                                            Related Products
                                        </div>
                                        <div className="related-products-container">
                                            {
                                                selected.related_products.map((product, index) => {
                                                    return(
                                                        <div
                                                            className="float-in-up-down margin-bottom-30"
                                                            style={{animationDelay: `${index * 0.2}s`}}
                                                            key={index}
                                                        >
                                                            <ProductCard key={index} product={product} />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-container related-articles margin-top-50">
                                <div className="txt-xlarge txt-bold text-center margin-top-50">
                                    Articles For You
                                </div>
                                <div className="margin-top-50 grid grid-3 blog-list">
                                    {
                                        blogs.contents.map((blog, key) => {
                                            return (
                                                <div key={key}>
                                                    <ArticleCard
                                                        article={blog}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="text-center txt-xxlarge">
                            Sorry, there is nothing to see here.
                        </div>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
	return {
        page: state.page,
        selectedBlog: state.selectedBlog
	};
}

BlogView.propTypes = {
    page: PropTypes.object
};

export default connect(mapStateToProps, null)(BlogView);

export default {
    page: {
    },
    brands: {
        loading: false
    },
    user: {},
    products: {
        list: [],
        loading: true
    },
    collections: [],
    categories: {
        list: []
    },
    reviews: {
        loading: false,
        list: []
    },
    cart: {
        items: []
    },
    modal: {
        open: false,
        content: '',
        animateIn: 'zoomIn',
        animateOut: 'zoomOut'
    },
    order: {},
    select_options: {
        province_list: [],
        city_list: [],
        area_list: []
    },
    shipping_form: {
        errors: {},
        inputs: {
            firstname: '',
            lastname: '',
            email: '',
            address_line_1: '',
            country_id: '1',
            province_id: '',
            city_id: '',
            area_id: '',
            mobile: ''
        },
        valid: false
    },
    site_options: {
        loading: false
    },
    shipping_zone: {

    },
    selectedBlog: {}
};

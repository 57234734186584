import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
        extractAssetObj
    } from '../../../helpers/caboodleHelper';
import history from '../../../History';
import FormattedTotal from '../Elements/FormattedTotal';

class RelatedProductsSlider extends React.PureComponent {
    state = {
        activeSlide: {},
        lastActiveSlide: {},
        activeSlideDimensions: {width: 0, height: 0},
        activeSlideIndex: 0,
        controlsDisabled: false,
        slideMovement: "",
        autoPlayInterval: false,
        autoPlayDelay: 5000,
        componentID: `cmp-${parseInt(Math.random() * 100)}`,
        pauseState: false
    };
    resizeTimeout;

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate() {
        if (Object.keys(this.state.activeSlide).length > 0 && (this.props.products && this.props.products.related && this.props.products.related.list && this.props.products.related.list.length === 0)) {
            clearInterval(this.state.autoPlayInterval);
            this.setState({
                activeSlide: {},
                lastActiveSlide: {}
            });
        }

        if (Object.keys(this.state.activeSlide).length === 0 && (this.props.products && this.props.products.related && this.props.products.related.list && this.props.products.related.list.length > 0)) {
            this.initializeSlider();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
        clearInterval(this.state.autoPlayInterval);

        this.setState({
            autoPlayInterval: false
        });
    }

    initializeSlider = () => {
        if (Object.keys(this.state.activeSlide).length == 0 && this.props.products && this.props.products.related && this.props.products.related.list.length > 0) {
            clearInterval(this.state.autoPlayInterval);
            this.setState({
                activeSlide: this.props.products.related.list[0],
                lastActiveSlide: this.props.products.related.list[0],
                activeSlideIndex: 0,
                autoPlayInterval: false,
                slideMovement: ""
            }, () => {
                this.handleWindowResize();

                if (this.props.products.related.list.length > 1) {
                    this.setState({
                        autoPlayInterval: setInterval(() => {
                                                this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.products.related.list.length ? (this.state.activeSlideIndex + 1) : 0, "next");
                                            }, this.state.autoPlayDelay)
                    });
                }
            });
        }
    }

    handleSetActiveSlide = (index, movement) => {
        const activeSlide = Object.assign({}, this.state.activeSlide ? this.state.activeSlide : {});
        clearInterval(this.state.autoPlayInterval);

        if (activeSlide && this.props.products && this.props.products.related && this.props.products.related.list) {
            this.setState({
                activeSlide: this.props.products.related.list[index],
                lastActiveSlide: activeSlide,
                activeSlideIndex: index,
                controlsDisabled: true,
                slideMovement: movement ? movement : (index > this.state.activeSlideIndex ? "next" : "prev"),
                autoPlayInterval: false
            }, () => {
                setTimeout(() => {
                    let autoPlayInterval = "";

                    if (!this.state.pauseState) {
                        autoPlayInterval = setInterval(() => {
                                                this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.products.related.list.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                            }, this.state.autoPlayDelay);
                    }
                    
                    this.setState({
                        controlsDisabled: false,
                        slideMovement: "",
                        autoPlayInterval
                    });
                }, 600);
            });
        }
    }
    
    handleWindowResize = () => {
        if (this.props.products.related.list.length > 0) {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }
    
            this.resizeTimeout = setTimeout(() => {
                const activeImage = document.querySelector(`.main-image-${this.state.componentID}`);

                if (activeImage) {
                    this.setState({
                        activeSlideDimensions: {
                            width: activeImage.clientWidth,
                            height: activeImage.clientHeight
                        }
                    });
                }
            }, 500);
        }
    }

    handleSetPause = (pauseState) => {
        let autoPlayInterval = this.state.autoPlayInterval;
        
        if (pauseState) { 
            clearInterval(this.state.autoPlayInterval);
            autoPlayInterval = false;
        } else {
            if (!this.state.autoPlayInterval) {
                autoPlayInterval = setInterval(() => {
                                        this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.products.related.list.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                    }, this.state.autoPlayDelay);
            }
        }

        this.setState({
            pauseState,
            autoPlayInterval
        });
    }
    
    render() {
        const { products } = this.props,
                related = products ? (products.related ? products.related : null) : null;
        const { activeSlide,
                lastActiveSlide,
                activeSlideDimensions,
                activeSlideIndex
            } = this.state;
            
        return (
            <Fragment>
                <div className="txt-xlarge margin-bottom-50 txt-bold">
                    Related Products
                </div>
                {
                    related && related.loading && Object.keys(activeSlide).length === 0 ? (
                        <div className="ph-item">
                            <div>
                                <div className="fullwidth-product-card">
                                    <div className="product-details flex align-center">
                                        <div className="flex-1">
                                            <div className="txt-small txt-gray-med txt-bold txt-uppercase txt-bold">
                                                <div className="ph-item">
                                                    <div className="ph-col-12">
                                                        <div className="ph-row">
                                                            <div className="ph-col-6" />
                                                            <div className="ph-col-8" />
                                                            <div className="ph-col-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`fullwidth-product-card related-products-slider ${this.state.slideMovement !== '' && 'in-transition'}`}
                            onMouseEnter={() => this.handleSetPause(true)}
                            onMouseLeave={() => this.handleSetPause(false)}
                        >
                            <div className={`main-image main-image-${this.state.componentID} fade-in`}>
                                <img src={extractAssetObj(activeSlide, ["image_paths", "path"])} alt={activeSlide.title} />
                            </div>
                            <div className={`transition-overlay-image ${this.state.slideMovement}`}>
                                <img
                                    className="transition-image"
                                    src={extractAssetObj(lastActiveSlide, ['image_paths', 'path'])}
                                    style={{width: activeSlideDimensions.width, height: activeSlideDimensions.height}}
                                    alt={lastActiveSlide.title}
                                />
                            </div>
                            <div className="product-details flex align-center float-in-up-down">
                                <div className="flex-1 details-container">
                                    <div className="txt-small txt-gray-med txt-bold txt-uppercase txt-bold">
                                        {activeSlide.product_category && activeSlide.product_category.title}
                                    </div>
                                    <div className="product-name txt-medium txt-bold">
                                        {activeSlide.title}
                                    </div>
                                    <div className="product-price txt-small">
                                        {
                                            activeSlide && activeSlide.formatted_price && (
                                                <FormattedTotal
                                                    formattedPrice={activeSlide.formatted_price}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="btn-container">
                                    <button
                                        className="btn btn-lgray"
                                        onClick={() => history.push(`/product/${activeSlide.slug}/view`)}
                                    >
                                        View Product
                                    </button>
                                </div>
                                {
                                    related && related.list && related.list.length > 1 && (
                                        <div className="list-control flex align-items margin-left-50">
                                            <button
                                                className={`btn btn-icon btn-lgray scale-hover no-transition ${this.state.controlsDisabled && "no-pointer"}`}
                                                onClick={() => this.handleSetActiveSlide((activeSlideIndex - 1) >= 0 ? (activeSlideIndex - 1) : (related.list.length - 1), "prev")}
                                            >
                                                <i className="far fa-chevron-left" />
                                            </button>
                                            <button
                                                className={`btn btn-icon btn-black scale-hover margin-left-10 no-transition ${this.state.controlsDisabled && "no-pointer"}`}
                                                onClick={() => this.handleSetActiveSlide((activeSlideIndex + 1) < related.list.length ? (activeSlideIndex + 1) : 0, "next")}
                                            >
                                                <i className="far fa-chevron-right" />
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </Fragment>
        );
    }
}

RelatedProductsSlider.propTypes = {
    products: PropTypes.object
};

function mapStateToProps(state, ownProps) {
	return {
        products: state.products
	};
}

export default connect(mapStateToProps, null)(RelatedProductsSlider);


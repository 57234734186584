import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { extractAssetObj, makeASquare } from '../../helpers/caboodleHelper';
import ProductList from '../_global/ProductList/ProductList';

class CollectionView extends React.PureComponent {
    state = {}

    componentWillMount() {
    }

    componentDidMount() {
        window.addEventListener("resize", this.initMakeASquare);
        makeASquare(document.getElementsByClassName(`product-container-image`), "width", 1);
    }

    componentDidUpdate() {
        this.initMakeASquare();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.initMakeASquare);
    }

    initMakeASquare = () => {
        makeASquare(document.getElementsByClassName(`product-container-image`), "width", 1);
    }

    render() {
        const {selected} = this.props.collections;

        return (
            <div className="home page-body-container">
                <div className="page-banner-image" style={{backgroundImage: `url(${extractAssetObj(selected, ['asset', 'path'])})`}}/>
                <div className="page-container">
                    <div className="flex flex-rm-md">
                        <div className="flex-1">
                            <div className="txt-header-2">
                                {selected.name}
                            </div>
                            <div className="txt-medium margin-top-20 margin-bottom-50" dangerouslySetInnerHTML={{ __html: selected.description }}/>
                        </div>
                        <div className="selected-collection-products flex-1">
                            <ProductList
                                loading={false}
                                products={selected && selected.products}
                                placeholderCardLength={9}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



CollectionView.propTypes = {
    collactions: PropTypes.array,
    loading: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
	return {
        collections: state.collections
	};
}

export default connect(mapStateToProps, null)(CollectionView);


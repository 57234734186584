// import * as apiHelper from '../helpers/apiHelper';
import * as types from '../constants/actionTypes';


export function changeShippingFormState(params, name) {
	return {
		type: types.CHANGE_SHIPPING_FORM_STATE,
		payload: {
			params,
			name
		}
	};
}

import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';

import {
    extractAssetObj,
    makeASquare
} from '../../../helpers/caboodleHelper';

class Slider extends Component {
    state = {
        activeSlide: {},
        activeSlideIndex: 0,
        lastActiveSlide: false,
        lastActiveSlideIndex: 0,
        inTransition: false,
        autoPlayInterval: false,
        controlsDisabled: false,
        autoPlayDelay: 6000
    };

    componentDidMount() {
        this.initializeSlider();
        window.addEventListener("resize", this.handleMakeASquare);
    }

    componentDidUpdate() {
        this.initializeSlider();
        this.handleMakeASquare();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleMakeASquare);
    }

    render () {
        const {
            activeSlide,
            lastActiveSlide,
            activeSlideIndex
        } = this.state;

        return (
            <div className={`slider ${this.state.slideMovement && "in-transition"}`}>
                {
                    Object.keys(lastActiveSlide).length > 0 && (
                        <div
                            className="last-active-slide pad-100"
                            style={{
                                backgroundImage: `url(${extractAssetObj(lastActiveSlide, ['image', 'path'])})`
                            }}
                        >
                            <div>
                                <h1
                                    className="txt-oversized txt-white">
                                    {lastActiveSlide.title}
                                </h1>
                                <h2
                                    className="txt-regular txt-header txt-white"
                                    dangerouslySetInnerHTML={{__html: lastActiveSlide.content.substr(0, 50)}}
                                />
                                <button
                                    className="btn btn-clear white btn-sqr txt-bold btn-pad-xlarge margin-top-30"
                                >
                                    <span className="txt-xlarge">
                                        {lastActiveSlide.button_text}
                                    </span>
                                </button>
                            </div>
                        </div>
                    )
                }
                {
                    Object.keys(activeSlide).length > 0 && (
                        <div
                            className={`active-slide ${this.state.slideMovement && "fade-in"} pad-100`}
                            style={{
                                backgroundImage: `url(${extractAssetObj(activeSlide, ['image', 'path'])})`
                            }}
                        >
                            <div>
                                <h1
                                    className={`txt-oversized txt-white ${this.state.slideMovement && "fade-in-up"}`}
                                    style={{
                                        animationDelay: "0.5s"
                                    }}
                                >
                                    {activeSlide.title}
                                </h1>
                                <h2
                                    className={`txt-regular txt-header txt-white ${this.state.slideMovement && "fade-in-up"}`}
                                    dangerouslySetInnerHTML={{__html: activeSlide.content.substr(0, 50)}}
                                    style={{
                                        animationDelay: "0.7s"
                                    }}
                                />
                                <button
                                    className={`btn btn-clear white btn-sqr txt-bold btn-pad-xlarge margin-top-30 ${this.state.slideMovement && "fade-in-up"}`}
                                    style={{
                                        animationDelay: "0.9s"
                                    }}
                                >
                                    <span className="txt-xlarge">
                                        {activeSlide.button_text}
                                    </span>
                                </button>
                            </div>
                        </div>
                    )
                }
                {
                    this.props.data && this.props.data.length > 1 && (
                        <Fragment>
                            <button
                                className="slider-control flex align-center justify-center txt-header prev-btn"
                                disabled={this.state.controlsDisabled}
                                onClick={() => this.handleSetActiveSlide(activeSlideIndex - 1, 'prev')}
                            >
                                <i className="far fa-angle-left" />
                            </button>
                            <button
                                className="slider-control flex align-center justify-center txt-header next-btn"
                                disabled={this.state.controlsDisabled}
                                onClick={() => this.handleSetActiveSlide(activeSlideIndex + 1, 'next')}
                            >
                                <i className="far fa-angle-right" />
                            </button>
                            <div className={`slider-thumbnails flex align-center justify-center ${this.state.slideMovement}`}>
                                {
                                    this.props.data && this.props.data.map((slide, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`thumb clickable ${this.state.activeSlideIndex === index ? 'active' : ''} ${this.state.lastActiveSlideIndex === index ? 'active-out' : ''}`}
                                                onClick={() => this.handleSetActiveSlide(index, this.state.activeSlideIndex > index ? 'prev' : 'next')}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                }
            </div>
        );
    }

    handleMakeASquare = () => {
        makeASquare(document.getElementsByClassName("slider"), "width", 0.4);
    }

    initializeSlider = () => {
        if (Object.keys(this.state.activeSlide).length == 0 && this.props.data) {
            this.setState({
                activeSlide: this.props.data[0],
                lastActiveSlide: this.props.data[0]
            }, () => {
                if (this.props.data.length > 1) {
                    // this.handleWindowResize();
                    this.setState({
                        autoPlayInterval: setInterval(() => {
                                                this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.data.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                            }, this.state.autoPlayDelay)
                    });
                }
            });
        }
    }

    handleSetActiveSlide = (index, movement) => {
        const activeSlide = Object.assign({}, this.state.activeSlide);
        clearInterval(this.state.autoPlayInterval);

        if (index > this.props.data.length - 1) {
            index = 0;
        }

        if (index < 0) {
            index = this.props.data.length - 1;
        }

        this.setState({
            activeSlide: this.props.data[index],
            lastActiveSlide: activeSlide,
            lastActiveSlideIndex: this.state.activeSlideIndex,
            activeSlideIndex: index,
            controlsDisabled: true,
            slideMovement: movement ? movement : (index > this.state.activeSlideIndex ? "next" : "prev")
        }, () => {
            setTimeout(() => {
                if (this.props.data.length > 1) {
                    this.setState({
                        controlsDisabled: false,
                        slideMovement: "",
                        autoPlayInterval: setInterval(() => {
                                                this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.data.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                            }, this.state.autoPlayDelay)
                    });
                }
            }, 1500);
        });
    }
};

export default Slider;
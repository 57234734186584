import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.brands, action) {
    switch(action.type) {
        case types.GET_BRANDS_LIST + types.PENDING:
            return Object.assign({},
                {
                    loading: true
                }
            );
        case types.GET_BRANDS_LIST + types.REJECTED:
            return {loading: false};
        case types.GET_BRANDS_LIST + types.FULFILLED:
            return Object.assign({},
                state,
                {
                    list: action.payload.data,
                    loading: false
                }
            );
        default: 
            return state;
    }
}
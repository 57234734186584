import React, {Fragment} from 'react';
import sha1 from 'sha1';
import Price from '../../_global/Elements/Price';
import FormattedTotal from '../../_global/Elements/FormattedTotal';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import {
            PAYPAL_CLIENT,
            PAYPAL_ENV,
            SITE_URL
        } from '../../../constants/config';

const OrderSummary = ({
    order,
    btnText,
    btnOnClick,
    couponInput,
    btnLoading,
    orderLoading,
    shipping_rate,
    invalidProducts,
    payment,
    paypalHandles,
    couponData,
    serviceFee,
    aispayEncodedData
}) => {
    const skeletonCount = 5;
    let skeletons = [];
    
    for (let i = 0; i <= skeletonCount; i++) {
        skeletons.push(
            <Fragment key={i}>
                <div className="loading-container pad-10">
                    <div className="ph-item" />
                </div>
                <div className="loading-container pad-10">
                    <div className="ph-item" />
                </div>
                <div className="loading-container pad-10">
                    <div className="ph-item" />
                </div>
            </Fragment>
        );
    }

    if (orderLoading) {
        return (
            <div className="card-container">
                <div className="column-3 txt-large txt-bold pad-10">
                    Order Summary
                </div>
                <div className="list-header pad">
                    Product
                </div>
                <div className="list-header pad text-center">
                    Quantity
                </div>
                <div className="list-header pad text-right">
                    Price
                </div>
                {skeletons}
            </div>
        );
    }

    let shipping_fee = 0;
    if (shipping_rate && shipping_rate.weight) {
        shipping_fee = shipping_rate.weight;
    }
    if (shipping_rate && shipping_rate.price) {
        shipping_fee = shipping_rate.price;
    }

    return (
        <div>
            <div className="card-container">
                <div className="column-3 txt-large txt-bold pad-10">
                    Order Summary
                </div>
                <div className="list-header pad-10">
                    Product
                </div>
                <div className="list-header text-center pad-10">
                    Quantity
                </div>
                <div className="list-header text-right pad-10">
                    Price
                </div>
                {order && order.items && order.items.length > 0 && order.items.map((item, key) => (
                    <Fragment key={key}>
                        <div className="product-title pad-10">
                            {item && item.product && item.product.title}
                        </div>
                        <div className="product-qty text-center pad-10">
                            {item && item.product && item.quantity}
                        </div>
                        <div className="product-price text-right pad-10">
                            <FormattedTotal
                                formattedPrice={item.product.formatted_price}
                                qty={1}
                            />
                        </div>
                    </Fragment>
                ))
                }
                <div className="column-2 pad-10 txt-bold">
                    Subtotal
                </div>
                <div className="subtotal text-right pad-10 txt-bold">
                    <FormattedTotal
                        formattedPrice={order.converted_subtotal}
                        qty={1}
                    />
                </div>
                {
                    shipping_fee !== 0 &&
                    <>
                        <div className="column-2 pad-10">
                            Shipping Fee
                        </div>
                        <div className="shipping-fee text-right pad-10 txt-bold">
                            <FormattedTotal
                                formattedPrice={order.converted_subtotal.split(" ")[0] + " " + shipping_fee}
                                qty={1}
                            />
                        </div>
                    </>
                }
                {
                    payment === 'paypal' || payment === 'asiapay' &&
                    <>
                        <div className="column-2 pad-10">
                            Service Fee
                        </div>
                        <div className="shipping-fee text-right pad-10 txt-bold">
                            <FormattedTotal
                                formattedPrice={order.converted_subtotal.split(" ")[0] + " " + serviceFee}
                                qty={1}
                            />
                        </div>
                    </>
                }
                {couponInput}
                <div className="total column-2 pad-10">
                    Total
                </div>
                <div className="total pad-10 txt-bold text-right">
                    <FormattedTotal
                        formattedPrice={order.converted_total}
                        deduction={couponData && couponData.absoluteValue}
                        additional={parseFloat(shipping_fee) + ((payment === 'paypal' || payment === 'asiapay') ? parseFloat(serviceFee) : 0)}
                        qty={1}
                    />
                </div>
            </div>
            {
                // displayInvalidProducts(invalidProducts)
            }
            {
                payment === 'paypal' ? (
                    <div className="margin-top-20">
                        <PaypalExpressBtn 
                            env={PAYPAL_ENV}
                            client={PAYPAL_CLIENT}
                            currency={order.currency ? order.currency.short_name.toUpperCase() : "PHP"}
                            total={handleTotalProcessing(order.converted_total, couponData.absoluteValue, (parseFloat(shipping_fee) + (payment === 'paypal' ? parseFloat(serviceFee) : 0)))}
                            onError={paypalHandles.onError}
                            onSuccess={paypalHandles.onSuccess}
                            onCancel={paypalHandles.onCancelled}
                            style={{height: 40, label: "pay"}}
                        />
                    </div>
                ) : (
                    payment === "asiapay" ? (
                        <form name="payFormCcard" method="POST" action="https://www.pesopay.com/b2c2/eng/payment/payForm.jsp">
                            <input type="hidden" name="merchantId" value="18153965"/>
                            <input type="hidden" name="orderRef" value={order.reference_number}/>
                            <input type="hidden" name="currCode" value="608" />
                            <input type="hidden" name="redirect" value="3" />
                            <input type="hidden" name="amount" value={handleTotalProcessing(order.converted_total, couponData.absoluteValue, (parseFloat(shipping_fee) + (payment === 'asiapay' ? parseFloat(serviceFee) : 0)))} />
                            <input type="hidden" name="mpsMode" value="NIL" /> 
                            <input type="hidden" name="successUrl" value={`${SITE_URL}/checkout/payment?apgwsc=${parseInt(Math.random() * 99999)}&cid=${aispayEncodedData}`}/>
                            <input type="hidden" name="failUrl" value={`${SITE_URL}/checkout/my-cart`}/>
                            <input type="hidden" name="cancelUrl" value={`${SITE_URL}/checkout/my-cart`}/>
                            <input type="hidden" name="payType" value="H"/>
                            <input type="hidden" name="lang" value="E"/>
                            <input type="hidden" name="payMethod" value="CC"/>
                            <input type="hidden" name="secureHash" value={sha1(`18153965|${order.reference_number}|608|${handleTotalProcessing(order.converted_total, couponData.absoluteValue, (parseFloat(shipping_fee) + (payment === 'asiapay' ? parseFloat(serviceFee) : 0)))}|H|gZhZvmFv11mSMJVQguRBPoCMF0ss0yNp`).toLowerCase()}/>
                            {/* <input type="submit" className="btn btn-secondary btn-sqr margin-top-20 btn-block txt-bold" name="submit"/> */}
                            <button
                                className="btn btn-secondary btn-sqr margin-top-20 btn-block txt-bold"
                            >
                                PAY VIA ASIAPAY
                            </button>
                        </form>
                    ) : (
                        <button
                            disabled={btnLoading}
                            onClick={btnOnClick} 
                            className="btn btn-secondary btn-sqr margin-top-20 btn-block txt-bold"
                        >
                            {btnText}
                        </button>
                    )
                )
            }
        </div>
    );
};

const handleTotalProcessing = (formattedPrice, deduction = 0, additionals = 0) => {
    const formattedArray = formattedPrice.split(" ");
    let newPrice = (parseFloat(formattedArray[1].replace("," , "")) + parseFloat(additionals)) - parseFloat(deduction);
    return parseFloat(newPrice).toFixed(2);
}

export default OrderSummary;
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { sendInquiry, validateFormObj, getSiteOption } from '../../helpers/caboodleHelper';
import EllipsisLoader from '../_global/Loaders/EllipsisLoader';
import pageBG from '../../assets/img/contact.jpg';

class ContactUs extends React.PureComponent {

    state = {
        inquiryForm: {
            full_name: '',
            email: '',
            message: ''
        },
        loading: false,
        sent: false
    };

    componentDidMount() {
        const slug = ["contact-email", "contact-address", "contact-number"];
        getSiteOption({slug});
    }

    componentWillUnmount() {}

    onInputFormChange(e) {
        let inquiryForm = Object.assign({}, this.state.inquiryForm);
        inquiryForm[e.target.name] = e.target.value;

        this.setState({
            inquiryForm
        });
    }

    submitInquiry() {
        let inquiryForm = Object.assign({}, this.state.inquiryForm);
        inquiryForm.misc = Object.assign({}, this.state.inquiryForm);

        this.setState({
            loading: true
        }, () => {
            sendInquiry(inquiryForm).then(res => {
                setTimeout(() => {
                    this.setState({
                        inquiryForm: {
                            full_name: '',
                            email: '',
                            message: ''
                        },
                        loading: false,
                        sent: true
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                sent: false
                            });
                        }, 1000);
                    });
                }, 500);
            });
        });
    }

    render() {
        const {site_options} = this.props;
        
        return (
            <div className="contact-us page-body-container">
                <div
                    className="contact-us-container flex justify-center pad-top-100 pad-bottom-100"
                    style={{backgroundImage: `url(${pageBG})`}}
                >
                    <div className="pad-right-100 form-header">
                        <div className="txt-xlarge txt-gray-med txt-bold">
                            Don't leave it empty.
                        </div>
                        <div className="txt-header txt-bold">
                            contact us.
                        </div>
                        <div>
                            {
                                site_options && (
                                    <div className="contact-info">
                                        <div>
                                            <div className="flex flex-rm-xs align-center">
                                                <i className="fal fa-envelope" />
                                                {site_options.contact_email}
                                            </div>
                                            <div className="flex flex-rm-xs align-center margin-top-10">
                                                <i className="fal fa-phone" />
                                                {site_options.contact_number}
                                            </div>
                                            <div className="flex flex-rm-xs align-center margin-top-10">
                                                <i className="fas fa-map-marker-alt" />
                                                {site_options.contact_address}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="contact-form pad-left-100">
                        <div className="flex align-center">
                            <div className="form-group flex-1">
                                <label className="lbl-sm">
                                    Name
                                </label>
                                <input
                                    className="form-control with-bottom-margin"
                                    name="full_name"
                                    onChange={e => this.onInputFormChange(e)}
                                    value={this.state.inquiryForm.full_name}
                                />
                            </div>
                            <div className="form-group flex-1 margin-left-20">
                                <label className="lbl-sm">
                                    Email
                                </label>
                                <input
                                    className="form-control with-bottom-margin"
                                    name="email"
                                    onChange={e => this.onInputFormChange(e)}
                                    value={this.state.inquiryForm.email}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="lbl-sm">
                                Message
                            </label>
                            <textarea
                                className="form-control with-bottom-margin"
                                name="message"
                                rows="10"
                                onChange={e => this.onInputFormChange(e)}
                                value={this.state.inquiryForm.message}
                            />
                        </div>
                        <div className="text-center">
                            <button
                                className={`btn btn-${this.state.sent ? "primary" : "black"} btn-sqr btn-block btn-block-center btn-pad-large txt-bold submit-btn`}
                                onClick={() => this.submitInquiry()}
                                disabled={!validateFormObj(this.state.inquiryForm) || this.state.loading || this.state.sent}
                            >
                                {this.state.loading ? <EllipsisLoader /> : (this.state.sent ? "MESSAGE SENT" : "SEND MESSAGE")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
       )
    }
};


function mapStateToProps(state, ownProps) {
	return {
        page: state.page,
        site_options: state.site_options  
	};
}

ContactUs.propTypes = {
    page: PropTypes.object
};

export default connect(mapStateToProps, null)(ContactUs);
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import TextInput from '../../_global/Form/TextInput';
import { getProvinceList, getCityList, getAreaList, getCountryList} from '../../../helpers/caboodleHelper';
import { validate } from '../../../helpers/validationHelper';
import * as shippingFormActions from '../../../actions/shipping-form.actions';

class ShippingForm extends React.Component {
    state = {
        submit: false,
        fetchingProvince: true,
        fetchingCountries: true,
        fetchingRegion: true,
        fetchingCity: true
    }

    componentDidMount() {
        const { shipping_form, initialState } = this.props;
        const { inputs } = shipping_form;
        let state = Object.assign({}, inputs);

        if (initialState) {
            state = Object.assign({}, initialState);
            state.country_id = 1;
            this.props.actions.changeShippingFormState({
                inputs: state
            });
        }
        
        getCountryList().then(res => {
            this.setState({
                fetchingCountries: false
            });

            getProvinceList()
                .then(() => {
                    this.setState({
                        fetchingProvince: false
                    });

                    if (state.province_id) {
                        getCityList({
                            region_id: state.province_id
                        }).then(() => {
                            this.setState({
                                fetchingCity: false
                            });
                        }).catch(() => {
                            this.setState({
                                fetchingCity: false
                            });
                        });
                    }
            
                    if (state.city_id) {
                        getAreaList({
                            city_id: state.city_id
                        }).then(() => {
                            this.setState({
                                fetchingRegion: false
                            });
                        }).catch(() => {
                            this.setState({
                                fetchingRegion: false
                            });
                        });
                    }
                }).catch(() => {
                    this.setState({
                        fetchingProvince: false
                    });
                });
        });

        this.props.setCallable(this.submitForm);
    }

    submitForm = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                submit: true
            }, () => {
                const valid = this.validateInputs().valid;
                if (!valid) {
                    return resolve(false);
                }
                return resolve(true);
            });
        });
    }

    handleInputState = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const insertValueToState = {};
        insertValueToState[name] = value;
        const { shipping_form } = this.props;
        const inputs = Object.assign({}, shipping_form.inputs);

        if (name === 'province_id') {
            const index = e.nativeEvent.target.selectedIndex;
            insertValueToState.province_text = e.nativeEvent.target[index].text;
            
            inputs.city_id = 0;
            inputs.area_id = 0;
            this.setState({
                fetchingCity: true
            });

            getCityList({
                region_id: value
            }).then(() => {
                this.setState({
                    fetchingCity: false
                });
            }).catch(() => {
                this.setState({
                    fetchingCity: false
                });
            });
        }

        if (name === 'city_id') {
            const index = e.nativeEvent.target.selectedIndex;
            insertValueToState.city_text = e.nativeEvent.target[index].text;
            inputs.area_id = 0;
            this.setState({
                fetchingRegion: true
            });
            getAreaList({
                city_id: value
            }).then(() => {
                this.setState({
                    fetchingRegion: false
                });
            }).catch(() => {
                this.setState({
                    fetchingRegion: false
                });
            });
        }

        if (name === 'area_id') {
            const index = e.nativeEvent.target.selectedIndex;
            insertValueToState.area_text = e.nativeEvent.target[index].text;
        }

        this.props.actions.changeShippingFormState({
            inputs: Object.assign(inputs, insertValueToState)
        }, name);
        //validate input after 100ms
        setTimeout(() => {
            this.validateInputs();
        }, 100);
    }

    validateInputs = () => {
        const { submit } = this.state;
        if (!submit) {
            return;
        }
        const { shipping_form } = this.props;
        const inputs = Object.assign({}, shipping_form.inputs);

        let rules = {
            properties: {
                firstname: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your firstname"
                },
                lastname: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your lastname"
                },
                email: {
                    type: 'string',
                    required: true,
                    format: 'email',
                    allowEmpty: false,
                    message: "Please enter your email"
                },
                address_line_1: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your address"
                },
                phone: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please provide a phone number"
                },
                zip: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please provide a zip code"
                }
            }
        };

        if (inputs.country_id.toString() === "1") {
            rules = Object.assign(rules, {
                                    province_id: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please select a province"
                                    },
                                    city_id: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please select a city"
                                    },
                                    area_id: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please select a city"
                                    }
                                });
        } else {
            rules = Object.assign(rules, {
                                    custom_province: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please enter a state/province"
                                    },
                                    custom_city: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please enter a city"
                                    },
                                    custom_area: {
                                        type: 'string',
                                        required: true,
                                        allowEmpty: false,
                                        message: "Please enter an area"
                                    }
                                });
        }

        //--- convert all fields in the rule object to string to avoid data type mismatch
        // eslint-disable-next-line
        Object.keys(rules.properties).map(key => {
            if (inputs[key]) {
                inputs[key] = inputs[key].toString();
            }
        });

        const result = validate(inputs, rules, undefined, true);
        this.props.actions.changeShippingFormState({
            errors: result.errors,
            valid: result.valid
        });
        // console.log(result);
        return result;
    }


    render() {
        const { select_options, shipping_form } = this.props;
        const { inputs, errors } = shipping_form;
        const { fetchingCity, fetchingProvince } = this.state;

        return (
            <div className="shipping-details-form-container">
                <TextInput
                    value={inputs.firstname}
                    error={errors.firstname}
                    label="First name"
                    type="text"
                    placeholder="First name"
                    name="firstname"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                <TextInput
                    value={inputs.lastname}
                    error={errors.lastname}
                    label="Last name"
                    type="text"
                    placeholder="Last name"
                    name="lastname"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                <TextInput
                    value={inputs.email}
                    error={errors.email}
                    label="Email Address"
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                <TextInput
                    value={inputs.address_line_1}
                    error={errors.address_line_1}
                    label="Address Line" 
                    type="text"
                    placeholder="Address Line"
                    name="address_line_1"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                {/* <TextInput
                    value={inputs.country_id}
                    error={errors.country_id}
                    label="Select Country" 
                    type="select"
                    placeholder="Select Country"
                    name="country_id"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                >
                    <option value="" disabled>
                        Select Country...
                    </option>
                    {select_options 
                        && select_options.country_list 
                        && select_options.country_list.map((country, key) => (
                            <option key={key} value={country.id}>
                                {country.name}
                            </option> 
                        ))}
                </TextInput> */}
                {
                    inputs.country_id && inputs.country_id.toString() === "1" ? (
                        <Fragment>
                            <TextInput
                                value={inputs.province_id}
                                error={errors.province_id}
                                label="Select Province" 
                                type="select"
                                placeholder="Select Province"
                                name="province_id"
                                className={`${fetchingProvince ? 'loading' : ''}`}
                                onChange={this.handleInputState}
                                disabled={this.props.disableForm}
                            >
                                <option value="" disabled>
                                    Select Province...
                                </option>
                                {select_options 
                                    && select_options.province_list 
                                    && select_options.province_list.map((province, key) => (
                                        <option key={key} value={province.id}>
                                            {province.name}
                                        </option> 
                                    ))}
                            </TextInput>
                            <TextInput
                                value={inputs.city_id}
                                error={errors.city_id}
                                label="Select City" 
                                type="select"
                                placeholder="Select City"
                                name="city_id"
                                className={`${fetchingCity ? 'loading' : ''}`}
                                onChange={this.handleInputState}
                                disabled={(select_options && select_options.city_list && select_options.city_list.length <= 0) || this.props.disableForm}
                            >
                                <option value="" disabled>
                                    Select City...
                                </option>
                                {select_options 
                                    && select_options.city_list 
                                    && select_options.city_list.map((city, key) => (
                                        <option key={key} value={city.id}>
                                            {city.name}
                                        </option> 
                                    ))}
                            </TextInput>
                            <TextInput
                                value={inputs.area_id}
                                error={errors.area_id}
                                label="Barangay" 
                                type="select"
                                placeholder="Enter Barangay"
                                name="area_id"
                                className={`${(fetchingCity || this.state.fetchingRegion || !inputs.city_id) ? 'loading' : ''}`}
                                onChange={this.handleInputState}
                                disabled={(select_options && select_options.city_list && select_options.city_list.length <= 0 && select_options.area_list && select_options.area_list.length <= 0) || this.props.disableForm}
                            >
                                <option value="" disabled>
                                    Select Area...
                                </option>
                                {select_options 
                                    && select_options.area_list 
                                    && select_options.area_list.map((area, key) => (
                                        <option key={key} value={area.id}>
                                            {area.name}
                                        </option> 
                                    ))}
                            </TextInput>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <TextInput
                                value={inputs.custom_province}
                                error={errors.custom_province}
                                label="State / Province"
                                type="text"
                                placeholder="State / Province"
                                name="custom_province"
                                onChange={this.handleInputState}
                                disabled={this.props.disableForm}
                            />
                            <TextInput
                                value={inputs.city}
                                error={errors.city}
                                label="City"
                                type="text"
                                placeholder="City"
                                name="city"
                                onChange={this.handleInputState}
                                disabled={this.props.disableForm}
                            />
                            <TextInput
                                value={inputs.custom_area}
                                error={errors.custom_area}
                                label="Area"
                                type="text"
                                placeholder="Area"
                                name="custom_area"
                                onChange={this.handleInputState}
                                disabled={this.props.disableForm}
                            />
                        </Fragment>
                    )
                }
                <TextInput
                    value={inputs.zip}
                    error={errors.zip}
                    label="Zip Code" 
                    type="text"
                    placeholder="Enter zip code"
                    name="zip"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                <TextInput
                    value={inputs.phone}
                    error={errors.phone}
                    label="Mobile" 
                    type="text"
                    placeholder="Enter Phone"
                    name="phone"
                    onChange={this.handleInputState}
                    disabled={this.props.disableForm}
                />
                
            </div>
       );
    }
}


ShippingForm.propTypes = {
    select_options: PropTypes.object,
    shipping_form: PropTypes.object,
    actions: PropTypes.object,
    setCallable: PropTypes.func, // Function to call to submit form
    initialState: PropTypes.object, // Form initial state on mount (optional)
    disableForm: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
	return {
        select_options: state.select_options,
        shipping_form: state.shipping_form,
        user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(shippingFormActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingForm);

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import Slider from "react-slick";
import {
    FacebookShareButton,
    TwitterShareButton
  } from 'react-share';
import ProductList from '../_global/ProductList/ProductList';
import ProductMoreContainer from '../_global/Elements/ProductsMoreContainer';
import {
        extractAssetObj,
        addToCart,
        makeASquare,
        computeDiscountPercentage,
        flagReview,
    } from '../../helpers/caboodleHelper';
import * as productActions from '../../actions/products.actions';
import * as ApiHelper  from '../../helpers/apiHelper';
import TextInput from '../_global/Form/TextInput';
import FormattedTotal from '../_global/Elements/FormattedTotal';
import ReviewCard from '../_global/Elements/ReviewCard';
import History from '../../History';
import ProductReviewForm from '../_global/Elements/ProductReviewForm';

class ProductView extends React.PureComponent {
    container = React.createRef();
    state = {
        containerWidth: 0,      // *
        refreshSlide: false,    // * State for refreshing slider
        timer: false,           // * 
        qty: 1,
        selectedImage: "",
        requestingToWishlist: false,
        reviews: {},
        flagRequest: false
    }

    componentWillMount() {
        window.removeEventListener("resize", this.initMakeASquare);
    }

    
    componentDidMount () {
        this.getProduct();
        window.addEventListener('resize', this.setContainerWidth);
    }

    componentDidUpdate(prevProps) {
        if(this.extractUrlSlug(this.props) !== this.extractUrlSlug(prevProps)) {
            this.setState({
                qty: 1
            }, () => {
                this.getProduct();
            });
        }

        if (this.props.products.selected && this.props.products.selected.inventory === 0 && this.props.products.selected.enable_inventory === 1) {
            History.push("/");
        }

        this.initMakeASquare();
        this.setContainerWidth();
    }

    initMakeASquare = () => {
        setTimeout(() => {
            makeASquare(document.getElementsByClassName(`product-container-image`));
            makeASquare(document.getElementsByClassName(`customer-img`));
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setContainerWidth);
        this.props.actions.unSelectProduct();
    }

    setContainerWidth = () => {
        //Refresh slick slider to fix layout
        if (this.container && 
            this.container.current && 
            this.container.current.offsetWidth !== this.state.containerWidth
        ) {
            clearTimeout(this.state.timer);
            this.setState({
                containerWidth: this.container.current.offsetWidth,
                refreshSlide: true,
                timer: setTimeout(() => {
                    this.setState({
                        refreshSlide: false
                    });
                }, 500)
            });
            
        }

        this.initMakeASquare();
    }

    switchImage = (imagePath) => {
        this.setState({
            selectedImage: imagePath
        });
    }

    getProduct = () => {
        const { actions, history} = this.props;
        const match = matchPath(history.location.pathname, {
            path: '/product/:slug/view',
            exact: true,
            strict: false
        });

        if (match.params.slug) {
            actions.findProduct(match.params.slug).then(res => {
                const params = {
                    pid: this.props.products.selected.id,
                    page: 0
                };

                actions.getProductReviews(params);
            });
            actions.getProductBreadcrumbs(match.params.slug);
            actions.getRelatedProducts({slug: match.params.slug});
        }
    }

    handleReviewSubmit = (response) => {
        let selectedProduct = Object.assign({}, this.props.products.selected, {average_review: response.new_average});
        this.props.actions.changeProductObject(selectedProduct);
    }

    handleClickCrumb = (category) => {
        let search = "";

        if (category) {
            search = `?categories=${category.slug}`;
        } else {
            search = "?best_seller=1"
        }

        History.push({
            pathname: '/products',
            search
        });
    }

    handleToggleToWishlist = (product, action) => {
        this.setState({
            requestingToWishlist: true
        }, () => {
            const params = {
                customer_id: this.props.user.customer.id,
                product_id: product.id
            };
            
            if (action === "add") {
                this.props.actions.addToWishlist(params).then(res => {
                    this.setState({
                        requestingToWishlist: false
                    });
                });
            } else {
                this.props.actions.removeToWishlist(params).then(res => {
                    this.setState({
                        requestingToWishlist: false
                    });
                });
            }
        });
    }

    render() {
        const { products, user } = this.props;
        const { qty } = this.state;
        const product = products.selected;
        const breadcrumbs = products.breadcrumbs;
        const { containerWidth, refreshSlide } = this.state;
        let { selectedImage } = this.state;
        let newAverage = 0, reviewCount = 0;

        if (!products || products.loading || !products.selected || Object.keys(product).length === 0) {
            return this.skeletonLoading();
        }

        if (selectedImage === '') {
            selectedImage = extractAssetObj(product, ['image_paths', 'path']);
        }
        
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false,
            dots: true,
            className: "thumbnail-list"
        };
        
        if (product && product.product_reviews && product.product_reviews.hasOwnProperty("ratings")) {
            Object.keys(product.product_reviews.ratings).map(key => {
                newAverage += product.product_reviews.ratings[key] * key;
                reviewCount += product.product_reviews.ratings[key];
            });
            
            if (reviewCount > 0) {
                newAverage /= reviewCount;
            }
        }

        return (
            <div className="page-body-container">
                <div className="page-container">
                    {
                        breadcrumbs && breadcrumbs.length > 0 && (
                            <div className="breadcrumbs flex align-center txt-uppercase margin-bottom-30 txt-bold">
                                <div
                                    className="crumb txt-large txt-gray-med float-in-up-down clickable"
                                    onClick={() => this.handleClickCrumb()}
                                >
                                    shop
                                </div>
                                {
                                    breadcrumbs.map((crumb, index) => {
                                        return (
                                            <div className="flex align-center">
                                                <div
                                                    className="separator txt-xlarge margin-left-20 margin-right-20 txt-gray-med float-in-up-down"
                                                    style={{animationDelay: `${(index + (index === 0 ? 1 : 2)) * 0.2}s`}}
                                                >
                                                    /
                                                </div>
                                                <div
                                                    className={`crumb txt-large ${crumb.id !== product.category && 'txt-gray-med'} float-in-up-down clickable`}
                                                    style={{animationDelay: `${(index + (index === 0 ? 2 : 3)) * 0.2}s`}}
                                                    onClick={() => this.handleClickCrumb(crumb)}
                                                >
                                                    {crumb.title}
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        )
                    }
                    <div className="product-view">
                        <div className="product-images"
                            ref={this.container}
                        >
                            <div className="main-image fade-in"
                                style={{
                                    backgroundImage: `url('${selectedImage}')`
                                }}
                            />
                            {!refreshSlide && product && product.images && product.images.length > 0 &&
                                <div
                                    style={{
                                        width: `${containerWidth}px`
                                    }}
                                    className="margin-top-20"
                                >
                                    <Slider
                                        {...settings}
                                        ref={slider => (this.slider = slider)} 
                                    >
                                        <div>
                                            <div 
                                                onClick={() => this.switchImage(extractAssetObj(product, ['image_paths', 'path']))}
                                                className={`thumbnail fade-in ${this.state.selectedImage === extractAssetObj(product, ['image_paths', 'path']) && 'selected'}`}
                                                style={{
                                                    backgroundImage: `url('${extractAssetObj(product, ['image_paths', 'path'])}')`
                                                }}
                                            />
                                        </div>
                                        {product.images.map((p, key) => {
                                            return (
                                                <div key={key}>
                                                    <div 
                                                        onClick={() => this.switchImage(extractAssetObj(p))}
                                                        className={`thumbnail fade-in ${this.state.selectedImage === extractAssetObj(p) && 'selected'}`}
                                                        style={{
                                                            backgroundImage: `url('${extractAssetObj(p)}')`,
                                                            animationDelay: `${key & 0.2}s`
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                        {product && product.images && product.images.length && product.images.length < 3 && Array(3 - product.images.length).fill(0).map((prd, key) => (
                                            <div key={key} />
                                        ))}

                                    </Slider>
                                </div>
                            }
                        </div>
                        <div className="product-info">
                            {product.product_category && product.product_category.title &&
                                <h6 className="product-category txt-medium txt-gray-med">
                                    {product.product_category.title}
                                </h6>
                            } 
                            <h1 className="product-title txt-xxlarge txt-regular">
                                {product.title}
                            </h1>
                            <h1 className="txt-xxlarge">
                                <FormattedTotal formattedPrice={product && product.formatted_price} />
                            </h1>
                            {
                                product && (product.active_sale.length > 0 || product.active_flash_sale.length > 0) && (
                                    <div className="flex align-center">
                                        <h1 className="txt-medium txt-gray-med txt-strike-element pad-left-10 pad-right-10 margin-0">
                                            <FormattedTotal formattedPrice={product && product.formatted_raw_price} />
                                        </h1>
                                        <div className="txt-bold txt-medium txt-lineheight-1 margin-left">
                                            -{computeDiscountPercentage(product.active_sale[0], product)}%
                                        </div>
                                    </div>
                                )
                            }
                            {
                                product && product.active_flash_sale.length > 0 && (
                                    <div className="flex align-center">
                                        <h1 className="txt-medium txt-gray-med txt-strike-element pad-left-10 pad-right-10 margin-0">
                                            <FormattedTotal formattedPrice={product && product.formatted_raw_price} />
                                        </h1>
                                        <div className="txt-bold txt-medium txt-lineheight-1 margin-left">
                                            -{computeDiscountPercentage(product.active_flash_sale[0], product)}%
                                        </div>
                                    </div>
                                )
                            }
                            <div className="flex align-center product-star txt-warning margin-top-20">
                                {
                                    [...Array(5)].map((star, index) => {
                                        if ((index + 1) <= newAverage) {
                                            return (
                                                <i
                                                    className="fas fa-star txt-xlarge"
                                                    key={index}
                                                />
                                            );
                                        } else {
                                            return (
                                                <i
                                                    className="far fa-star txt-xlarge"
                                                    key={index}
                                                />
                                            );
                                        }
                                    })
                                }
                            </div>
                            {
                                    product.description && (
                                        <div className="description-container pad-top-30">
                                            
                                                    <div className="txt-medium txt-bold">
                                                        DESCRIPTION
                                                    </div>
                                                    <div className="product-description" dangerouslySetInnerHTML={{__html: product.description}} />
                                                
                                        </div>
                                    )
                            }
                            {
                                product.specifications && (
                                    <div className="description-container pad-top-30">
                                        <div className="txt-medium txt-bold">
                                            SPECIFICATIONS
                                        </div>
                                        <div className="product-description margin-top-10" dangerouslySetInnerHTML={{__html: product.specifications}} />
                                    </div>
                                )
                            }
                            <div className="actions-container margin-top-30">
                                <div className="action-qty">
                                    <div className="txt-bold txt-medium margin-bottom">
                                        Quantity:
                                    </div>
                                    <TextInput 
                                        type="number"
                                        min={1}
                                        value={qty}
                                        onChange={(e) => this.setState({
                                            qty: e.target.value
                                        })}
                                        className="txt-bold txt-large"
                                    />
                                </div>
                                <div className="action-buttons margin-top-50 flex">
                                    <button 
                                        onClick={() => addToCart(product, qty)}
                                        className="btn btn-secondary btn-sqr btn-pad-large flex-1 margin-right-20 btn-pad-xlarge"
                                    >
                                            <span className="txt-uppercase txt-bold">
                                                Add to Cart
                                            </span>
                                    </button>
                                    <button 
                                        onClick={() => this.handleBuyNow(product, qty)}
                                        className="btn btn-secondary-dark btn-sqr btn-pad-large flex-1 margin-right-20 btn-pad-xlarge"
                                    >
                                            <span className="txt-uppercase txt-bold">
                                                Buy now
                                            </span>
                                    </button>
                                    {
                                        this.props.user.hasOwnProperty("name") && (
                                            <Fragment>
                                                {
                                                    this.checkIfWishlisted(product) ? (
                                                        <button 
                                                            onClick={() => this.handleToggleToWishlist(product, "remove")}
                                                            className="btn btn-clear clear-black btn-sqr no-padding"
                                                            disabled={this.state.requestingToWishlist}
                                                        >
                                                                <i className="fas fa-heart margin-right-10 txt-warning pulse txt-large" />
                                                                <span className="txt-uppercase txt-bold txt-large">
                                                                    Remove to Wishlist
                                                                </span>
                                                        </button>
                                                    ) : (
                                                        <button 
                                                            onClick={() => this.handleToggleToWishlist(product, "add")}
                                                            className="btn btn-clear clear-black btn-sqr no-padding"
                                                            disabled={this.state.requestingToWishlist}
                                                        >
                                                                <i className="far fa-heart margin-right-10 pulse-inwards txt-large" />
                                                                <span className="txt-uppercase txt-bold txt-large">
                                                                    Add to Wishlist
                                                                </span>
                                                        </button>
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="description-container pad-top-30">
                                <div className="txt-medium txt-bold">
                                    Share with
                                </div>
                                <div className="flex align-center">
                                    <FacebookShareButton
                                        url={window.location.href}
                                    >
                                        <button className="btn btn-fb">
                                            <i className="fab fa-facebook-f margin-right" /> facebook
                                        </button>
                                    </FacebookShareButton>
                                    {/* <TwitterShareButton
                                        url={window.location.href}
                                    >
                                        <button className="btn btn-twitter margin-left-10">
                                            <i className="fab fa-twitter margin-right" /> Twitter
                                        </button>
                                    </TwitterShareButton> */}
                                </div>
                            </div>
                        </div>
                        <div className="margin-top-100 related-product-list">
                            <div className="txt-bold txt-header-2 margin-top-100">
                                Reviews and Ratings
                            </div>
                            <div className="margin-top-50">
                                <div className="container">
                                    <div className="flex justify-center margin-bottom-20">
                                        <div className="flex flex-rm-xs align-center margin-top-50">
                                            <div className="overall-rating">
                                                <div className="flex align-center justify-center">
                                                    <div className="txt-bold txt-oversized">
                                                        {newAverage.toFixed(1)}
                                                    </div>
                                                    <div className="txt-gray txt-header-2 margin-left-10">
                                                        / 5
                                                    </div>
                                                </div>
                                                <div className="margin-top-10 product-stars">
                                                    {
                                                        [...Array(5)].map((star, index) => {
                                                            if ((index + 1) <= newAverage) {
                                                                return (
                                                                    <i
                                                                        className="fas fa-star txt-header-2"
                                                                        key={index}
                                                                    />
                                                                );
                                                            } else {
                                                                return (
                                                                    <i
                                                                        className="far fa-star txt-header-2"
                                                                        key={index}
                                                                    />
                                                                );
                                                            }
                                                        })
                                                    }
                                                </div>
                                                {
                                                    product && product.product_reviews && product.product_reviews.ratings && (
                                                        <div className="margin-top text-center">
                                                            {this.countAllRatings()} Ratings
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="ratings-chart margin-left-50 margin-xs-0">
                                                {this.renderRatingsChart()}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        user.hasOwnProperty('name') && <ProductReviewForm />
                                    }
                                    {
                                        product && product.product_reviews && product.product_reviews.reviews.map(review => {
                                            return (
                                                <div
                                                    className="margin-bottom-10 fade-in"
                                                    key={"reviewcard" + review.id}
                                                >
                                                    <ReviewCard
                                                        review={review}
                                                        onFlagReview={this.handleFlagReview}
                                                        userId={user.id}
                                                        requestLoading={this.state.flagRequest}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {(products && products.related && products.related.list && products.related.list.length > 0) &&
                            <div className="related-product-list margin-top-50 margin-bottom-100">
                                <ProductMoreContainer
                                    products={products && products.related && products.related.list}
                                    header="Related Products"
                                    headerTextAlign="left"
                                    gridCol={4}
                                    moreBtnLink={`/products?categories=${product.product_category && product.product_category.slug}`}
                                    moreBtnText="Discover more products"
                                    cardGap={50}
                                    limit={4}
                                    id={"related"}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    handleBuyNow = (product, qty) => {
        addToCart(product, qty, false, false);
        History.push("/checkout");
    }

    countAllRatings = () => {
        const reviews = Object.assign({}, this.props.products.selected.product_reviews);
        let count = 0;

        Object.keys(reviews.ratings).map(key => {
            count += parseInt(reviews.ratings[key]);
        });

        return count;
    }

    renderRatingsChart = () => {
        let reviews  = {
            ratings: {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0
            }
        };

        if (this.props.products && this.props.products.selected && this.props.products.selected.product_reviews) {
            reviews = Object.assign({}, this.props.products.selected.product_reviews);
        }

        return (
            <>
                {
                    Object.keys(reviews.ratings).map(key => {
                        return (
                            <div className="flex align-center justify-center product-stars margin-top-10 margin-bottom-10">
                                {
                                    [...Array(5)].map((star, index) => {
                                        if ((index + 1) <= key) {
                                            return (
                                                <i
                                                    className="fas fa-star txt-xlarge scale-in"
                                                    style={{
                                                        animationDelay: `${(index * 0.2) + (key * 0.05)}s`
                                                    }}
                                                    key={index}
                                                />
                                            );
                                        } else {
                                            return (
                                                <i
                                                    className="far fa-star txt-xlarge scale-in"
                                                    style={{
                                                        animationDelay: `${(index * 0.2) + (key * 0.05)}s`
                                                    }}
                                                    key={index}
                                                />
                                            );
                                        }
                                    })
                                }
                                <div className="margin-left-10">
                                    {
                                        reviews.ratings[key]
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </>
        );
    }

    getProductMerchantLocation(product) {
        let location = false;
        const {merchant} = product;

        if (merchant) {
            const {country} = merchant;

            if (country) {
                location = country.country_info.name;
            }
        }

        if (location) {
            return (
                <div className="product-location margin-top-10">
                    <i className="fal fa-exclamation-circle txt-warning"/> This product is only available in {location}.
                </div>
            )
        }
    }

    extractUrlSlug(props) {
        let slug = "";
        const { location } = props;
        const match = matchPath(location.pathname, {
            path: '/product/:slug/view',
            exact: true,
            strict: false
        });

        if (match.params.slug) {
            slug = match.params.slug;
        }

        return slug;
    }

    skeletonLoading = () => {
        return (
            <div className="page-body-container pad-to-nav">
                <div className="page-container">
                    <div className="product-view">
                        <div className="product-images ph-item">
                            <div className="main-image"/>
                            <div className="thumbnail-list grid-fix-3-col margin-top-30">
                                {
                                    [...Array(3)].map(arr => {
                                        return(
                                            <div className="thumbnail" />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="product-info">
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    {
                                        [...Array(3)].map((arr, index) => {
                                            return(
                                                <div className="ph-row margin-0" key={index}>
                                                    <div className={`ph-col-${index%2 === 0 ? '4' : '6'} big`} />
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="ph-row margin-top-30">
                                        {
                                            [...Array(3)].map((arr, index) => {
                                                return(
                                                    <div key={index} className={`ph-col-${index === 0 ? '12' : (index === 1 ? '6' : '8')}`} />
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="ph-row margin-top-30">
                                        <div className={`ph-col-2 big`} />
                                    </div>
                                    <div className="ph-row margin-top-30">
                                        <div className={`ph-col-4 big`} />
                                        <div className={`ph-col-4 margin-left-20 big`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="related-product-list">
                            <ProductList
                                bigContainer
                                loading
                                gridNumber="4"
                                placeholderCardLength={4}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    checkIfWishlisted(product) {
        const { wishlisted } = this.props.user;
        let isWishlisted = false;
        
        if (wishlisted !== undefined && wishlisted.list) {
            if (wishlisted.list.find(findProduct => {
                return findProduct.product_id === product.id;
            })) {
                isWishlisted = true;
            }
        }
        
        return isWishlisted;
    }

    handleFlagReview = (id) => {
        const params = {
            flagged_id: id,
            user_id: this.props.user.id,
        },
        selected = Object.assign({}, this.props.products.selected);
        
        this.setState({
            flagRequest: true
        }, () => {
            flagReview(params)
                .then(res => {
                    selected.product_reviews.reviews.filter(review => {
                        if(review.id === id) {
                            review.flags = res.data;
                            return true;
                        }
                    })
                    this.props.actions.changeProductObject(selected)

                    this.setState({
                        flagRequest: false
                    });
                })
                .catch(err => {
                    this.setState({
                        flagRequest: false
                    });
                });
        });
    }
}


function mapStateToProps(state, ownProps) {
	return {
        products: state.products,
        user: state.user
	};
}

ProductView.propTypes = {
    products: PropTypes.object,
    user: PropTypes.object,
    history: PropTypes.object,
    actions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(productActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
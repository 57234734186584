import React from 'react';
import { numberWithCommas } from '../../../helpers/caboodleHelper';

const FormattedTotal = ({formattedPrice, qty = 1, deduction = 0, additional = 0}) => {
    const price = handlePriceProcessing(formattedPrice, qty, deduction);

    return (
        <span>
            {price.symbol} {numberWithCommas(((parseFloat(price.value) + parseFloat(additional)).toFixed(2)))}
        </span>
    )
};

const handlePriceProcessing = (formattedPrice, qty, deduction) => {
    const formattedArray = formattedPrice.split(" ");
    let currencySymbol = formattedArray[0];
    let newPrice = parseFloat(formattedArray[1].replace("," , "")) - parseFloat(deduction);
    
    newPrice *= parseFloat(qty);
    return {
                value: parseFloat(newPrice).toFixed(2),
                symbol: currencySymbol
            };
}

export default FormattedTotal;
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    updateCustomerDetails,
    requestUpdate,
    updateAddress,
    createAddress,
    updatePassword
} from '../../actions/customer.actions';
import {
    resetShippingForm,
    validateFormObj
} from '../../helpers/caboodleHelper';
import { ASSET_URL } from '../../constants/config';
import TextInput from '../_global/Form/TextInput';
import ShippingForm from '../_global/ShippingForm/ShippingForm';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
class UserInfo extends React.Component {

    state = {
        submitShippingForm: null,
        userInfoForm: {
            profile: {
                firstname: "",
                lastname: "",
                imagePrev: "",
                imageData: "",
                email: "",
                phone: "",
                gender: "Male",
                birthday: new Date(),
                isEditMode: false,
                isSaving: false
            },
            shipping: {
                isEditMode: false,
                isSaving: false
            },
            password: {
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
                isEditMode: false,
                isSaving: false,
                error: ""
            }
        },
        editMode: false,

    }

    componentDidMount() {
        this.resetUserInfo();

        if (this.props.user.hasOwnProperty("name")) {
            if (!this.props.user.customer.primary_address) {
                resetShippingForm();
            }
        }
    }

    render() {
        const {user, loading} = this.props;
        const {profile, shipping, password} = this.state.userInfoForm;
        const address = user.hasOwnProperty('name') ? Object.assign({}, user.customer.primary_address, {email: user.email}) : {};
        
        return (
            <div className="user-info">
                <div className="profile-form">
                    <div className="txt-header-2 flex align-center margin-bottom-20">
                        <span>
                            Profile
                        </span>
                        {
                            loading && !this.props.user.hasOwnProperty("name") ? (
                                <i className="fal fa-spinner fa-spin margin-left-10 transitioned-fast txt-small" />
                            ) : (
                                <i
                                    className={`far fa-pencil txt-large txt-success margin-left-10 transitioned-fast clickable ${profile.isEditMode ? "scale-hide" : ""}`}
                                    onClick={() => this.toggleEdit("profile")}
                                />       
                            )
                        }
                    </div>
                    <div className="profile-img-container">
                        <div
                            className="profile-img clickable"
                            onClick={() => this.openImageSelector()}
                        >
                            {
                                profile.imageData ? (
                                    <img src={URL.createObjectURL(profile.imageData)} />
                                ) : (
                                    user.asset? (
                                        <img src={`${ASSET_URL}/${user.asset.path}`} />
                                    ) : (
                                        <div className="img-placeholder flex align-center justify-center">
                                            <i className="far fa-user" />
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <input
                            type="file"
                            name="image"
                            id="profileImage"
                            className="hidden"
                            accept="image/*"
                            onChange={e => this.handleImageChange(e)}
                        />
                    </div>
                    <div className="flex justify-center margin-top-10 margin-bottom-20">
                        <div className="flex">
                            <div
                                role="button"
                                className={`gender ${!profile.isEditMode || profile.isSaving ? "gender-hide" : "clickable"} ${profile.gender === "Male" && 'active'}`}
                                value="Male"
                                onClick={() => this.handleSetGender("Male")}
                            >
                                <div className="text-center flex align-center justify-center">
                                    <div>
                                        <i className="far fa-mars txt-xxlarge" />
                                    </div>
                                </div>
                            </div>
                            <div
                                role="button"
                                className={`gender ${!profile.isEditMode || profile.isSaving ? "gender-hide" : "clickable"} ${profile.gender === "Female" && 'active'}`}
                                value="Female"
                                onClick={() => this.handleSetGender("Female")}
                            >
                                <div className="text-center flex align-center justify-center">
                                    <div>
                                        <i className="far fa-venus txt-xxlarge" />
                                    </div>
                                </div>
                            </div>
                            <div
                                role="button"
                                className={`gender ${!profile.isEditMode || profile.isSaving ? "gender-hide" : "clickable"} ${profile.gender === "LGBTQA" && 'active'}`}
                                value="LGBTQA"
                                onClick={() => this.handleSetGender("LGBTQA")}
                            >
                                <div className="text-center flex align-center justify-center">
                                    <div>
                                        <i className="far fa-transgender txt-xxlarge" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-2 align-center">
                        <div className="flex-1 margin-right">
                            <TextInput
                                value={profile.firstname}
                                label="First name"
                                type="text"
                                placeholder="First name"
                                name="firstname"
                                form="profile"
                                onChange={this.handleInputState}
                                disabled={!profile.isEditMode || profile.isSaving}
                                onKeyEnter={this.updateUserInfo}
                            />
                            {
                                !profile.firstname && (
                                    <div className="margin-top txt-error txt-small">
                                        <i className="fas fa-exclamation-circle" /> Please enter your first name here
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex-1 margin-left">
                            <TextInput
                                value={profile.lastname}
                                label="Last name"
                                type="text"
                                placeholder="Last name"
                                name="lastname"
                                form="profile"
                                onChange={this.handleInputState}
                                disabled={!profile.isEditMode || profile.isSaving}
                                onKeyEnter={this.updateUserInfo}
                            />
                            {
                                !profile.lastname && (
                                    <div className="margin-top txt-error txt-small">
                                        <i className="fas fa-exclamation-circle" /> Please enter your last name here
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="grid grid-2 align-center margin-top-10">
                        <div className="flex-1 margin-right">
                            <TextInput
                                value={profile.email}
                                label="Email"
                                type="text"
                                placeholder="Email"
                                name="email"
                                form="profile"
                                onChange={this.handleInputState}
                                disabled
                            />
                        </div>
                        <div className="flex-1 margin-left">
                            <TextInput
                                value={profile.phone}
                                label="Phone"
                                type="text"
                                placeholder="Phone"
                                name="phone"
                                form="profile"
                                onChange={this.handleInputState}
                                disabled={!profile.isEditMode || profile.isSaving}
                            />
                            {
                                !profile.phone && (
                                    <div className="margin-top txt-error txt-small">
                                        <i className="fas fa-exclamation-circle" /> Please enter your phone number here
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex align-center margin-top-10">
                        <div className="flex-1 margin-right">
                            <div className="margin-bottom-10">
                                Birthday
                            </div>
                            <DatePicker
                                selected={profile.birthday}
                                onChange={this.hadleBirthdayChange}
                                disabled={!profile.isEditMode || profile.isSaving}
                                className="form-control"
                            />
                            {
                                !profile.birthday && (
                                    <div className="margin-top txt-error txt-small">
                                        <i className="fas fa-exclamation-circle" /> Please enter your birthday here
                                    </div>
                                )
                            }
                        </div>
                        <div className="flex-1 margin-left"/>   
                    </div>
                    <div className={`flex align-center justify-center margin-top-20 ${profile.isEditMode ? "" : "scale-hide"}`}>
                            <button
                                className="btn btn-lgray"
                                onClick={() => this.toggleEdit("profile")}
                                disabled={profile.isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                className={`btn btn-${profile.isSaving ? 'gray' : 'black'} margin-left-10`}
                                onClick={() => this.updateUserInfo()}
                                disabled={profile.isSaving || !validateFormObj({firstname: profile.firstname, lastname: profile.lastname})}
                            >
                                {
                                    profile.isSaving ? "Updating..." : "Update"
                                }
                            </button>
                    </div>
                </div>
                <div className="password-form">
                    <div className="txt-header-2 flex align-center margin-bottom-20">
                        <span>
                            Password
                        </span>
                        {
                            loading && !this.props.user.hasOwnProperty("name") ? (
                                <i className="fal fa-spinner fa-spin margin-left-10 transitioned-fast txt-small" />
                            ) : (
                                <i
                                    className={`far fa-pencil txt-large txt-success margin-left-10 transitioned-fast clickable ${password.isEditMode ? "scale-hide" : ""}`}
                                    onClick={() => this.toggleEdit("password")}
                                />       
                            )
                        }
                    </div>
                    <div className="flex align-center">
                        <div className="flex-1 margin-right">
                            <TextInput
                                value={password.currentPassword}
                                label="Current password"
                                type="password"
                                placeholder="Current password"
                                name="currentPassword"
                                form="password"
                                onChange={this.handleInputState}
                                disabled={!password.isEditMode || password.isSaving}
                                onKeyEnter={this.handleUpdatePassword}
                            />
                        </div>
                    </div>
                    <div className="flex align-center margin-top-10">
                        <div className="flex-1 margin-left">
                            <TextInput
                                value={password.newPassword}
                                label="New Password"
                                type="password"
                                placeholder="New Password"
                                name="newPassword"
                                form="password"
                                onChange={this.handleInputState}
                                disabled={!password.isEditMode || password.isSaving}
                                onKeyEnter={this.handleUpdatePassword}
                            />
                        </div>
                    </div>
                    <div className="flex align-center margin-top-10">
                        <div className="flex-1 margin-left">
                            <TextInput
                                value={password.confirmPassword}
                                label="Confirm Password"
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                form="password"
                                onChange={this.handleInputState}
                                disabled={!password.isEditMode || password.isSaving}
                                onKeyEnter={this.handleUpdatePassword}
                            />
                        </div>  
                    </div>
                    {
                        password.error && (
                            <div className="txt-small txt-bold txt-error margin-top-10">
                                {password.error}
                            </div>
                        )
                    }
                    <div className={`flex align-center justify-center margin-top-20 ${password.isEditMode ? "" : "scale-hide"}`}>
                            <button
                                className="btn btn-lgray"
                                onClick={() => this.toggleEdit("password")}
                                disabled={password.isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                className={`btn btn-${password.isSaving ? 'gray' : 'black'} margin-left-10`}
                                onClick={() => this.handleUpdatePassword()}
                                disabled={password.isSaving}
                            >
                                {
                                    password.isSaving ? "Updating..." : "Update"
                                }
                            </button>
                    </div>
                </div>
                <div className="shipping-form margin-top-40">
                    <div className="txt-header-2 flex align-center margin-bottom-40">
                        <span>
                            Shipping Details
                        </span>
                        {
                            loading && !this.props.user.hasOwnProperty("name") ? (
                                <i className="fal fa-spinner fa-spin margin-left-10 transitioned-fast txt-small" />
                            ) : (
                                <i
                                    className={`far fa-pencil txt-large txt-success margin-left-10 transitioned-fast clickable ${shipping.isEditMode ? "scale-hide" : ""}`}
                                    onClick={() => this.toggleEdit("shipping")}
                                />   
                            )
                        }
                    </div>
                    <ShippingForm
                        setCallable={callable => this.setState({submitShippingForm: callable})}
                        disableForm={!shipping.isEditMode}
                        initialState={address ? address : {}}
                    />
                    <div className={`flex transitioned-fast align-center justify-center margin-top-20 margin-bottom-50 ${shipping.isEditMode ? "" : "scale-hide"}`}>
                            <button
                                className="btn btn-lgray"
                                onClick={() => this.toggleEdit("shipping")}
                                disabled={shipping.isSaving}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-black margin-left-10"
                                onClick={() => this.updateCustomerAddress()}
                                disabled={shipping.isSaving}
                            >
                                {
                                    shipping.isSaving ? "Updating..." : "Update"
                                }
                            </button>
                    </div>
                </div>
            </div>
       )
    }

    handleSetGender = (gender) => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        userInfoForm.profile.gender = gender;

        this.setState({
            userInfoForm
        });
    }

    hadleBirthdayChange = (date) => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        userInfoForm.profile.birthday = date;

        this.setState({
            userInfoForm
        });
    }

    handleInputState = (e) => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        
        userInfoForm[e.target.getAttribute("form")][e.target.name] = e.target.value;
        this.setState({
            userInfoForm
        });
    }

    toggleEdit = (form) => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        this.resetUserInfo();
        userInfoForm[form].isEditMode = !userInfoForm[form].isEditMode;
        if (this.props.user.hasOwnProperty("name")) {
            if (!this.props.user.customer.primary_address) {
                resetShippingForm();
            }
        }
        this.setState({
            userInfoForm
        }, () => {
            if (!form) {
                this.resetUserInfo();
            }
        });
    }

    resetUserInfo = () => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm),
                user = Object.assign({}, this.props.user);

        userInfoForm.profile.firstname = user.customer.firstname;
        userInfoForm.profile.lastname = user.customer.lastname;
        userInfoForm.profile.email = user.email;
        userInfoForm.profile.phone = user.customer.phone;
        userInfoForm.profile.gender = user.customer.gender;

        userInfoForm.password.currentPassword = "";
        userInfoForm.password.newPassword = "";
        userInfoForm.password.confirmPassword = "";

        if(user.customer.birthday !== "0000-00-00") {
            userInfoForm.profile.birthday = new Date(user.customer.birthday);
        }
        
        this.setState({
            userInfoForm
        });
    }

    updateUserInfo = () => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        userInfoForm.profile.isSaving = true;

        this.setState({
            userInfoForm
        }, () => {
            delete userInfoForm.profile.isEditMode;
            delete userInfoForm.profile.isSaving;
            userInfoForm.profile.id = this.props.user.id;
            userInfoForm.profile.email = this.props.user.email;
            
            let formData = new FormData();
            Object.keys(userInfoForm.profile).map(field => {
                formData.append(field, field === "birthday" ? moment(userInfoForm.profile[field]).format() : userInfoForm.profile[field]);
            });
			
            requestUpdate(formData).then(res => {
                if (res.data) {
                    let user = Object.assign({}, this.props.user);
                    user.customer = res.data.customer;
                    user.asset = res.data.asset;
                    user.image = res.data.image;
                    
                    updateCustomerDetails(user);
                    userInfoForm.profile.isEditMode = false;
                    userInfoForm.profile.isSaving = false;
                    this.setState({
                        userInfoForm
                    });
                }
            });
        });
    }

    handleUpdatePassword = () => {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        userInfoForm.password.isSaving = true;
        userInfoForm.password.error = "";
        
        this.setState({
            userInfoForm
        }, () => {

            const params = {
                current_password: userInfoForm.password.currentPassword,
                new_password: userInfoForm.password.newPassword,
                confirm_password: userInfoForm.password.confirmPassword
            };

            updatePassword(params)
                .then(res => {
                    userInfoForm.password.isEditMode = false;
                    userInfoForm.password.isSaving = false;
                    userInfoForm.password.error = "";
                    
                    this.setState({
                        userInfoForm
                    });
                })
                .catch(err => {
                    userInfoForm.password.isEditMode = false;
                    userInfoForm.password.isSaving = false;
                    userInfoForm.password.error = err.data.message;
                    
                    this.setState({
                        userInfoForm
                    });
                });
        });
    }

    updateCustomerAddress = () => {
        this.state.submitShippingForm().then(valid => {
            if (!valid) {
                return;
            }
            const userInfoForm = Object.assign({}, this.state.userInfoForm);
            userInfoForm.shipping.isSaving = true;
    
            return this.setState({
                userInfoForm
            }, () => {
                let shippingForm = Object.assign({}, this.props.shippingForm);
                shippingForm.inputs.id = this.props.user.customer.primary_address ? this.props.user.customer.primary_address.id : 0;
    
                if (shippingForm.inputs.id === 0) {
                    shippingForm.inputs.customer_id = this.props.user.customer.id;
                    createAddress(shippingForm.inputs).then(res => {
                        if (res.data) {
                            let user = Object.assign({}, this.props.user);
                            user.customer.primary_address = res.data;
                            userInfoForm.shipping.isEditMode = false;
                            userInfoForm.shipping.isSaving = false;
                            this.setState({
                                userInfoForm
                            }, () => {
                                updateCustomerDetails(user);
                            });
                        }
                    });
                } else {
                    updateAddress(shippingForm.inputs).then(res => {
                        if (res.data) {
                            let user = Object.assign({}, this.props.user);
                            user.customer.primary_address = res.data;
                            userInfoForm.shipping.isEditMode = false;
                            userInfoForm.shipping.isSaving = false;
                            this.setState({
                                userInfoForm
                            }, () => {
                                updateCustomerDetails(user);
                            });
                        }
                    });
                }
            });
        });

    }

    openImageSelector() {
        if (this.state.userInfoForm.profile.isEditMode && !this.state.userInfoForm.profile.isSaving) {
            document.getElementById("profileImage").click();
        }
    }

    handleImageChange(e) {
        const userInfoForm = Object.assign({}, this.state.userInfoForm);
        
        if (e.target.files.length > 0) {
            userInfoForm.profile.imageData = e.target.files[0];
            this.setState({
                userInfoForm
            });
        }
    }
};


function mapStateToProps(state, ownProps) {
	return {
        user: state.user,
        shippingForm: state.shipping_form
	};
}

UserInfo.propTypes = {
    user: PropTypes.object,
    shippingForm: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(mapStateToProps, null)(UserInfo);
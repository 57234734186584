import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.selectedBlog, action) {
    switch(action.type) {
        case types.SET_SELECTED_BLOG:
                return Object.assign({}, action.payload);
        default: 
            return state;
    }
}
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
        getPage,
        extractAssetObj,
        makeASquare
    } from '../../helpers/caboodleHelper';

class About extends React.PureComponent {

    state = {};

    componentDidMount() {
        getPage('about');
        window.addEventListener("resize", this.handleMakeASquare);
    }

    componentDidUpdate() {
        makeASquare(document.getElementsByClassName("member-image"));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleMakeASquare);
    }

    handleMakeASquare = () => {
        makeASquare(document.getElementsByClassName("member-image"));
    }

    render() {
        const {page} = this.props;
        let about = false;

        if (page && page.hasOwnProperty("about")) {
            about = Object.assign({}, page.about);
        }

        return (
            <div className="about page-body-container">
                {
                    (page.loading && !about) ? (
                        <div>
                            <div className="page-banner-image ph-item" />
                            <div className="pad-top-50">
                                <div className="page-container ph-item">
                                    <div className="ph-col-12">
                                        <div className="ph-row">
                                            <div className="ph-col-2" />
                                        </div>
                                    </div>
                                    <div className="ph-col-6">
                                        <div className="ph-row">
                                            <div className="ph-col-8 big" />
                                        </div>
                                    </div>
                                    <div className="ph-col-6">
                                        <div className="ph-row">
                                            {
                                                [...Array(15)].map((arr, i) => {
                                                    return(
                                                        // eslint-disable-next-line
                                                        <div key={i} className={`ph-col-${i%2 == 0 ? '12' : (parseInt(Math.random() * (10 - 1) + 1)%2 == 0 ? '10' : '8')}`} />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {
                                about.content && about.slug === "about" && (
                                    <div>
                                        <div className="page-banner-image" style={{backgroundImage: `url(${extractAssetObj(about.content, ["image", "path"])})`}}/>
                                        <div className="page-container pad-top-50">
                                            <div className="txt-xlarge txt-gray-med txt-uppercase txt-bold">
                                                {about.content.sub_title}
                                            </div>
                                            <div className="flex main-article">
                                                <div className="flex-1">
                                                    <h1 className="txt-header">
                                                        {about.content.title}
                                                    </h1>
                                                </div>
                                                <div className="flex-1 pad-left-50 pad-xs-0" dangerouslySetInnerHTML={{ __html: about.content.content }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                about["our-team"] && about.slug === "about" && (
                                    <div className="pad-top-50">
                                        <div className="page-container">
                                            <div className="txt-xlarge txt-gray-med txt-uppercase txt-bold">
                                                {about["our-team"].sub_title}
                                            </div>
                                            <h1 className="txt-header">
                                                {about["our-team"].title}
                                            </h1>
                                            <div className="grid grid-4 pad-top-100 pad-bottom-100 team-member-list">
                                                {
                                                    about["our-team"].items.map(item => {
                                                        return(
                                                            <div
                                                                className="team-member text-center"
                                                                key={item.slug}
                                                            >
                                                                <div className="img-container">
                                                                    <img
                                                                        className="member-image"
                                                                        src={extractAssetObj(item, ["image", "path"])}
                                                                        alt={item.name}
                                                                    />
                                                                </div>
                                                                <div className="txt-xlarge margin-top-20 txt-bold">
                                                                    {item.name}
                                                                </div>
                                                                <div className="txt-gray">
                                                                    {item.job_title}
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
       )
    }
};


function mapStateToProps(state, ownProps) {
	return {
        page: state.page
	};
}

About.propTypes = {
    page: PropTypes.object
};

export default connect(mapStateToProps, null)(About);
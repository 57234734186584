import React from 'react';
import PropTypes from 'prop-types';
import {
    NavLink
} from 'react-router-dom';
// import ReactStars from 'react-stars';
import {
        extractAssetObj,
        computeDiscountPercentage,
        addToCart
    } from '../../../helpers/caboodleHelper';
import history from '../../../History';
import FormattedTotal from '../Elements/FormattedTotal';

class ProductCard extends React.PureComponent {

    render() {
        const { product, loading } = this.props;

        if (loading) {
            return (
                <div className="product-card-js product-card ph-item">
                    <div className="image-container">
                        <img src="" alt="loading" className="product-container-image" />
                        <div className="loading-image-overlay" />
                    </div>
                    <div className="info ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-8 big" />
                                <div className="ph-col-6" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        const active_sale = product.active_sale.length > 0 ? product.active_sale[0] : false;
        const active_flash_sale = product.active_flash_sale.length > 0 ? product.active_flash_sale[0] : false;

        return (
            <div 
                className="product-card-js product-card margin-top-50"
            >
                <div className={`image-container`}>
                    <NavLink
                        to={`/product/${product.slug}/view`}
                    >
                        <img 
                            src={extractAssetObj(product, ['image_paths', 'path'])}
                            alt={product.title}
                            className={`product-container-image fade-in`}
                        />
                    </NavLink>
                    <div className="hover-overlay">
                        <button
                            className="btn btn-violet btn-block btn-sqr btn-pad-large txt-large txt-bold"
                            onClick={() => addToCart(product, 1, true)}
                        >
                            <i className="far fa-plus-circle margin-right-10" />
                            ADD TO CART
                        </button>
                    </div>
                    {
                        active_sale ? (
                            <div className="discount-badge txt-white txt-bold flex align-center justify-center">
                                <div className="text-center">
                                    <div className="txt-medium">
                                        {computeDiscountPercentage(active_sale, product)}% OFF
                                    </div>
                                    <div className="txt-xxlarge">
                                        SALE
                                    </div>
                                </div>
                            </div>
                        ) : false
                    }
                    {
                        active_flash_sale ? (
                            <div className="discount-badge txt-white txt-bold flex align-center justify-center">
                                <div className="text-center">
                                    <div className="txt-medium">
                                        {computeDiscountPercentage(active_flash_sale, product)}% OFF
                                    </div>
                                    <div className="txt-xxlarge">
                                        SALE
                                    </div>
                                </div>
                            </div>
                        ) : false
                    }
                </div>
                <NavLink
                    to={`/product/${product.slug}/view`}
                >
                    <div className="info">
                        <h5 className="product-title txt-large">
                            {product && product.title}
                        </h5>
                        {
                            active_flash_sale || active_sale ? (
                                <div className="txt-large txt-bold">
                                    {
                                        product && (
                                            <div className="txt-medium txt-gray-med txt-strike-element pad-left pad-right margin-0 inline-block">
                                                <FormattedTotal formattedPrice={product && product.formatted_raw_price} />
                                            </div>
                                        )
                                    }
                                </div>
                            ) : false
                        }
                        <div className="product-price txt-large txt-bold">
                            {
                                product && (
                                    <FormattedTotal
                                        formattedPrice={product.formatted_price}
                                    />
                                )
                            }
                        </div>
                        <div className="flex align-center product-star">
                            {
                                [...Array(5)].map((star, index) => {
                                    if ((index + 1) <= product.average_review) {
                                        return (
                                            <i
                                                className="fas fa-star txt-xlarge"
                                                key={index}
                                            />
                                        );
                                    } else {
                                        return (
                                            <i
                                                className="far fa-star txt-xlarge"
                                                key={index}
                                            />
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </NavLink>
            </div>
        );
    }
}


ProductCard.propTypes = {
    product: PropTypes.object,
    loading: PropTypes.bool
};

export default ProductCard;


import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.shipping_form, action) {
    switch(action.type) {
        case types.CHANGE_SHIPPING_FORM_STATE: {
            const newParams = action.payload.params;
            if (action.payload.name === 'province_id') {
                newParams.inputs.city_id = "";
                newParams.inputs.area_id = "";
            }
            if (action.payload.name === 'city_id') {
                newParams.inputs.area_id = "";
            }
            return Object.assign({},
                state,
                {
                    ...newParams
                }
            );
        }
        case types.RESET_SHIPPING_FROM: {
            return {
                errors: {},
                inputs: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    address_line_1: '',
                    country_id: '1',
                    province_id: '',
                    city_id: '',
                    area_id: '',
                    phone: '',
                    custom_area: ''
                },
                valid: false
            };
        }
        default: 
            return state;
    }
}
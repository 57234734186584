import * as types from '../constants/actionTypes';
import initialState from './initialState';
import moment from 'moment';

export default function (state = initialState.cart, action) {
    switch(action.type) {
        case types.SET_CART:
            return Object.assign({},
                state,
                action.payload
            );
        case types.SYNC_ORDER_TO_CART:
            return Object.assign({},
                state,
                action.payload
            );
        case types.CART_UPDATE_ITEMS:
            return Object.assign({},
                state,
                {
                    items: action.payload,
                    expiration: moment().add(1, 'days').format("YYYY-MM-DDTHH:mm:ss")
                }
            );
        case types.RESET_CART: 
            return Object.assign(
                {},
                {
                    items: []
                }
            );
        default: 
            return state;
    }
}
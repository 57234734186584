import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { matchPath } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import OrderSummary from './components/OrderSummary';
import ShippingForm from '../_global/ShippingForm/ShippingForm';
import CheckoutBreadCrumbs from './components/CheckoutBreadCrumbs';
import AddressDetailsCard from './components/AddressDetailsCard';
import PaymentMethod from './components/PaymentMethod';
import MyCartPage from '../_global/MyCartPage/MyCartPage';
import History from '../../History';
import TextInput from '../_global/Form/TextInput';
import Button from '../_global/Form/Button';
// import ShippingZoneList from '../_global/ShippingZoneList/ShippingZoneList';
import EllipsisLoader from '../_global/Loaders/EllipsisLoader'
import { validate } from '../../helpers/validationHelper';

import {
    getShippingZonesList,
    syncOrderToCart,
    numberWithCommas,
    getSiteOption,
    resetCart,
    encryptCheckout
} from '../../helpers/caboodleHelper';

//ACTIONS
import * as checkoutActions from '../../actions/checkout.actions';

import {
        createAddress,
        updateAddress,
        updateCustomerDetails
    } from '../../actions/customer.actions';

import {
        changeShippingFormState
    } from '../../actions/shipping-form.actions';



class Checkout extends React.PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.paymentMethod = React.createRef();
        // get slug value
        const slug = this.getSlugValue(props.location.pathname);
        let header = this.getHeader(slug);

        this.state = {
            submitShippingForm: null,
            header,
            coupon: {
                value: ''
            },
            placingOrder: false,
            fetchingOrder: true,
            shipping_rate: {
                weight: undefined,
                price: undefined
            },
            invalidProducts: [],
            paymentSlug: "cod",
            pickUpLocationError: "",
            pickupForm: {
                errors: {},
                inputs: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: ""
                }
            }
        };
    }

    componentWillMount() {
        if (this.props.cart.items === undefined || this.props.cart.items.length === 0) {
            History.push("/");
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const { order, cart, location } = this.props;
        const routeSlug = this.getSlugValue(location.pathname);
        if (cart && cart.items <= 0) {
            History.push("/");
        }

        if (this.props.location.search.indexOf("apgwsc") > -1) {
            const searchStrings = this.props.location.search.split("&");
            let cid = searchStrings.find(str => {
                return str.indexOf("cid") > -1;
            });

            if (cid) {
                cid = JSON.parse(atob(cid.split("=")[1]));
            }

            this.placeOrder(undefined, cid ? cid : undefined);
        }
        // if (routeSlug === 'payment') {
        //     if (!this.validateProductsLocation(order, this.props.shipping_form)) {
        //         History.push("/checkout");
        //     }
        // }

        const { shipping_zone } = this.props;

        if (!shipping_zone || !shipping_zone.list || shipping_zone.list.length > 0) {
            getShippingZonesList().then(() => {
                const { shipping_form } = this.props;
                this.getShippingFee(shipping_form.inputs.province_id);
            });
        }

        const slug = [
                    "pickup-locations",
                    "service-fee"
                ];

        getSiteOption({slug});
        this.updateOrder(order, cart);

        if (order.delivery_option === "pickup") {
            const pickupForm = Object.assign({}, this.state.pickupForm);
            const loggedIn = this.props.user.hasOwnProperty("name");
            const user = Object.assign({}, this.props.user);
            
            pickupForm.inputs.firstname = order.pickup_firstname ? order.pickup_firstname : (loggedIn ? user.customer.firstname : "");
            pickupForm.inputs.lastname = order.pickup_lastname ? order.pickup_lastname : (loggedIn ? user.customer.lastname : "");
            pickupForm.inputs.phone = order.pickup_contact ? order.pickup_contact : (loggedIn ? user.customer.phone : "");
            pickupForm.inputs.email = order.email_address ? order.email_address : (loggedIn ? user.customer.email : "");
            
            this.setState({
                pickupForm
            });
        }
    }
    
    componentDidUpdate(oldProps) {
        const slug = this.getSlugValue(this.props.location.pathname);
        const oldSlug = this.getSlugValue(oldProps.location.pathname);

        if (slug !== oldSlug) {
            let {paymentSlug} = this.state;

            if (slug !== 'payment') {
                paymentSlug = "cod";
            }

            this.setState({
                header: this.getHeader(slug),
                paymentSlug
            });
        }

        if (oldProps.shipping_form.inputs.province_id != this.props.shipping_form.inputs.province_id) {
            this.getShippingFee(this.props.shipping_form.inputs.province_id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    updateOrder = (order, cart) => {
        const { actions, user } = this.props;
        const items = cart.items;
        let params = {
            items
        };

        if (user.hasOwnProperty("name")) {
            params = Object.assign({}, params, {
                token: user.token
            });
        }

        if (order && order.reference_number) {
            params = Object.assign({}, params, {
                reference_number: order.reference_number,
                delivery_option: order.delivery_option,
                pickup_location: order.pickup_location
            });
        }

        this.setState({
            fetchingOrder: true
        });
        
        return actions.checkoutCart(params).then((res) => {
            if (this._isMounted) {
                this.setState({
                    fetchingOrder: false
                });
            }

            if (res.value.out_of_stock_message) {
                alert("The following item(s) was out of stock and was removed from your cart. " + res.value.out_of_stock_message);
            }

            syncOrderToCart(this.props.order.items);
        });
    }

    getHeader(slug) {
        if (slug === 'payment') {
            return "Payment Method";
        }


        if (slug === 'my-cart') {
            return "";
        }

        return "Shipping Details";
    }
    
    getSlugValue(pathname) {
        const match = matchPath(pathname, { //get slug value
            path: '/checkout/:slug?',
            exact: true,
            strict: false
        });
        return match.params.slug;
    }

    getShippingFee = (province_id) => {
        const { shipping_zone, order } = this.props;
        if ((!order || !order.items) || order.items.length <= 0) {
            return;
        }

        const selected_shipping_zone = shipping_zone.list && shipping_zone.list.find((zone) => {
            return zone.shipping_zone_country.shipping_zone_province.find(zone_province => {
                //eslint-disable-next-line
                return zone_province.province_id == province_id;
            });
        });

        let total_price = 0;
        let total_weight = 0; 

        order.items.map((item) => {
            total_price = total_price + parseFloat(item.subtotal);
            total_weight = total_weight + (parseFloat(item.product.weight) * parseInt(item.quantity));
        });
        
        let shipping_rate = {
            weight: undefined,
            price: undefined
        };

        selected_shipping_zone && selected_shipping_zone.shipping_rates.map(rate => {
            if (rate.type === 'Weight') {
                if ((parseFloat(total_weight) >= parseFloat(rate.minimum)) && (parseFloat(total_weight) <= parseFloat(rate.maximum) || parseFloat(rate.maximum) === 0)) {
                    shipping_rate = Object.assign({} , rate, {
                        weight: rate.amount,
                        zone: selected_shipping_zone.shipping_zone_country
                    });
                }
            } else {
                if ((parseFloat(total_price) >= parseFloat(rate.minimum)) && (parseFloat(total_price) <= parseFloat(rate.maximum) || parseFloat(rate.maximum) === 0)) {
                    shipping_rate = Object.assign({} , rate, {
                        price: rate.amount,
                        zone: selected_shipping_zone.shipping_zone_country
                    });
                }
            }
        });

        this.setState({
            shipping_rate
        });
    }

    btnOnClickSummary = () => {
        const { shipping_form, location, user, order } = this.props;
        const { inputs } = shipping_form;
        const slug = this.getSlugValue(location.pathname);
        // if slug is undefined
        
        if (!slug) {
            if (order.delivery_option === "delivery") {
                this.state.submitShippingForm().then(res => {
                    //if shipping form is not valid don't continue
                    // console.log(res);
                    if (!res) {
                        return;
                    }
                    // if valid save address or update address then proceed to payment
                    this.setState({
                        placingOrder: true,
                        pickUpLocationError: ""
                    }, () => {
                        let shippingForm = Object.assign({}, shipping_form);
                        shippingForm.inputs.id = shippingForm.inputs.id ? shippingForm.inputs.id : 0;
    
                        if (user.hasOwnProperty("name")) {
                            if (user.customer.primary_address) {
                                shippingForm.inputs.id = user.customer.primary_address.id;
                            }
                        }
    
                        if (shippingForm.inputs.id === 0) {
                            shippingForm.inputs.customer_id = user.hasOwnProperty("name") ? user.customer.id : 0;
                            createAddress(shippingForm.inputs).then(res => {
                                if (res.data) {
                                    
                                    if (user.hasOwnProperty("name")) {
                                        user.customer.primary_address = res.data;
                                        updateCustomerDetails(user);
                                    } else {
                                        changeShippingFormState({inputs: Object.assign(shippingForm.inputs, res.data)});
                                        this.getShippingFee(res.data.province_id);
                                    }
                                    this.setState({
                                        placingOrder: false
                                    }, () => {
                                        return History.push('/checkout/payment');
                                    });
                                }
                            });
                        } else {
                            updateAddress(shippingForm.inputs).then(res => {
                                if (res.data) {
                                    this.getShippingFee(res.data.province_id);
    
                                    if (user.hasOwnProperty("name")) {
                                        user.customer.primary_address = res.data;
                                        updateCustomerDetails(user);
                                    }
    
                                    this.setState({
                                        placingOrder: false
                                    }, () => {
                                        return History.push('/checkout/payment');
                                    });
                                }
                            });
                        }
                    });
                });
            } else {
                const { order, cart } = this.props;
                const formValid = this.validatePickupDetails();
                if(order.pickup_location) {
                    if (formValid) {
                        const pickupForm = Object.assign({}, this.state.pickupForm.inputs);
                        const params = Object.assign({}, {
                            items: cart.items,
                            reference_number: order.reference_number,
                            delivery_option: order.delivery_option,
                            pickup_location: order.pickup_location,
                            email_address: pickupForm.email,
                            pickup_contact: pickupForm.phone,
                            pickup_firstname: pickupForm.firstname,
                            pickup_lastname: pickupForm.lastname
                        });

                        this.setState({
                            placingOrder: true,
                            pickUpLocationError: ""
                        }, () => {
                            this.props.actions.checkoutCart(params).then(() => {
                                this.setState({
                                    placingOrder: false
                                });
                    
                                syncOrderToCart(this.props.order.items);
                                return History.push('/checkout/payment');
                            });
                        });
                    }
                } else {
                    this.setState({
                        pickUpLocationError: "Please select a pickup location"
                    });
                }
            }
        } else {
            if (slug === 'payment') {
                return this.placeOrder();
            }
    
            if (slug === 'my-cart') {
                const { order, cart } = this.props;
                this.updateOrder(order, cart);
                return History.push('/checkout');
            }
        }
    }

    validateProductsLocation = (order, shippingAddress) => {
        const {items} = order;
        let isValid = true;
        let invalidProducts = [];

        if (items) {
            items.map(item => {
                if (item.product.merchant) {
                    const merchant = item.product.merchant;
                    
                    if (merchant && merchant.country) {

                        if (merchant.country.reference_id != shippingAddress.inputs.country_id) {
                            isValid = false;
                            invalidProducts.push(item.product);
                        }
                    }
                }
            });
        } else {
            isValid = false;
        }

        this.setState({
            invalidProducts
        });

        return isValid;
    }

    placeOrder = (paymentStatus, asiapayData) => {
        const { order, shipping_form, user } = this.props;
        const {coupon} = this.state;
        let {shipping_rate} = this.state,
            eta = false,
            eta_end = false;

        if (coupon && coupon.data) {
            if(coupon.data.coupon_group.wave_manila === 1) {
                if (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) {
                    shipping_rate = {};
                }
            }
            if(coupon.data.coupon_group.wave_non_manila === 1) {
                if (shipping_form.inputs.province_id < 48 || shipping_form.inputs.province_id > 64) {
                    shipping_rate = {};
                }
            }
        }

        if (order.delivery_option === "pickup") {
            shipping_rate = {};
        } else {
            if (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) {
                eta = moment().add("days", 2).format();
                eta_end = moment().add("days", 5).format();
            } else {
                eta = this.addWeekdays(moment(), 7).format();
                eta_end = this.addWeekdays(moment(), 10).format();
            }
        }

        let params = {
            id: order.id,
            billing_address: shipping_form.inputs.id,
            shipping_address: shipping_form.inputs.id,
            shipping_rate_id: shipping_rate.hasOwnProperty('id') ? shipping_rate.id : 0,
            delivery_option: order.delivery_option,
            pickup_location: order.pickup_location,
            payment_type: (this.props.location.search.indexOf("apgwsc") > -1) ? 'asiapay' : this.paymentMethod.current.state.slug,
            customer_id: user.hasOwnProperty("customer") ? user.customer.id : 0,
            coupon_id: coupon.data ? coupon.data.id : 0,
            paymentStatus
            // digital: true
        };

        if (order.delivery_option === "delivery") {
            params.email_address = shipping_form.inputs.email;
        }

        if (eta) {
            params.date_delivery = eta;
        }

        if (eta_end) {
            params.date_delivery_end = eta_end;
        } 

        if (asiapayData) {
            params = Object.assign({}, params, asiapayData);
        }
        params = {d: encryptCheckout(params)};
        this.setState({
            placingOrder: true
        }, () => {
            this.props.actions.placeOrder(params).then((res) => {
                History.push(`/order/success`);
                if (this._isMounted) {
                    this.setState({
                        placingOrder: false
                    });
                }
            }, (err) => {
                // console.log(err);
                if (err.data && err.data.code && err.data.code === "COUPON_ERROR") {
                    alert("We are sorry but it seems that " + err.data.message.toLowerCase());
                    this.setState({
                        coupon: {
                            value: ''
                        }
                    });
                } else {
                    History.push(`/order/failed`);
                    if (this._isMounted) {
                        this.setState({
                            placingOrder: false
                        });
                    }
                }
            });
        });

        //ASIAPAY TESTING SCRIPT

        // const postData = 'orderRef=09123456&amount=100&currCode=608&lang=E&merchantId=18065730&pMethod=VISA&epMonth=01&epYear=2020&cardNo=4918914107195005&cardHolder=Edward&securityCode=123&payType=N';
        // // action="https://test.pesopay.com/b2cDemo/eng/dPayment/payComp.jsp">
        // // http://test.pesopay.com:8080/b2c2/eng/merchant/api/orderApi.jsp );
        // axios.post('https://test.pesopay.com/b2cDemo/eng/directPay/payComp.jsp', postData)
        //   .then(function (response) {
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });
    }

    processAsiaPayData = () => {
        const { order, shipping_form, user } = this.props;
        const {coupon} = this.state;
        let {shipping_rate} = this.state,
            eta = false,
            eta_end = false;

        if (coupon && coupon.data) {
            if(coupon.data.coupon_group.wave_manila === 1) {
                if (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) {
                    shipping_rate = {};
                }
            }
            if(coupon.data.coupon_group.wave_non_manila === 1) {
                if (shipping_form.inputs.province_id < 48 || shipping_form.inputs.province_id > 64) {
                    shipping_rate = {};
                }
            }
        }

        if (order.delivery_option === "pickup") {
            shipping_rate = {};
        } else {
            if (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) {
                eta = moment().add("days", 2).format();
                eta_end = moment().add("days", 5).format();
            } else {
                eta = this.addWeekdays(moment(), 7).format();
                eta_end = this.addWeekdays(moment(), 10).format();
            }
        }

        const params = {
            id: order.id,
            billing_address: shipping_form.inputs.id,
            shipping_address: shipping_form.inputs.id,
            shipping_rate_id: shipping_rate.hasOwnProperty('id') ? shipping_rate.id : 0,
            delivery_option: order.delivery_option,
            pickup_location: order.pickup_location,
            payment_type: (this.props.location.search.indexOf("apgwsc") > -1) ? 'asiapay' : ((this.paymentMethod && this.paymentMethod.current) ? this.paymentMethod.current.state.slug : ''),
            customer_id: user.hasOwnProperty("customer") ? user.customer.id : 0,
            coupon_id: coupon.data ? coupon.data.id : 0
            // digital: true
        };

        if (order.delivery_option === "delivery") {
            params.email_address = shipping_form.inputs.email;
        }

        if (eta) {
            params.date_delivery = eta;
        }

        if (eta_end) {
            params.date_delivery_end = eta_end;
        } 

        return btoa(JSON.stringify(params));
    }

    getCouponContent = () => {
        const { coupon } = this.state;
        const { order } = this.props;

        if (coupon.exist === true && coupon.claimed === false && coupon.valid === true) {
            return (
                <>
                    <div className="column-2 pad-10">
                        <div>
                            Coupon Discount
                        </div>
                        <div className="txt-bold">
                            ({coupon.value})
                        </div>
                    </div>
                    <div className="pad-10 text-right flex align-center flex-end txt-error txt-bold">
                        {this.computeCouponValue(coupon, order)}
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="coupon-container column-3 flex">
                        <TextInput
                            placeholder="Coupon here"
                            value={coupon.value}
                            type="text"
                            name="coupon"
                            onChange={(e) => {
                                const value = e.target.value;
                                this.setState(prevState => ({
                                    coupon: Object.assign({},
                                        prevState.coupon,
                                        {
                                            value: value,
                                            message: ""
                                        }
                                    )
                                }));
                            }}
                        />
                        <Button
                            loading={coupon.loading}
                            color="secondary"
                            onClick={() => this.handleCouponValidation()}
                        >
                            Apply
                        </Button>
                    </div>
                    <div className="txt-error txt-small column-3 pad-left pad-right">
                        {coupon.message}
                    </div>
                </>
            );
        }
    }

    handleCouponValidation = () => {
        const { coupon } = this.state;
        const { order } = this.props;

        this.setState(prevState => ({
            coupon: Object.assign({},
                prevState.coupon,
                {
                    loading: true
                }
            )
        }));
        
        //48-64 --- metron manila provcinve ids

        this.props.actions.validateCoupon({
            code: coupon.value,
            checkout_token: order.token 
        }).then(res => {
            this.setState(prevState => ({
                coupon: Object.assign({},
                    prevState.coupon,
                    res.value,
                    {
                        loading: false
                    }
                )
            }));
        }).catch((err) => {
            this.setState(prevState => ({
                coupon: Object.assign({},
                    prevState.coupon,
                    {
                        loading: false,
                        message: err.data.message
                    }
                )
            }));
        });
    }

    computeCouponValue = (coupon, order) => {
        const formattedPrice = coupon.data.coupon_group.formatted_price.split(" ");

        if (coupon.data.coupon_group.value_type === "fixed") {
            coupon.absoluteValue = formattedPrice[1];
            this.setState({
                coupon
            });
        } else {
            const orderTotal = order.converted_subtotal.split(" ");
            coupon.absoluteValue = (parseFloat(coupon.data.coupon_group.value) / 100) * parseFloat(orderTotal[1]).toFixed(2);
            this.setState({
                coupon
            });
        }

        return formattedPrice[0] + " " + numberWithCommas(parseFloat(coupon.absoluteValue).toFixed(2));
    }

    handleSelectPayment = (slug) => {
        this.setState({
            paymentSlug: slug
        });
    }

    handlePaypalSuccess = (payment) => {
        payment.gateway = "paypal";
		this.placeOrder(btoa(JSON.stringify(payment)));
    }
    
	handlePaypalError = (error)=> {
        // alert(error);
    }
    
	handlePaypalCancelled = (data) => {
        // alert(data);
	}

    render() {
        const { order, shipping_form, cart, site_options } = this.props;
        const { header, placingOrder, fetchingOrder, coupon, pickupForm } = this.state;
        let {shipping_rate} = this.state;
        const btnText = this.getBtnText(header),
            delivery_option = order.delivery_option;
        let couponInput = undefined,
            serviceFee = site_options.service_fee;

        if (header === 'Payment Method') {
            couponInput = this.getCouponContent();
        }
        
        //redirect to cart if no order object
        if (!order.id && !fetchingOrder) {
            History.push('/checkout/my-cart');
        }

        if (coupon && coupon.data) {
            if(coupon.data.coupon_group.wave_manila === 1) {
                if (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) {
                    shipping_rate = {};
                }
            }
            if(coupon.data.coupon_group.wave_non_manila === 1) {
                if (shipping_form.inputs.province_id < 48 || shipping_form.inputs.province_id > 64) {
                    shipping_rate = {};
                }
            }
            if(coupon.data.coupon_group.wave_service_fee === 1) {
                serviceFee = 0;
            }
        }

        if (order.delivery_option === "pickup") {
            shipping_rate = {};
        }
        
        // console.log(delivery_option);
        return (
            <div className={`checkout-page ${this.getSlugValue(this.props.location.pathname)}`}>
                <div className="container">
                    <CheckoutBreadCrumbs 
                        header={header}
                        delivery_option={delivery_option}
                    />
                    <div>
                        {
                            header === "Shipping Details" && !fetchingOrder && (
                                <div className="margin-bottom-30">
                                    <h1 className="header">
                                        Delivery Options
                                    </h1>
                                    <div className="grid grid-2 grid-gap-20 align-center">
                                        <div
                                            className={`flex align-center clickable location-card pad-20 ${delivery_option === "delivery" ? "active" : "inactive"}`}
                                            onClick={() => this.setDeliveryOption("delivery")}
                                        >
                                            <i className="far fa-truck txt-xxlarge txt-warning" />
                                            <div className="flex-1 line-height-1 margin-left-10">
                                                Delivery
                                            </div>
                                        </div>
                                        <div
                                            className={`flex align-center clickable location-card pad-20 ${delivery_option === "pickup" ? "active" : "inactive"}`}
                                            onClick={() => this.setDeliveryOption("pickup")}
                                        >
                                            <i className="far fa-handshake txt-xxlarge txt-warning" />
                                            <div className="flex-1 line-height-1 margin-left-10">
                                                Pick up
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        order.delivery_option === "pickup" ? (
                                            <>
                                                <h1 className="header margin-top-30">
                                                    Pickup Information
                                                </h1>
                                                <div className="grid grid-2 grid-gap-20 margin-bottom-20">
                                                    <div className="">
                                                        <TextInput
                                                            value={pickupForm.inputs.firstname}
                                                            error={pickupForm.errors.firstname}
                                                            label="First name"
                                                            type="text"
                                                            placeholder="First name"
                                                            name="firstname"
                                                            onChange={e => this.handlePickupDetailsInput(e)}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <TextInput
                                                            value={pickupForm.inputs.lastname}
                                                            error={pickupForm.errors.lastname}
                                                            label="Last name"
                                                            type="text"
                                                            placeholder="Last name"
                                                            name="lastname"
                                                            onChange={e => this.handlePickupDetailsInput(e)}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <TextInput
                                                            value={pickupForm.inputs.email}
                                                            error={pickupForm.errors.email}
                                                            label="Email"
                                                            type="text"
                                                            placeholder="Email"
                                                            name="email"
                                                            onChange={e => this.handlePickupDetailsInput(e)}
                                                        />
                                                    </div>
                                                    <div className="">
                                                        <TextInput
                                                            value={pickupForm.inputs.phone}
                                                            error={pickupForm.errors.phone}
                                                            label="Contact number"
                                                            type="text"
                                                            placeholder="Contact number"
                                                            name="phone"
                                                            onChange={e => this.handlePickupDetailsInput(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) : false
                                    }
                                </div>
                            )
                        }
                        {header &&
                            <h1 className="header margin-bottom-20">
                                {header === "Shipping Details" ? (!fetchingOrder ? (delivery_option === "pickup" ? "Pickup Locations" : header) : "") : header}
                                {
                                    this.state.pickUpLocationError && (
                                        <div className="txt-bold txt-medium txt-error">
                                            <i className="fas fa-exclamation-circle txt-error" /> {this.state.pickUpLocationError}
                                        </div>
                                    )
                                }
                            </h1>
                        }
                        {this.getMainComponentToBeRender(header)}
                    </div>
                    {cart && cart.items.length > 0 &&
                        <div className="cards-container">
                            {
                                header === 'Payment Method' && order.delivery_option === "delivery" && (
                                    <AddressDetailsCard 
                                        onClickEdit={() => History.push('/checkout')}
                                        {
                                            ...shipping_form.inputs
                                        }
                                    />
                                )
                            }
                            {
                                header === 'Payment Method' && order.delivery_option === "pickup" && (
                                    <AddressDetailsCard 
                                        onClickEdit={() => History.push('/checkout')}
                                        pickup={true}
                                        pickupLocation={order.pickup_location}
                                        fullname={order.pickup_firstname + " " + order.pickup_lastname}
                                        email={order.email_address}
                                        phone={order.pickup_contact}
                                    />
                                )
                            }
                            {
                                order.delivery_option === "delivery" && shipping_form.inputs.province_id > 0 && (
                                    <div className="margin-bottom-10">
                                        <div className="card-container">
                                            <div className="column-3 pad-10 flex align-center">
                                                <div className="flex-1">
                                                    <div className="txt-large txt-bold">
                                                        Delivery
                                                    </div>
                                                    <div className="txt-regular txt-small txt-gray">
                                                        {(shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) ? "2 to 5" : "7 to 10 business"} days
                                                    </div>
                                                </div>
                                                {
                                                    (shipping_form.inputs.province_id >= 48 && shipping_form.inputs.province_id <= 64) ? (
                                                        <div className="txt-bold">
                                                            ETA : {moment().add('days', 2).format("MMM DD")} to {moment().add('days', 5).format("MMM DD, YYYY")}
                                                        </div>
                                                    ) : (
                                                        <div className="txt-bold">
                                                            ETA : {this.addWeekdays(moment(), 7).format("MMM DD")} to {this.addWeekdays(moment(), 10).format("MMM DD, YYYY")}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.getSlugValue(this.props.location.pathname) != "my-cart" && (
                                    <OrderSummary
                                        shipping_rate={shipping_rate}
                                        orderLoading={fetchingOrder}
                                        order={order}
                                        btnLoading={placingOrder}
                                        btnText={btnText}
                                        btnOnClick={this.btnOnClickSummary}
                                        couponInput={couponInput}
                                        invalidProducts={this.state.invalidProducts}
                                        payment={this.state.paymentSlug}
                                        paypalHandles={{
                                            onSuccess: this.handlePaypalSuccess,
                                            onError: this.handlePaypalError,
                                            onCancel: this.handlePaypalCancelled
                                        }}
                                        couponData={this.state.coupon}
                                        aispayEncodedData={this.processAsiaPayData()}
                                        serviceFee={serviceFee}
                                    />
                                )
                            }
                        </div>
                    }
                </div>
                {
                    this.state.placingOrder ? (
                        <div className="screen-overlay flex align-center justify-center">
                            <div>
                                <div>
                                    <div className="circle-loader margin-auto">
                                        <div/>
                                        <div/>
                                        <div/>
                                    </div>
                                </div>
                                <div
                                    className="text-center margin-top-20 fade-in-down txt-white"
                                    style={{animationDelay: "5s"}}
                                >
                                    Please wait a moment. This may take a while.
                                </div>
                            </div>
                        </div>
                    ) : false
                }
            </div>
       );
    }

    addWeekdays = (date, days) => {
        date = moment(date); // use a clone
        while (days > 0) {
            date = date.add(1, 'days');
            // decrease "days" only if it's a weekday.
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
            days -= 1;
            }
        }
        return date;
    }

    setDeliveryOption = (option) => {
        const {order} = this.props;
        order.delivery_option = option;
        this.props.actions.updateOrder(order);
    }

    handleSetPickupLocation = (location) => {
        const {order} = this.props;
        order.pickup_location = location;
        this.setState({
            pickUpLocationError: ""
        }, () => {
            this.props.actions.updateOrder(order);
        });
    }

    getMainComponentToBeRender = (header) => {
        if (header === 'Shipping Details') {
            let address = this.props.shipping_form.inputs;
            const {site_options, order} = this.props,
                {fetchingOrder, pickupForm} = this.state;
            let pickup_locations = [];

            if (site_options && site_options.pickup_locations) {
                pickup_locations = site_options.pickup_locations.split("---");
            }

            if (this.props.user.hasOwnProperty("name")) {
                address = this.props.user.customer.primary_address ? this.props.user.customer.primary_address : address;
                address.email = this.props.user.email;
            }

            return (
                <div>
                    {
                        fetchingOrder ? (
                            <EllipsisLoader
                                dot="black"
                            />
                        ) : (
                            this.props.order.delivery_option === "delivery" ? (
                                <ShippingForm 
                                    initialState={address}
                                    setCallable={callable => this.setState({submitShippingForm: callable})}
                                />
                            ) : (
                                <div>
                                    {
                                        pickup_locations.map(location => {
                                            return(
                                                <div
                                                    className="flex align-center clickable location-card pad-20 margin-bottom-10"
                                                    onClick={() => this.handleSetPickupLocation(location)}
                                                    key={location.replace(/ /g, "")}
                                                >
                                                    <div className="flex-1 line-height-1">
                                                        {location}
                                                    </div>
                                                    <i className={`${order.pickup_location === location ? "fas fa-check-circle" : "far fa-circle"} txt-xxlarge txt-warning`} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        )
                    }
                </div>
            );
        }

        if (header === 'Payment Method') {
            return (<PaymentMethod
                ref={this.paymentMethod}
                onSelectPayment={this.handleSelectPayment}
                order={this.props.order}
                codValid={this.validateRestrictedZones()}
            />);
        }
        
        return <MyCartPage btnOnClickSummary={this.btnOnClickSummary} loading={this.state.fetchingOrder} />;
    }

    validateRestrictedZones = () => {
        const provincial = this.props.shipping_zone && this.props.shipping_zone.list && this.props.shipping_zone.list.find(zone => {return zone.name === "Provincial"});
        let findProvince = false,
            isValid = true;

        if (provincial && this.props.shipping_form.inputs.province_id) {
            if (provincial.shipping_zone_country && provincial.shipping_zone_country.shipping_zone_province) {
                
                findProvince = provincial.shipping_zone_country.shipping_zone_province.find(province_zone => {
                    return province_zone.province_id == this.props.shipping_form.inputs.province_id;
                });
                
                if (findProvince) {
                    isValid = false;
                }
            }
        }

        return isValid;
    }
    
    getBtnText = (header) => {
        if (header === 'Payment Method') {
            return "PLACE YOUR ORDER NOW";
        }
        if (header === 'Shipping Details') {
            return "PROCEED TO PAYMENT";
        }
        return "PROCEED TO CHECKOUT";
    }

    handlePickupDetailsInput = (e) => {
        const pickupForm = Object.assign({}, this.state.pickupForm);

        pickupForm.inputs[e.target.name] = e.target.value;
        this.setState({
            pickupForm
        });
    }

    validatePickupDetails = () => {
        let rules = {
            properties: {
                firstname: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your firstname"
                },
                lastname: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your lastname"
                },
                email: {
                    type: 'string',
                    required: true,
                    format: 'email',
                    allowEmpty: false,
                    message: "Please enter your email"
                },
                phone: {
                    type: 'string',
                    required: true,
                    allowEmpty: false,
                    message: "Please enter your contact number"
                }
            }
        };
        const pickupForm = Object.assign({}, this.state.pickupForm);
        const inputs = Object.assign({}, pickupForm.inputs);
        const result = validate(inputs, rules, undefined, true);
        
        pickupForm.errors = Object.assign({}, result.errors);
        pickupForm.valid = Object.assign({}, result.valid);
        this.setState({
            pickupForm
        });

        return Object.keys(result.errors).length === 0;
    }
}


function mapStateToProps(state, ownProps) {
	return {
        cart: state.cart,
        order: state.order,
        shipping_form: state.shipping_form,
        user: state.user,
        shipping_zone: state.shipping_zone,
        site_options: state.site_options
	};
}

Checkout.propTypes = {
    cart: PropTypes.object,
    order: PropTypes.object,
    actions: PropTypes.object,
    location: PropTypes.object,
    shipping_form: PropTypes.object,
    user: PropTypes.object
};

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(checkoutActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
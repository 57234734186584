import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.modal, action) {
    switch(action.type) {
        case types.SET_MODAL_CONTENT:
            return Object.assign({},
                state, 
                {
                    ...action.payload
                }
            );
        case types.TOGGLE_MODAL:
            return Object.assign({},
                state, 
                {
                    open: action.payload.isOpen ? action.payload.isOpen : !state.open,
                    animateIn: action.payload.animateIn,
                    animateOut: action.payload.animateOut,

                }
            );
        default: 
            return state;
    }
}
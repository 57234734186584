import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.collections, action) {
    switch(action.type) {
        case types.GET_FEATURED_COLLECTIONS + types.PENDING:
            return Object.assign({},
                state,
                {
                    loading: true
                }
            );
        case types.GET_FEATURED_COLLECTIONS + types.REJECTED:
            return Object.assign({},
                state, 
                {
                    loading: false
                }
            );
        case types.GET_FEATURED_COLLECTIONS + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    featured: action.payload.data
                },
                {
                    loading: false
                }
            );
        case types.UPDATE_SELECTED_COLLECTION: 
            return Object.assign({},
                state,
                {
                    selected: action.payload
                },
                {
                    loading: false
                }
            );
        default: 
            return state;
    }
}
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.page, action) {
    switch(action.type) {
        case types.GET_PAGE + types.PENDING:
            return Object.assign({},
                state,
                {
                    loading: true
                }
            );
        case types.GET_PAGE + types.REJECTED:
            return Object.assign({},
                state, 
                {
                    loading: false
                }
            );
        case types.GET_PAGE + types.FULFILLED:
            let data = {};
            if (action.payload.data.slug) {
                data = {
                    [action.payload.data.slug] : action.payload.data
                }
            }

            return Object.assign({},
                state,
                data,
                {
                    loading: false
                }
            );
        case types.GET_PAGE + "_RESET":
            return Object.assign({},
                {
                    loading: true
                }
            );
        default: 
            return state;
    }
}
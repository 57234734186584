import React from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import {
        getPage,
        getFeatured,
        getBestSeller,
        makeASquare,
        extractAssetObj
} from '../../helpers/caboodleHelper';
import {
    getBrandsList
} from '../../actions/brands.actions';
import {
    findByTag
} from '../../actions/products.actions';

import Banner from '../_global/Banner/Banner';
import ProductsMoreContainer from '../_global/Elements/ProductsMoreContainer';
import Slider from '../_global/Elements/Slider';

class Home extends React.Component {

    componentDidMount() {
        getPage('home');
        getBestSeller();
        // getFeatured(9);
        getBrandsList();
        findByTag("travel sim collection");
        findByTag("travel buddies");
        findByTag("travel essentials");
        window.addEventListener("resize", this.handleMakeASquare);
    }

    componentDidUpdate() {
        makeASquare(document.getElementsByClassName("product-container-image"));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleMakeASquare);
    }

    handleMakeASquare = () => {
        makeASquare(document.getElementsByClassName("product-container-image"));
    }

    render() {
        const {
                page,
                products,
                brands
            } = this.props;
        let featuredBrands = [];

        if (brands && brands.list) {
            featuredBrands = brands.list.filter(brand => {
                return brand.featured === 1;
            });
        }

        return (
            <div className="home page-body-container">
                <Banner
                    loading={page.loading && page.home === undefined}
                    banners={page && page.home && page.home.banners && page.home.banners.items}
                />
                {
                    page && page.home && page.home["banner-footer"] && page.home["banner-footer"].items && (
                        <div className="steps gradi grid-fix-3">
                            {
                                page.home["banner-footer"].items.map(data => {
                                    return (
                                        <div>
                                            <div className="txt-xlarge txt-bold">
                                                {data.title}
                                            </div>
                                            {
                                                data.sub_text && (
                                                    <div className="txt-medium">
                                                        {data.sub_text}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    )
                }
                <div className="page-container">
                    {
                        products && products.best_sellers && products.best_sellers.length > 0 && (
                            <div className="margin-top-100">
                                <ProductsMoreContainer
                                    products={products && products.best_sellers}
                                    header="Best Sellers"
                                    moreBtnLink="/products?best_seller=1"
                                    moreBtnText="Discover more deals"
                                    cardGap={50}
                                    limit={3}
                                    id={"deals"}
                                />
                            </div>
                        )
                    }
                    {
                        products && products.tagged && products.tagged.travel_sim_collection && products.tagged.travel_sim_collection.length > 0 && (
                            <div className="margin-top-100">
                                <ProductsMoreContainer
                                    products={products.tagged.travel_sim_collection}
                                    gridCol={4}
                                    header="Travel Sim Collection"
                                    moreBtnLink="/"
                                    moreBtnText="Discover more products"
                                    cardGap={50}
                                    limit={4}
                                    id={"tavelSim"}
                                />
                            </div>
                        )
                    }
                    {
                        page && page.home && page.home.slider1 && page.home.slider1.items && page.home.slider1.items.length > 0 && (
                            <div className="margin-top-100 fade-in">
                                <Slider
                                    data={page.home.slider1.items}
                                />
                            </div>
                        )
                    }
                    {
                        products && products.tagged && products.tagged.travel_buddies && products.tagged.travel_buddies.length > 0 && (
                            <div className="margin-top-100">
                                <ProductsMoreContainer
                                    products={products.tagged.travel_buddies}
                                    gridCol={3}
                                    header="Your Best Travel Buddies"
                                    moreBtnLink="/"
                                    moreBtnText="Discover more products"
                                    cardGap={50}
                                    limit={3}
                                    id={"travelBuddies"}
                                />
                            </div>
                        )
                    }
                    {
                        page && page.home && page.home.slider2 && page.home.slider2.items && page.home.slider2.items.length > 0 && (
                            <div className="margin-top-100 fade-in">
                                <Slider
                                    data={page.home.slider2.items}
                                />
                            </div>
                        )
                    }
                    {
                        products && products.tagged && products.tagged.travel_essentials && products.tagged.travel_essentials.length > 0 && (
                            <div className="margin-top-100">
                                <ProductsMoreContainer
                                    products={products.tagged.travel_essentials}
                                    gridCol={3}
                                    header="Travel Essentials"
                                    moreBtnLink="/"
                                    moreBtnText="Discover more products"
                                    cardGap={50}
                                    limit={3}
                                    id={"tavelSim"}
                                />
                            </div>
                        )
                    }
                    {
                        page && page.home && page.home["travel-survival-guide"] && page.home["travel-survival-guide"] && (
                            <div className="margin-top-100">
                                <div className="txt-header txt-bold text-center">
                                    #TravelSurvivalGuide Blog
                                </div>
                                <div className="grid grid-2 grid-xs-1 grid-gap-30 margin-top-50">
                                    {
                                        page.home["travel-survival-guide"]["left-card"] && (
                                            <div
                                                className="pad-50 txt-white  fade-in-up"
                                                key={"travel-guide-left"}
                                                style={{
                                                    backgroundColor: page.home["travel-survival-guide"]["left-card"].background_color ? page.home["travel-survival-guide"]["left-card"].background_color : "#F8BD05"
                                                }}
                                            >
                                                <h1 className="txt-bold txt-header-2">
                                                    {
                                                        page.home["travel-survival-guide"]["left-card"].title
                                                    }
                                                </h1>
                                                <div className="txt-large">
                                                    {
                                                        page.home["travel-survival-guide"]["left-card"].content
                                                    }
                                                </div>
                                                {
                                                    page.home["travel-survival-guide"]["left-card"].button_link && (
                                                        <a
                                                            className="btn btn-clear white btn-sqr btn-pad-xlarge txt-bold margin-top-30"
                                                            traget="_blank"
                                                            href={page.home["travel-survival-guide"]["left-card"].button_link}
                                                        >
                                                            {
                                                                page.home["travel-survival-guide"]["left-card"].button_text ? page.home["travel-survival-guide"]["left-card"].button_text : "READ NOW"
                                                            }
                                                        </a>       
                                                    )
                                                }
                                                {
                                                    page.home["travel-survival-guide"]["left-card"].footer_text && (
                                                        <div className="text-right margin-top-50">
                                                            {
                                                                page.home["travel-survival-guide"]["left-card"].footer_text
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        page.home["travel-survival-guide"]["right-card"] && (
                                            <div
                                                className="pad-50 txt-dark-link text-center fade-in-up"
                                                key={"travel-guide-right"}
                                                style={{
                                                    backgroundColor: page.home["travel-survival-guide"]["right-card"].background_color ? page.home["travel-survival-guide"]["right-card"].background_color : "#F6EDE3"
                                                }}
                                            >
                                                <h1 className="txt-bold txt-header-2">
                                                    {
                                                        page.home["travel-survival-guide"]["right-card"].title
                                                    }
                                                </h1>
                                                <div className="txt-large">
                                                    {
                                                        page.home["travel-survival-guide"]["right-card"].content
                                                    }
                                                </div>
                                                {
                                                    page.home["travel-survival-guide"]["right-card"].button_link && (
                                                        <a
                                                            className="btn btn-primary btn-sqr btn-pad-xlarge txt-bold margin-top-30"
                                                            traget="_blank"
                                                            href={page.home["travel-survival-guide"]["right-card"].button_link}
                                                        >
                                                            {
                                                                page.home["travel-survival-guide"]["right-card"].button_text ? page.home["travel-survival-guide"]["right-card"].button_text : "READ NOW"
                                                            }
                                                        </a>       
                                                    )
                                                }
                                                {
                                                    page.home["travel-survival-guide"]["right-card"].footer_text && (
                                                        <div className="text-right margin-top-50">
                                                            {
                                                                page.home["travel-survival-guide"]["right-card"].footer_text
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        brands && featuredBrands && featuredBrands.length > 0 && (
                            <div className="margin-top-100 pad-bottom-100">
                                <div className="txt-header txt-bold text-center">
                                    Featured Brands
                                </div>
                                <div className="partner-list grid grid-4 grid-xs-2 grid-gap-50 margin-top-50">
                                    {
                                        featuredBrands.map((brand, index) => {
                                            return (
                                                <div
                                                    className="partner-card fade-in-up"
                                                    key={index + "brands"}
                                                    style={{
                                                        animationDelay: `${(index + 1) * 0.3}s`
                                                    }}
                                                >
                                                    <img
                                                        src={extractAssetObj(brand, ["asset", "path"])}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        page && page.home && page.home.partners && page.home.partners.items.length > 0 && (
                            <div className="margin-top-100 margin-bottom-100">
                                <div className="txt-header txt-bold text-center">
                                    Our Partners
                                </div>
                                <div className="partner-list grid grid-4 grid-xs-2 grid-gap-50 margin-top-50">
                                    {
                                        page.home.partners.items.map((partner, index) => {
                                            return (
                                                <div
                                                    className="partner-card fade-in-up"
                                                    key={index + "partners"}
                                                    style={{
                                                        animationDelay: `${(index + 1) * 0.3}s`
                                                    }}
                                                >
                                                    <img
                                                        src={extractAssetObj(partner, ["image", "path"])}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
	return {
        page: state.page,
        products: state.products,
        collections: state.collections,
        brands: state.brands
        // articles: state.article.featured_list,
        // projects: state.projects.featured_list

	};
}

Home.propTypes = {
    page: PropTypes.object,
    products: PropTypes.object,
    // articles: PropTypes.array,
    // projects: PropTypes.array
};

export default connect(mapStateToProps, null)(Home);

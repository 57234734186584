import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.site_options, action) {
    switch(action.type) {
        case types.GET_SITE_OPTION + types.PENDING: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case types.GET_SITE_OPTION + types.REJECTED: {
            return Object.assign({}, state, {
                loading: false
            });
        }
        case types.GET_SITE_OPTION + types.FULFILLED: {
            return Object.assign({}, state, {
                ...action.payload.data
            });
        }
        default: 
            return state;
    }
}
import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
// import PropTypes from 'prop-types';
import TextInput from '../Form/TextInput';
import History from '../../../History';

class AutoSuggest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            keyword: queryString.parse(props.location.search).keyword
        };
    }

    render() {
        const { keyword } = this.state;
        return (
            <div className="search-container auto-suggest">
                <TextInput
                    value={keyword}
                    onKeyEnter={() => {
                        if (!keyword) {
                            return;
                        }
                        const { location } = this.props;
                        if (location.pathname === '/products') {
                            let params = queryString.parse(location.search);
                            params = Object.assign(params, {
                                keyword: keyword
                            });
                            return History.push({
                                pathname: '/products',
                                search: `?${queryString.stringify(params)}`
                            });
                        }
                        return History.push(`/products?keyword=${keyword}`);
                    }}
                    placeholder="What are you looking for?"
                    onChange={(e) => this.setState({ keyword: e.target.value })}
                />
                <i className="far fa-search" />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
	return {
	};
}

AutoSuggest.propTypes = {

};

export default connect(mapStateToProps, null)(AutoSuggest);

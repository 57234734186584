import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
        makeASquare,
        getPage,
        extractAssetObj,
        slugify
    } from '../../helpers/caboodleHelper';
import ArticleCard from '../_global/ArticleCard/ArticleCard';
import { setSelectedBlog } from '../../actions/blogs.actions';
import history from '../../History';

class Blogs extends React.PureComponent {

    componentDidMount() {
        getPage('blogs');
        window.addEventListener("resize", this.handleMakeASquare);
    }

    componentDidUpdate() {
        makeASquare(document.getElementsByClassName("article-image"), "width", 0.8);
    }

    componentWillMount() {
        window.removeEventListener("resize", this.handleMakeASquare);
    }

    handleMakeASquare = () => {
        makeASquare(document.getElementsByClassName("article-image"), "width", 0.8);
    }

    handleSelectBlog = (blog) => {
        const slug = slugify(blog.title);
        setSelectedBlog(blog);
        history.push({
            pathname: `/blogs/${slug}/view`,
            params: {slug}
        });
    }

    render() {
        const {page} = this.props;
        const {blogs} = page;
        let featured = false;

        if (blogs === undefined || (page.loading && blogs.contents === undefined)) {
            return (
                <div className="blogs page-body-container">
                    <div className="page-banner-image ph-item" />
                    <div className="page-container">
                        <div className="margin-top-100 grid grid-3 blog-list">
                            {
                                [...Array(6)].map((v, i) => {
                                    return (
                                        <ArticleCard
                                            key={i}
                                            loading={true}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        }

        if (blogs.contents && blogs.contents.length === 1) {
            featured = blogs.contents[0];
        } else {
            featured = blogs.contents.find(blog => {
                return blog.featured == 1;
            });
        }

        return (
            <div className="blogs page-body-container">
                {
                    blogs.contents.length > 0 ? (
                        <Fragment>
                            <div
                                className="page-banner-image flex align-center"
                                style={{backgroundImage: `url(${extractAssetObj(featured, ["image", "path"])})`}}
                            >
                                <div className="pad-100 txt-white banner-text">
                                    <div className="background-overlay">
                                        <div className="txt-large">
                                            FEATURED NEWS
                                        </div>
                                        <div className="txt-header txt-bold">
                                            {featured.title}
                                        </div>
                                        <div
                                            className="txt-medium margin-top-10"
                                            dangerouslySetInnerHTML={{__html: featured.blurb}}
                                        />
                                        <button
                                            className="btn btn-oblivion btn-sqr margin-top-50 txt-bold"
                                            onClick={() => this.handleSelectBlog(featured)}
                                        >
                                            READ NOW
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="page-container">
                                <h1 className="txt-xlarge margin-top-100 txt-bold text-center">
                                    LATEST ARTICLES
                                </h1>
                                <div className="margin-top-100 grid grid-3 blog-list">
                                    {
                                        blogs.contents.map((blog, key) => {
                                            return (
                                                <div key={key}>
                                                    <ArticleCard
                                                        article={blog}
                                                        onSelectBlog={this.handleSelectBlog}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="text-center txt-xxlarge">
                            Sorry, there is nothing to see here.
                        </div>
                    )
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
	return {
        page: state.page,
        products: state.products,
        collections: state.collections
        // articles: state.article.featured_list,
        // projects: state.projects.featured_list

	};
}

Blogs.propTypes = {
    page: PropTypes.object,
    products: PropTypes.object,
    // articles: PropTypes.array,
    // projects: PropTypes.array
};

export default connect(mapStateToProps, null)(Blogs);

import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
// import {
//             getCustomerDetails,
//             updateCustomerDetails
//         } from '../../actions/customer.actions';
import {
        toggleCollapse,
        setModalContent,
        toggleModal
    } from '../../helpers/caboodleHelper';

import {
    voidOrder
} from '../../actions/customer.actions';

import { ASSET_URL } from '../../constants/config';
import history from '../../History';
import FormattedTotal from '../_global/Elements/FormattedTotal';
import noResult from '../../assets/img/no_result.png';

class UserOrders extends React.Component {

    state = {
        userInfoForm: {},
        voidList: []
    }

    componentDidMount() {}

    render() {
        const {orders} = this.props.user.hasOwnProperty("name") ? this.props.user.customer : {orders: []};
        const {loading} = this.props;
        
        return (
            <div className="user-orders">
                <div className="txt-header">
                    Order History
                </div>
                {/* <div className="display-controls flex align-center margin-top-30">
                    <div className="flex-1 filter-controls">
                    </div>
                    <div className="sort-control flex align-center">
                        <span className="txt-small margin-right no-whitespace">
                            Sort by: 
                        </span>
                        <select className="form-control txt-medium">
                            <option value="date-completed">
                                Date completed
                            </option>
                            <option value="price-asc">
                                Price (Ascending)
                            </option>
                            <option value="price-desc">
                                Price (Descending)
                            </option>
                            <option value="items-asc">
                                Number of items (Ascending)
                            </option>
                            <option value="items-desc">
                                Number of items (Descending)
                            </option>
                        </select>
                    </div>
                </div> */}
                <div className="order-list margin-top-40">
                    {
                        loading ? (
                            <div className="ph-item">
                                <div className="ph-col-12">
                                    <div className="ph-row">
                                        {
                                            [...Array(5)].map((arr, i) => {
                                                return(
                                                    <div className={`ph-col-12 order-card big`} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {
                                    orders && orders.length > 0 ?
                                        (
                                            orders.map(order => {
                                                return (
                                                    <div className="order-card margin-bottom-10" key={order.id}>
                                                        <div className="flex order-card-header">
                                                            <div className="flex-1">
                                                                <div className="flex-1 flex flex-rm-xs align-center">
                                                                    <div className="flex align-center margin-right-10">
                                                                        <i className="far fa-boxes txt-gray-med margin-right-10" />
                                                                        <div>
                                                                            # {order.reference_number}
                                                                        </div>
                                                                    </div>
                                                                    <div className={`badge badge-${order.payment_status_id == 2 ? 'success' : 'warning'} txt-white`}>
                                                                        {
                                                                            order.payment_status ? (
                                                                                <Fragment>
                                                                                    {order.payment_status.name} {order.payment_status.name === "pending" ? " payment" : ""}
                                                                                </Fragment>
                                                                            ) : (
                                                                                "N/A"
                                                                            )
                                                                        }
                                                                    </div>
                                                                    <div className={`badge badge-${order.overall_status == 'delivered' ? 'success' : (order.overall_status ? 'danger' : 'info')} margin-left-10`}>
                                                                        {order.overall_status}
                                                                    </div>
                                                                </div>
                                                                <div className="flex align-center margin-right-20">
                                                                    <span className="txt-gray margin-right txt-small txt-bold">
                                                                        Items:
                                                                    </span>
                                                                    <span className="badge badge-success">
                                                                        {order.items.length}
                                                                    </span>
                                                                </div>
                                                                <div className="flex align-center txt-small txt-bold">
                                                                    <span className="txt-gray margin-right">
                                                                        Date ordered:
                                                                    </span>
                                                                    <span>
                                                                        {moment(order.created_at).format('MMMM D, YYYY')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="clickable margin-left-20"
                                                                onClick={e => toggleCollapse(e, `#orderBody${order.id}`)}    
                                                            >
                                                                <i className="far fa-chevron-down order-collapse" />
                                                            </div>
                                                        </div>
                                                        <div
                                                            id={`orderBody${order.id}`} 
                                                            className="order-card-body collapse-container"
                                                        >
                                                            <div className="inline-block full-width">
                                                                {
                                                                    order.items.map(item => {
                                                                        return(
                                                                            <div className="item-card">
                                                                                <div
                                                                                    className="flex align-center clickable"
                                                                                    key={item.id}
                                                                                    onClick={() => history.push(`/product/${item.product.slug}/view`)}
                                                                                >
                                                                                    <div className="item-img flex align-center justify-center">
                                                                                        {
                                                                                            item.product.asset ? (
                                                                                                <img src={ASSET_URL + '/' + item.product.asset.path} />
                                                                                            ) : (
                                                                                                <i className="far fa-box txt-gray-light" />
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="item-title flex-1 txt-medium">
                                                                                        {item.product.title}
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        {order.converted_subtotal.charAt(0)} {item.product.converted_price} x {item.quantity}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    item.fulfilment_items && item.fulfilment_items.length > 0 && item.fulfilment_items.map(fulfillment => {
                                                                                        return (    
                                                                                            <div className="item-card">
                                                                                                <div className="flex align-center">
                                                                                                    <div className="txt-small txt-warning flex-1">
                                                                                                        Fulfillment comment:
                                                                                                    </div>
                                                                                                    <div className="txt-small txt-gray margin-right-10">
                                                                                                        Number of fulfillments: <span className="badge badge-success">{fulfillment.quantity}</span>
                                                                                                    </div>
                                                                                                    <div className="txt-small txt-gray">
                                                                                                        {moment(fulfillment.created_at).format("MMM DD, YYYY")}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="margin-top-10">
                                                                                                    {fulfillment.note}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="order-card-footer text-right">
                                                            <div className="flex align-center">
                                                                <div className="margin-right-10 flex-1 text-right txt-medium">
                                                                    Payment Method
                                                                </div>
                                                                <div className="total-container txt-warning">
                                                                    {order.payment_type.name}
                                                                </div>
                                                            </div>
                                                            <div className="flex align-center">
                                                                <div className="margin-right-10 flex-1 text-right txt-medium">
                                                                    Subtotal
                                                                </div>
                                                                <div className="total-container">
                                                                    <span>
                                                                        <FormattedTotal
                                                                            formattedPrice={order.converted_subtotal}
                                                                            qty={1}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex align-center">
                                                                <div className="margin-right-10 flex-1 text-right txt-medium">
                                                                    Discount
                                                                </div>
                                                                <div className="total-container">
                                                                    <span className="txt-error">
                                                                        <FormattedTotal
                                                                            formattedPrice={order.converted_discount}
                                                                            qty={1}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex align-center">
                                                                <div className="margin-right-10 flex-1 text-right txt-medium">
                                                                    Shipping Fee
                                                                </div>
                                                                <div className="total-container">
                                                                    <span>
                                                                        <FormattedTotal
                                                                            formattedPrice={order.converted_shipping_fee}
                                                                            qty={1}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="flex align-center margin-top">
                                                                <div className="margin-right-10 flex-1 text-right">
                                                                    TOTAL
                                                                </div>
                                                                <div className="total-container">
                                                                    <span className="txt-success txt-large">
                                                                        <FormattedTotal
                                                                            formattedPrice={order.converted_overall_total}
                                                                            qty={1}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {
                                                                order.overall_status === "processing" && order.payment_type.slug === "cod" && (this.state.voidList.indexOf(order.reference_number) === -1) && (
                                                                    <div className="flex align-center margin-top-20">
                                                                        <div className="margin-right-10 flex-1 text-right" />
                                                                        <div className="total-container">
                                                                            <button
                                                                                className="btn btn-google btn-block btn-block-center submit-btn"
                                                                                onClick={() => this.showAlertAction(order.reference_number)}
                                                                            >
                                                                                Cancel Order
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div
                                                className="margin-top-50 margin-bottom-50 txt-xxlarge flex align-center justify-center"
                                                style={{height: "500px"}}
                                            >
                                                <div className="text-center">
                                                    <img src={noResult} />
                                                    <div className="margin-top-10">
                                                        Make your first order now.
                                                    </div>
                                                    <div className="">
                                                        <button
                                                            className="btn btn-black btn-sqr"
                                                            onClick={() => history.push('/store')}
                                                        >
                                                            SHOP NOW
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                            </div>
                        )
                    }
                </div>
            </div>
       )
    }

    showAlertAction = (orderRefNumber) => {
        const alertMessage = (
            <div className="pad-20">
                <div className="text-center txt-large txt-bold">
                    Confirm Action
                </div>
                <div className="margin-top-10 text-center">
                    Are you sure you want to continue? This action is irreversible.
                </div>
                <div className="flex justify-center margin-top-30">
                    <div className="flex align-center flex-1" style={{maxWidth: "400px"}}>
                        <button
                            className="btn btn-clear black margin-right-10 flex-1"
                            onClick={() => this.showAlertAction()}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-secondary margin-left-10 flex-1"
                            onClick={() => this.handleVoidOrder(orderRefNumber)}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        );

        setModalContent(false, false, alertMessage);
        toggleModal();
    }

    handleVoidOrder = (orderRefNumber) => {
        let voidList = this.state.voidList.slice();
        voidList.push(orderRefNumber);

        this.setState({
            voidList
        }, () => {
            toggleModal();
            voidOrder({reference: orderRefNumber}).then(res => {
                voidList = voidList.filter(list => {
                                return list !== orderRefNumber;
                            });

                this.setState({
                    voidList
                }, () => {
                    window.location.reload();
                });
            });
        });
    }
};


function mapStateToProps(state, ownProps) {
	return {
        user: state.user
	};
}

UserOrders.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(mapStateToProps, null)(UserOrders);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

class Price extends Component {
    constructor(props) {
        super(props);
    }

    handlePriceProcessing = () => {
        const {
            price,
            qty,
            formatted
        } = this.props;
        let currencySymbol = "";
        let newPrice = parseFloat(price);

        if (formatted) {
            currencySymbol = price.charAt(0);
        }

        newPrice *= qty;
        return {
                    value: newPrice,
                    symbol: currencySymbol
                };
    }
    
    render() {
        const price = this.handlePriceProcessing();
        
        return (
            <>  
                <NumberFormat
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    prefix={price.symbol}
                    displayType="text"
                    value={price.value}
                    // className="no-whitespace"
                />
            </>
        );
    }
}

Price.propTypes = {
    price: PropTypes.number,
    qty: PropTypes.number,
    formatted: PropTypes.bool
}


export default Price;
import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
// import { NavLink } from 'react-router-dom';
import {
    getSiteOption,
    subscribeEmail
} from '../../../helpers/caboodleHelper';

class Footer extends Component {
    state = {
        email: "",
        sending: false,
        requestStatus: false,
        requestMessage: "",
        requestMessageShow: false
    };

    componentDidMount() {
        const slug = [
                        "contact-number",
                        "contact-mobile",
                        "contact-email",
                        "facebook-page",
                        "instagram-page",
                        "twitter-page"
                    ];
        getSiteOption({slug});
    }
    
    render () {
        let getToKnowLinks = [
                    {
                        text: "Home",
                        link: "/"
                    }
                ],
                productLinks = [],
                {categories, page} = this.props;
                

        if(categories && categories.list && categories.list.length > 0) {
            categories.list.map(category => {
                productLinks.push({
                    text: category.title,
                    link: `products?categories=${category.slug}`,
                    target: ""
                });
            });
        }

        if (page && page.navbar && page.navbar.contents) {
            page.navbar.contents.map(link => {
                getToKnowLinks.push({
                    text: link.link_text,
                    link: link.link_url,
                    target: link.link_target != "none" ? link.link_target : ""
                });
            });
        }

        return ( 
            <div className="margin-top" className="footer page-body-container">
                <div className="page-container">
                    <div className="flex">
                        {generateLinks("GET TO KNOW US", getToKnowLinks)}
                        {
                            productLinks.length > 0 && (
                                generateLinks("PRODUCTS", productLinks)
                            )
                        }
                        {generateReachUs(this.props.site_options ? this.props.site_options : {})}
                        {generateSubscribeContent(this.props.site_options ? this.props.site_options : {}, this.state.email, this.handleEmailSubmit, this.handleInputChange, this.state.requestMessage, this.state.requestStatus, this.state.requestMessageShow, this.state.sending)}
                    </div>
                </div>
                <div className="copy-right">
                    <div className="flex align-center txt-medium">
                        <div className="flex-1">
                            Copyright © {moment().format("YYYY")} Big Sky Nation. All rights reserved.
                        </div>
                        <div className="flex-1 text-right">
                            <a href="https://sumofy.me/" target="_blank">
                                Made with <i className="far fa-heart pulse-heart infinite-animation" /> by Think Sumo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleInputChange = (e) => {
        this.setState({
            email: e.target.value
        });
    }
    
    handleEmailSubmit = () => {
        if (this.state.email) {
            this.setState({
                sending: true
            }, () => {
                subscribeEmail({email: this.state.email}).then(res => {
                    let requestMessage = "",
                        requestStatus = false;
        
                    if (res.message === "This email address is already subscribed, thank you!") {
                        requestMessage = "This email address is already subscribed!";
                        requestStatus = false;
                    } else {
                        requestMessage = "Thank you subscribing!";
                        requestStatus = true;
                    }
        
                    this.setState({
                        requestStatus,
                        requestMessage,
                        requestMessageShow: true,
                        sending: false,
                        email: ""
                    }, () => {
        
                        setTimeout(() => {
                            this.setState({
                                requestMessageShow: false
                            });
                        }, 3000);
                    });
                });
            });
        }
    }
};

const generateLinks = (title, links) => {
    return (
        <div className="flex-1 txt-large">
            <div className="txt-bold">
                {title}
            </div>
            {
                links.map((link, index) => {
                    return (
                        <div
                            className="margin-top"
                            key={index + parseInt(Math.random() * 10000)}
                        >
                            <a
                                href={link.link}
                                target={link.target}
                                className="footer-link"
                            >
                                {link.text}
                            </a>
                        </div>
                    );
                })
            }
        </div>
    );
};

const generateReachUs = (siteOptions) => {
    const {
        contact_email,
        contact_number,
        contact_mobile
    } = siteOptions;

    return (
        <div className="flex-1 txt-large reach-us-content margin-left-20 margin-right-20">
            <div className="txt-bold">
                REACH US
            </div>
            <div className="flex margin-top-30">
                <div>
                    <i className="fas fa-phone" />
                </div>
                <div className="flex-1 margin-left-10">
                    {
                        contact_number && contact_number.split(",").map(number => {
                            return (
                                <div
                                    key={number}
                                >
                                    {number}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="flex margin-top-30">
                <div>
                    <i className="fas fa-mobile-alt" />
                </div>
                <div className="flex-1 margin-left-10">
                    {
                        contact_mobile && contact_mobile.split(",").map(number => {
                            return (
                                <div
                                    key={number}
                                >
                                    {number}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="flex margin-top-30">
                <div>
                    <i className="fas fa-envelope" />
                </div>
                <div className="flex-1 margin-left-10">
                    {
                        contact_email && contact_email.split(",").map(number => {
                            return (
                                <div
                                    key={number}
                                >
                                    {number}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const generateSubscribeContent = (siteOptions, email, submitEmail, onInputChange, requestMessage, requestStatus, requestMessageShow, sending) => {
    const {
        facebook_page,
        instagram_page,
        twitter_page
    } = siteOptions;

    return (
        <div className="flex-1 txt-large subscribe-content">
            <div className="subscribe-form">
                <div className="input-group">
                    <input
                        name="email"
                        placeholder="Subscribe to our newsletter with your email"
                        className="form-control"
                        value={email}
                        onChange={e => onInputChange(e)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                submitEmail();
                            }
                        }}
                        disabled={sending}
                    />
                    <button
                        className={`send-btn ${email && !sending && "in"}`}
                        onClick={() => submitEmail()}
                    >
                        <i className="far fa-paper-plane" />
                    </button>
                </div>
                <div className={`subscription-message ${requestStatus ? "success" : "error"} ${requestMessageShow && "in"}`}>
                    <div>
                        <div className="message">
                            {requestMessage}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-social-media margin-top-50">
                <div>
                    Get in touch with us
                </div>
                <div>
                    <a
                        className="margin-right-10"
                        href={facebook_page}
                        target="_blank"
                    >
                        <i className="fab fa-facebook-f txt-xlarge scale-hover" />
                    </a>
                    <a
                        className="margin-right-10 scale-hover"
                        href={instagram_page}
                        target="_blank"
                    >
                        <i className="fab fa-instagram txt-xlarge scale-hover" />
                    </a>
                    {/* <a
                        className="margin-right-10 scale-hover"
                        href={twitter_page}
                        target="_blank"
                    >
                        <i className="fab fa-twitter txt-xlarge scale-hover" />
                    </a> */}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state, ownProps) {
	return {
        site_options: state.site_options,
        categories: state.categories,
        page: state.page
	};
}

export default connect(mapStateToProps, null)(Footer);
import React from 'react';

const CheckoutBreadCrumbs = ({
    header,
    delivery_option
}) => {
    return (
        <div className="custom-breadcrumbs">
            {header === "" ?
                <div className="text">
                    My Shopping Cart
                </div>
                :
                <>
                    <div className="text">
                        <span>Checkout</span>
                    </div>
                    <div className="text">
                        {
                            delivery_option === "delivery" ? (
                                <span>Shipping Details</span>
                            ) : (
                                <span>Pickup Locations</span>
                            )
                        }
                    </div>
                    {header === 'Payment Method' &&
                        <div className="text">
                            Payment
                        </div>
                    }
                </>
            }
        </div>
    );
};

export default CheckoutBreadCrumbs;
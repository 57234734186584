import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import AutoSuggest from '../AutoSuggest.js/AutoSuggest';
import CartBag from '../CartBag/CartBag';
import SideBar from './components/SideBar';
// import ModalRegistrationButton from '../ModalRegistrationButton/ModalRegistrationButton';
// import ModalLoginButton from '../ModalLoginButton/ModalLoginButton';
import UserDropDown from '../UserDropDown/UserDropDown';

import {
    getCategoryTree,
    scrollToTop,
    toggleLoginModal,
    getPage
} from '../../../helpers/caboodleHelper';
import History from '../../../History';

import logo from '../../../assets/img/logo.png';
import travelImg from '../../../assets/img/travel.png';

class Nav extends React.PureComponent {
    state = {
        open: false,
        animating: false,
        isMoved: false
    };

    componentDidMount() {
        window.addEventListener('scroll', this.handleWindowScroll);
        getCategoryTree();
        getPage("navbar");
    }

    componentDidUpdate(lastProps) {
        if (lastProps.location.pathname != this.props.location.pathname) {

            if (this.state.open) {
                this.toggle();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    handleWindowScroll = (e) => {
        const nav = document.querySelector(".nav-fixed");
        if (nav) {
            if ((window.scrollY >= nav.clientHeight)) {
                if (!this.state.isMoved) {
                    this.setState({
                        isMoved: true
                    });
                }
            } else {
                if (this.state.isMoved) {
                    this.setState({
                        isMoved: false
                    });
                }
            }
        }
    }

	toggle = () => {
        const {open, animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({
            timer: setTimeout(() => {
                this.setState({
                    animating: false
                });
            }, 500)
        });
        this.setState({
            open: !open,
            animating: true
        });
    }
    
    render() {
        const { open, isMoved } = this.state;
        const { location, items, page } = this.props;

        return (
            <div>
                {
                    (location.pathname.indexOf("checkout") == -1 || (location.pathname.indexOf("checkout") > -1 && location.pathname.indexOf("my-cart") > -1)) && (
                        <div className={`main-navbar ${this.state.isMoved && "moved"}`}>
                            <SideBar 
                                open={open}
                                toggle={this.toggle}
                                user={this.props.user}
                                categories={this.props.categories}
                                links={page && page.navbar && page.navbar.contents}
                            />
                            <div onClick={this.toggle} className={this.navToggle(open)[0]} />
                            <div className="nav-fixed">
                                <div className="nav-disclaimer">
                                    <div className="container text-center txt-xlarge txt-bold">
                                        The #1 Travel Solutions Provider in the Philippines
                                    </div>
                                    <img
                                        src={travelImg}
                                    />
                                </div>
                                <nav className={`navbar navbar-default scrolled`}>
                                    <div className="nav-stickout flex align-center">
                                        <div>
                                            <div
                                                className={`hamburger`}
                                                onClick={this.toggle}
                                            >
                                                <span />
                                                <span />
                                                <span />
                                            </div>
                                        </div>
                                        <div className="flex-1">
                                            <NavLink to="/" className="navbar-brand" href="">
                                                <img src={logo} alt="BigSkyNation"/>
                                            </NavLink>
                                        </div>
                                        <div>
                                            <button
                                                className="btn-icon btn btn-secondary"
                                                onClick={() => scrollToTop()}
                                            >
                                                <i className="far fa-long-arrow-up" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className={`hamburger`} onClick={this.toggle}>
                                            <span />
                                            <span />
                                            <span />
                                        </div>
                                        <div className="navbar-header">
                                            <NavLink to="/" className="navbar-brand" href="">
                                                <img src={logo} alt="BigSkyNation"/>
                                            </NavLink>
                                        </div>
                                        <AutoSuggest 
                                            location={location}
                                        />
                                        <div className="flex flex-end">
                                            {/* <div>
                                                <div className="txt-warning txt-xlarge txt-bold">
                                                    GET FREE SHIPPING
                                                </div>
                                                <div className="txt-warning">
                                                    for orders P2,000 up
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="margin-left-30">
                                            {
                                                this.props.user.hasOwnProperty("name") ? false : (
                                                    <button
                                                        className="btn btn-clear secondary txt-medium btn-pad-large txt-bold margin-right-20 nav--login-btn"
                                                        onClick={() => toggleLoginModal()}
                                                    >
                                                        Login
                                                    </button>
                                                )
                                            }
                                            {
                                                items && items.length > 0 && (
                                                    <button
                                                        className="btn btn-secondary txt-medium btn-pad-large txt-bold margin-right-20 nav--checkout-btn"
                                                        onClick={() => History.push(items.length > 0 ? '/checkout' : '/')}
                                                    >
                                                        Checkout
                                                        <i className="fal fa-arrow-right margin-left-15" />
                                                    </button>
                                                )
                                            }
                                            {
                                                this.props.user.hasOwnProperty("name") ? (
                                                    <div className="margin-right-20 margin-left-20 relative-container">
                                                        <UserDropDown
                                                            alert={this.checkUserInfo()}
                                                        />
                                                        {
                                                            this.checkUserInfo() && (
                                                                <i className="fas fa-exclamation-circle user-indicator txt-xlarge txt-error" />
                                                            )
                                                        }
                                                    </div>
                                                ) : false
                                            }
                                            <CartBag />
                                            {/* <button className="btn btn-nostyles margin-left-20 nav--search-btn">
                                                <i className="far fa-search txt-header-2 txt-warning" />
                                            </button> */}
                                        </div>
                                    </div>
                                </nav>
                                <div className="nav-links">
                                    <div className="container flex align-center">
                                        {
                                            page && page.navbar && page.navbar.contents && page.navbar.contents.map(link => {
                                                return (
                                                    <div className="link txt-small">
                                                        <a
                                                            href={link.link_url}
                                                            target={link.link_target != "none" ? link.link_target : ""}
                                                        >
                                                            {link.link_text}
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/about-us/"
                                                target="_blank"
                                            >
                                                About Big Sky
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/"
                                                target="_blank"
                                            >
                                                Big Sky Home
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/products/"
                                                target="_blank"
                                            >
                                                Shop SIMs and WiFi
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/form/rent-now/skyroam-hotspot-rental"
                                                target="_blank"
                                            >
                                                Rent Travel Wi-Fi
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/buy-travel-wifi/"
                                                target="_blank"
                                            >
                                                Buy Travel Wifi
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/blogs/"
                                                target="_blank"
                                            >
                                                Promos & Blogs
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/about-us/"
                                                target="_blank"
                                            >
                                                Enterprise Solutions
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/connect-with-us/"
                                                target="_blank"
                                            >
                                                Connect with us
                                            </a>
                                        </div>
                                        <div className="link txt-small">
                                            <a
                                                href="https://bigskynation.com/#freq-questions"
                                                target="_blank"
                                            >
                                                Support & FAQs
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="mobile-search-container">
                                <AutoSuggest 
                                    location={location}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    navToggle(open) {
        if (open) {
            return [
                "nav-overlay open",
                "hamburger open",
                "nav navbar-nav open"
            ];
        }
        return [
            "nav-overlay",
            "hamburger",
            "nav navbar-nav"
        ];
    }

    checkUserInfo = () => {
        let alertStatus = false;

        if (this.props.user.hasOwnProperty("name")) {
            const {user} = this.props;

            if (!user.customer.firstname || !user.customer.lastname || !user.customer.phone || !user.customer.gender || !user.customer.birthday) {
                alertStatus = true;
            }
        }

        return alertStatus;
    }
}

function mapStateToProps(state, ownProps) {
	return {
        user: state.user,
        items: state.cart.items,
        categories: state.categories,
        page: state.page
	};
}

Nav.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    items: PropTypes.array
};

export default connect(mapStateToProps, null)(Nav);


import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
            logoutUser,
            toggleRegistrationModal,
            toggleLoginModal,
            extractAssetObj
        } from '../../../../helpers/caboodleHelper';

import signalLogo from '../../../../assets/img/signal-logo.png';

const SideBar = ({
    open,
    toggle,
    user,
    categories,
    links
}) => {
    return (
        <div className={`nav-sidebar-container ${open ? 'open' : ''} flex align-center`}>
            <div className="link-container flex direction-column">
                <div className="action-container flex align-center">
                    <div className="flex-1">
                        <img src={signalLogo} />
                    </div>
                    <div>
                        <i className="far fa-long-arrow-left clickable" onClick={toggle} />
                    </div>
                </div>
                <div  className="menu-list margin-top-50 flex-1">
                    <ul>
                        <li>
                            <NavLink
                                to="/products?sale=1"
                                className="pad-bottom txt-warning txt-bold"
                                text="Sale"
                            >
                                <span>
                                    Sale
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                exact
                                to="/products?best_seller=1"
                                className="pad-bottom txt-bold"
                                text="Best Sellers"
                            >
                                <span>
                                    Best Sellers    
                                </span>
                            </NavLink>
                        </li>
                        {
                            categories && categories.list && categories.list.map(category => {
                                return (
                                    <li key={"category" + category.id}>
                                        <img src={extractAssetObj(category, ["asset", "path"])} className="hidden" />
                                        <NavLink
                                            to={`/products?categories=${category.slug}`}
                                            className="pad-bottom"
                                            text={category.title}
                                        >
                                            <span>
                                                {category.title}
                                            </span>
                                        </NavLink>
                                    </li>
                                );
                            })
                        }
                        {
                            user.hasOwnProperty("name") ? (
                                <li>
                                    <NavLink
                                        className="clickable"
                                        onClick={() => logoutUser()}
                                        text="Logout"
                                    >
                                        <span>
                                            Logout
                                        </span>
                                    </NavLink>
                                </li>
                            ) : (
                                <Fragment>
                                    <li>
                                        <a
                                            className="pad-bottom clickable"
                                            onClick={() => toggleLoginModal()}
                                            text="Login"
                                        >
                                            <span>
                                                Login
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="pad-bottom clickable"
                                            onClick={() => toggleRegistrationModal()}
                                            text="Sign up"
                                        >
                                            <span>
                                                Sign up
                                            </span>
                                        </a>
                                    </li>
                                </Fragment>
                            )
                        }
                    </ul>
                    <div className="mobile-linkouts">
                        <ul>
                            <li>
                                <NavLink
                                    to="/"
                                    exact
                                    className="pad-bottom txt-bold"
                                >
                                    Home
                                </NavLink>
                            </li>
                            {
                                links && links.map(link => {
                                    return (
                                        <li>
                                            <a
                                                className="pad-bottom txt-bold"
                                                href={link.link_url}
                                                target={link.link_target != "none" ? link.link_target : ""}
                                            >
                                                {link.link_text}
                                            </a>
                                        </li>
                                    )
                                })
                            }
                            {/* <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/"
                                    target="_blank"
                                >
                                    bigskynation.com
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/products/"
                                    target="_blank"
                                >
                                    Big Sky Products
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/travel-gadgets/"
                                    target="_blank"
                                >
                                    Rent Gadgets
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/form/rent-now/skyroam-hotspot-rental"
                                    target="_blank"
                                >
                                    Rent Travel Wi-Fi
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/buy-travel-wifi/"
                                    target="_blank"
                                >
                                    Buy Travel Wi-Fi
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/blogs/"
                                    target="_blank"
                                >
                                    Promos & Blogs
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/connect-with-us/"
                                    target="_blank"
                                >
                                    Connect with us
                                </a>
                            </li>
                            <li>
                                <a
                                    className="pad-bottom txt-bold"
                                    href="https://bigskynation.com/#freq-questions"
                                    target="_blank"
                                >
                                    FAQs
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <ul className="quick-links margin-top-100 pad-top-30">
                    <li>
                        <a
                            href="https://bigskynation.com/form/privacy-policy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://bigskynation.com/form/privacy-policy"
                            target="_blank"
                        >
                            Terms and Conditions
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://bigskynation.com/#freq-questions"
                            target="_blank"
                        >
                            FAQs
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

SideBar.propTypes = {
    open: PropTypes.bool,
    toggle: PropTypes.func
};

export default SideBar;
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
            removeWishlist,
            updateCustomerDetails
        } from '../../actions/customer.actions';
import {
        loadCustomerWishlist
    } from '../../actions/products.actions';
import {
        makeASquare
    } from '../../helpers/caboodleHelper';
import * as productActions from '../../actions/products.actions';
import noResult from '../../assets/img/no_result.png';
import ProductCard from '../_global/ProductCard/ProductCard';

class UserWishlists extends React.PureComponent {

    state = {
        userInfoForm: {},
        removing: []
    }

    componentWillMount() {
        window.removeEventListener("resize", this.initMakeASquare);
    }

    componentDidMount() {
        window.addEventListener('resize', this.setContainerWidth);
        if (this.props.user.hasOwnProperty("name")) {
            this.props.actions.loadCustomerWishlist({customer_id: this.props.user.customer.id});
        }
    }

    componentDidUpdate() {
        this.initMakeASquare();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setContainerWidth);
        this.props.actions.unSelectProduct();
    }
    
    initMakeASquare = () => {
        setTimeout(() => {
            makeASquare(document.getElementsByClassName(`product-container-image`));
        });
    }

    handleRemoveWishlist = (id) => {
        let removing = this.state.removing.splice();

        removing.push(id);
        this.setState({
            removing
        }, () => {
            removeWishlist(this.createWishlistData(id)).then(res => {
                let user = Object.assign({}, this.props.user);
                user.customer.wishlists = res.wishlists;
                updateCustomerDetails(user);

                removing = removing.splice(id, 1);
                this.setState({
                    removing
                });
            });
        });        
    }

    render() {
        const {wishlisted} = this.props.user.hasOwnProperty("name") ? this.props.user : {wishlisted: {list: [], loading: false}};
        const {loading} = this.props;
        
        return (
            <div className="user-info">
                {
                    loading && wishlisted && wishlisted.list.length === 0 ? (
                        <div className="grid grid-3 grid-xs-1 wishlist-container">
                            {
                                [...Array(6)].map((v, i) => {
                                    return(
                                        <ProductCard key={i} loading />
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div>
                            {
                                wishlisted && wishlisted.list.length > 0 ? (
                                    <div>
                                        <div className="txt-header margin-bottom-50">
                                            Wishlists
                                        </div>
                                        <div className="grid grid-3 grid-xs-1 wishlist-container">
                                            {
                                                wishlisted.list.map((wishlist, index) => {
                                                    return(
                                                        <div
                                                            key={index}
                                                            className="float-in-up-down"
                                                            style={{animationDelay: `${index * 0.15}s`}}
                                                        >
                                                            <ProductCard product={wishlist.product}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="margin-top-50 margin-bottom-50 txt-xxlarge flex align-center justify-center"
                                        style={{height: "500px"}}
                                    >
                                        <div className="text-center">
                                            <img src={noResult} />
                                            <div className="margin-top-10">
                                                You currently have no wishlist item.
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
       )
    }

    createWishlistData = (wishlistId) => {
        const data = {
            customer_id: this.props.user.customer.id,
            id: wishlistId
        };

        return data;
    }
};


function mapStateToProps(state, ownProps) {
	return {
        user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(productActions, dispatch)
    };
}

UserWishlists.propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(UserWishlists);
import React from 'react';
import { withRouter, Router, Switch } from 'react-router-dom';
import Home from './modules/Home/Home';
import ProductView from './modules/ProductView/ProductView';
import About from './modules/About/About';
import Terms from './modules/Terms/Terms';
import Blogs from './modules/Blogs/Blogs';
import BlogView from './modules/Blogs/BlogView';
import CollectionView from './modules/CollectionView/CollectionView';
import ContactUs from './modules/ContactUs/ContactUs';
import Account from './modules/Account/Account';
import Products from './modules/Products/Products';
import Checkout from './modules/Checkout/Checkout';
import OrderStatus from './modules/OrderStatus/OrderStatus';
import Layout from './modules/Layout';
// import ComingSoon from './modules/_global/Extras/ComingSoon';
import history from './History';

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Layout exact path="/"  component={Home} />
            <Layout path="/products"  component={Products} />
            <Layout path="/about"  component={About} />
            <Layout path="/terms-and-conditions"  component={Terms} />
            <Layout path="/privacy-policy"  component={Terms} />
            <Layout path="/contact"  component={ContactUs} />
            <Layout path="/collection/:slug"  component={CollectionView} />
            <Layout exact path="/blogs"  component={Blogs} />
            <Layout exact path="/blogs/:slug/view"  component={BlogView} />
            <Layout path="/product/:slug/view"  component={ProductView} />
            <Layout path="/checkout/:slug?"  component={Checkout} />
            <Layout exact path="/manage-account/:section"  component={Account} />
            <Layout path="/order/:status?"  component={OrderStatus} />
            {/* <ComingSoon path="/coming-soon" /> */}
        </Switch>
    </Router>
);
export default withRouter(Routes);


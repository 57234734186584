/*eslint-disable import/default*/

import React from 'react';
import { render } from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { PersistGate } from 'redux-persist/lib/integration/react';
import * as serviceWorker from './serviceWorker';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "placeholder-loading/src/scss/placeholder-loading.scss";
import "animate.css/animate.min.css";
import './assets/css/fontawesome-all.css';
import './assets/scss/app.scss';

const store = configureStore().store;
const persistor = configureStore().persistor;

render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
				<Routes />
            </BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById('root') 
);

serviceWorker.unregister();


export { store };

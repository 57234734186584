import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
        getPage,
        extractAssetObj
    } from '../../helpers/caboodleHelper';

class Terms extends React.PureComponent {

    state = {};

    componentDidMount() {
        getPage('terms-and-conditions');
        getPage('privacy');
    }

    render() {
        const {page} = this.props;
        const activeSlug = this.props.location.pathname.indexOf("terms-and-conditions") > -1 ? "terms" : "privacy";
        let terms = false,
            privacy = false,
            activePage = false;

        if (page && page.hasOwnProperty("terms-and-conditions")) {
            terms = Object.assign({}, page["terms-and-conditions"]);
        }

        if (page && page.hasOwnProperty("privacy")) {
            privacy = Object.assign({}, page.privacy);
        }

        if (activeSlug === "terms" && terms) {
            activePage = terms;
        } else if (activeSlug === "privacy" && privacy) {
            activePage = privacy;
        }

        return (
            <div className="t-and-c page-body-container">
                {
                    (page.loading && !terms && !privacy) ? (
                        <div>
                            <div className="page-banner-image ph-item" />
                            <div className="pad-top-50">
                                <div className="page-container ph-item">
                                    <div className="ph-col-4">
                                        <div className="ph-row">
                                            <div className="ph-col-8 big" />
                                            <div className="ph-col-8 big" />
                                        </div>
                                    </div>
                                    <div className="ph-col-8">
                                        <div className="ph-row">
                                            {
                                                [...Array(15)].map((arr, i) => {
                                                    return(
                                                        // eslint-disable-next-line
                                                        <div key={i} className={`ph-col-${i%2 == 0 ? '12' : (parseInt(Math.random() * (10 - 1) + 1)%2 == 0 ? '10' : '8')}`} />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="pad-bottom-100">
                            {
                                activePage.content && terms && privacy && (
                                    <div>
                                        <div
                                            className="page-banner-image"
                                            style={{backgroundImage: `url(${extractAssetObj(activePage.content, ["image", "path"])})`}}
                                        />
                                        <div className="page-container pad-top-50">
                                            <div className="flex flex-rm-md">
                                                <div className="pad-right-100 tc-page-links">
                                                    {
                                                        terms && (
                                                            <div className={`txt-xlarge txt-uppercase txt-bold`}>
                                                                <Link
                                                                    to="/terms-and-conditions"
                                                                    className={`pad-bottom ${activeSlug != "terms" && "txt-gray-med underline-hover uh-black "}`}
                                                                >
                                                                    {terms.content.title}
                                                                </Link>
                                                            </div>
                                                        )
                                                    }

{
                                                    privacy && (
                                                            <div className={`txt-xlarge txt-uppercase txt-bold margin-top-30`}>
                                                                <Link
                                                                    to="/privacy-policy"
                                                                    className={`pad-bottom ${activeSlug != "privacy" && "txt-gray-med underline-hover uh-black"}`}
                                                                >
                                                                    {privacy.content.title}
                                                                </Link>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-1 pad-left-100" dangerouslySetInnerHTML={{ __html: activePage.content.content }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
       )
    }
};


function mapStateToProps(state, ownProps) {
	return {
        page: state.page
	};
}

Terms.propTypes = {
    page: PropTypes.object
};

export default connect(mapStateToProps, null)(Terms);
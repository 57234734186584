import React from 'react';
import PropTypes from 'prop-types';
import { extractAssetObj } from '../../../helpers/caboodleHelper';
import YouTube from 'react-youtube';

class Banner extends React.PureComponent {
    state = {
        activeSlide: {},
        lastActiveSlide: {},
        activeSlideDimensions: {width: 0, height: 0},
        activeSlideIndex: 0,
        lastActiveSlideIndex: 0,
        controlsDisabled: false,
        slideMovement: "",
        autoPlayInterval: false,
        autoPlayDelay: 8000
    }
    sliderTimeout;
    resizeTimeout;

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/player_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    componentWillMount() {
        this.initializeSlider();
    }

    componentDidUpdate() {
        this.initializeSlider();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    initializeSlider = () => {
        if (Object.keys(this.state.activeSlide).length == 0 && this.props.banners) {
            this.setState({
                activeSlide: this.props.banners[0],
                lastActiveSlide: this.props.banners[0]
            }, () => {
                if (this.props.banners.length > 1) {
                    this.handleWindowResize();
                    this.setState({
                        autoPlayInterval: this.state.activeSlide.video_embed ? false : setInterval(() => {
                                                this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                            }, this.state.autoPlayDelay)
                    });
                }
            });
        }
    }

    onYouTubePlayerAPIReady = () => {
        const player = new window.YT.Player('player', {
          height: '390',
          width: '640',
          videoId: 'u1zgFlCw8Aw',
          events: {
            'onReady': () => {console.log(123)},
            'onStateChange': () => {console.log(123)}
          }
        });
      }

    handleSetActiveSlide = (index, movement) => {
        const activeSlide = Object.assign({}, this.state.activeSlide);
        clearInterval(this.state.autoPlayInterval);
        this.setState({
            activeSlide: this.props.banners[index],
            lastActiveSlide: activeSlide,
            lastActiveSlideIndex: this.state.activeSlideIndex,
            activeSlideIndex: index,
            controlsDisabled: true,
            slideMovement: movement ? movement : (index > this.state.activeSlideIndex ? "next" : "prev")
        }, () => {
            this.sliderTimeout = setTimeout(() => {
                this.setState({
                    controlsDisabled: false,
                    slideMovement: "",
                    autoPlayInterval: this.state.activeSlide.video_embed ? false : setInterval(() => {
                                            this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next")
                                        }, this.state.autoPlayDelay)
                });
            }, 1500);
        });
    }

    handleWindowResize = () => {
        if (this.props.banners && this.props.banners.length > 0) {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }
    
            this.resizeTimeout = setTimeout(() => {
                const activeImage = document.querySelector(".active-image");
                this.setState({
                    activeSlideDimensions: {
                        width: activeImage.clientWidth,
                        height: activeImage.clientHeight
                    }
                });
            }, 500);
        }
    }

    render() {
        const {
                banners,
                loading
            } = this.props;
            
        const {
                activeSlide,
                lastActiveSlide,
                activeSlideDimensions
            } = this.state;

        if (loading || !banners) {
            return (
                <div className="page-container-full-xs banner-slider banner-js">
                    <div className="main-banner">
                        <div className="active-banner ph-item">
                            <div className="active-image" />
                        </div>
                    </div>
                </div>
            );
        }
        
        return (
            <div className="page-container-full-xs">
                {
                    Object.keys(activeSlide).length > 0 && (
                        <div className="banner-js banner-slider">
                            <div className="main-banner">
                                <div className={`active-banner ${this.state.slideMovement !== '' && 'in-transition'}  fade-in`}>
                                    <div className="active-image">
                                        <div className="flex align-center">
                                            <div className="text-details flex-1 flex justify-center pad-left-50 pad-right-50">
                                                <div>
                                                    <h1 className="txt-oversized">
                                                        {activeSlide.title}
                                                    </h1>
                                                    <h2
                                                        className="txt-medium txt-regular"
                                                        dangerouslySetInnerHTML={{__html: activeSlide.content}}
                                                    />
                                                    <a
                                                        className="btn btn-white btn-sqr txt-bold txt-large btn-pad-xlarge margin-top-30"
                                                        href={activeSlide.button_link ? activeSlide.button_link : ''}
                                                    >
                                                        {activeSlide.button_text}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex-1 image-details">
                                                {
                                                    activeSlide.video_embed ? (
                                                        <div
                                                            className="embed_video"
                                                        >
                                                            <YouTube
                                                                videoId={activeSlide.video_embed}
                                                                containerClassName="iframe-container"
                                                                opts={{
                                                                    height: '390',
                                                                    width: '640',
                                                                    playerVars: {
                                                                        autoPlay: 1,
                                                                        modestbranding: 1,
                                                                    }
                                                                }}
                                                                onReady={(e) => {
                                                                    // setTimeout(() => {
                                                                    //     this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next");
                                                                    // }, this.state.autoPlayDelay);
                                                                    e.target.playVideo();
                                                                }}
                                                                onPlay={() => {clearInterval(this.state.autoPlayInterval);}}
                                                                onPause={() => this.onVideoStop()}
                                                                onEnd={() => this.onVideoStop()}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img
                                                            src={extractAssetObj(activeSlide, ['image', 'path'])}
                                                            alt={activeSlide.title}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`transition-overlay ${this.state.slideMovement}`}>
                                        <div
                                            className="flex align-center"
                                            style={{width: activeSlideDimensions.width, height: activeSlideDimensions.height}}
                                        >
                                            <div className="text-details flex-1 flex justify-center pad-left-50 pad-right-50">
                                                <div>
                                                    <h1 className="txt-oversized">
                                                        {lastActiveSlide.title}
                                                    </h1>
                                                    <h2
                                                        className="txt-medium txt-regular"
                                                        dangerouslySetInnerHTML={{__html: lastActiveSlide.content}}
                                                    />
                                                    <button
                                                        className="btn btn-white btn-sqr txt-bold txt-large btn-pad-xlarge margin-top-30"
                                                    >
                                                        {lastActiveSlide.button_text}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex-1 image-details">
                                                {
                                                    lastActiveSlide.video_embed ? (
                                                        <div
                                                            className="embed_video"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={extractAssetObj(lastActiveSlide, ['image', 'path'])}
                                                            alt={lastActiveSlide.title}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`banner-thumbnails flex align-center justify-center ${this.state.slideMovement}`}>
                                {
                                    banners && banners.map((banner, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`thumb clickable ${this.state.activeSlideIndex === index ? 'active' : ''} ${this.state.lastActiveSlideIndex === index ? 'active-out' : ''}`}
                                                onClick={() => this.handleSetActiveSlide(index, this.state.activeSlideIndex > index ? 'prev' : 'next')}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                }
                
            </div>
        );
    }

    onVideoStop = () => {
        if (this.sliderTimeout) {
            clearTimeout(this.sliderTimeout);
        }
        
        this.sliderTimeout = setTimeout(() => {
            this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next");
        }, 1500);
    }
}



Banner.propTypes = {
    banners: PropTypes.array,
    loading: PropTypes.bool
};

export default Banner;


import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from './_global/Modal/Modal';
import Nav from './_global/Nav/Nav';
import Footer from './_global/Footer/Footer';
import { toggleModal } from '../helpers/caboodleHelper';

class Layout extends React.PureComponent {

    componentDidUpdate(old) {
		if (old.computedMatch.url !== this.props.computedMatch.url) {
			window.scrollTo(0, 0);
		}
	}

    render() {
		const Component = this.props.component;
		const { footer, modal } = this.props;
		
        return (
			<Route {...this.props.rest} render={matchProps => (
				<Fragment>
					<Nav {...matchProps}  />
					<div id="outer">
						<Component {...matchProps} />
						<Modal
							zIndex={999999}
							isOpen={modal.open}
							animateIn={modal.animateIn}
							animateOut={modal.animateOut}
							toggle={() => toggleModal()}
						>
							{modal.content}
						</Modal>
						<Footer 
							contact={footer && footer.contact}
							partners={footer && footer.partners && footer.partners.items}
						/>
					</div>
				</Fragment>
			)} />
        );
    }
}

Layout.propTypes = {
	rest: PropTypes.object,
	component: PropTypes.func,
	computedMatch: PropTypes.object,
	footer: PropTypes.object,
	modal: PropTypes.object
};

function mapStateToProps(state, ownProps) {
	return {
		footer: state.footer,
		modal: state.modal
	};
}

export default connect(mapStateToProps, null)(Layout);

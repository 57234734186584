import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    sendProductReview
} from '../../../actions/products.actions';

class ProductReviewForm extends React.PureComponent {
    state = {
        content: "",
        stars: 5,
        tempStars: 5,
        sendingReview: false
    }

    componentWillMount() {
    }

    componentDidMount () {
    }

    render() {
        const {stars, tempStars} = this.state;

        return (
            <div className="review-form pad-20 margin-bottom-20">
                <textarea
                    placeholder="Add your review here..."
                    onChange={e => this.setState({content: e.target.value})}
                    value={this.state.content}
                />
                <div className="flex align-center">
                    {
                        [...Array(5)].map((star, index) => {
                            return(
                                <div
                                    className="star-group cliackable"
                                    key={index}
                                >
                                    <i
                                        className="far fa-star txt-xxlarge txt-warning"
                                        
                                    />
                                    <div className="left-part">
                                        <i
                                            className={`${(tempStars > (index-1)) && (tempStars >= (index+0.5)) ? 'fas' : 'far'} fa-star txt-xxlarge txt-warning`}
                                            onMouseEnter={(e) => this.handleStarValueChange(index+0.5)}
                                            onMouseLeave={() => this.handleStarValueChange(stars)}
                                            onClick={() => this.handleStarValueChange(tempStars, false)}
                                        />
                                    </div>
                                    <div className="right-part">
                                        <i
                                            className={`${(tempStars > (index-1)) && (tempStars >= (index+1)) ? 'fas' : 'far'} fa-star txt-xxlarge txt-warning`}
                                            onMouseEnter={(e) => this.handleStarValueChange(index+1)}
                                            onMouseLeave={() => this.handleStarValueChange(stars)}
                                            onClick={() => this.handleStarValueChange(tempStars, false)}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className="flex flex-end">
                    <button
                        className={`btn ${this.state.sendingReview || !this.state.content ? "btn-gray no-pointer" : "btn-secondary"}`}
                        onClick={() => this.handleSubmitReview()}
                        disabled={this.state.sendingReview || !this.state.content}
                    >
                        SEND
                    </button>
                </div>
            </div>
        );
    }

    handleStarValueChange = (value, temp = true) => {
        if (temp) {
            this.setState({
                tempStars: value
            });
        } else {
            this.setState({
                tempStars: value,
                stars: value
            });
        }
    }

    handleSubmitReview = () => {
        this.setState({
            sendingReview: true
        }, () => {
            const params = {
                product_id: this.props.products.selected.id,
                user_id: this.props.user.hasOwnProperty("name") ? this.props.user.id : 0,
                content: this.state.content,
                rating: this.state.stars,
                status: "publish"
            }
    
            this.props.sendProductReview(params).then(res => {
                this.setState({
                    sendingReview: false,
                    content: "",
                    stars: 5,
                    tempStars: 5
                });
            });
        });
    }
}


function mapStateToProps(state, ownProps) {
	return {
        products: state.products,
        user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		sendProductReview: bindActionCreators(sendProductReview, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewForm);
import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { extractAssetObj, addToCart, removeToCart } from '../../../helpers/caboodleHelper';
import Button from '../Form/Button';
import FormattedTotal from '../Elements/FormattedTotal';
import history from '../../../History';

class MyCartPage extends React.PureComponent {

    handleQtyInputChange = (e, product) => {
        let qty = e.target.value;
        
        if (isNaN(qty)) {
            qty = parseInt(qty);
        }

        if (qty < 1 || qty === '') {
            qty = 1;
        }

        addToCart(product, qty, false, true);
    }
    
    render() {
        const { cart, loading } = this.props;
        if (cart && cart.items && cart.items.length <= 0) {
            return (
                <h1>
                    Your Cart is Empty
                </h1>
            );
        }
        return (
            <div className="my-cart-page-container">
                <div className="custom-table">
                    <div className="table-header sku">
                        SKU
                    </div>
                    <div className="table-header image">
                        Image
                    </div>
                    <div className="table-header description">
                        Details
                    </div>
                    <div className="table-header item-price text-right">
                        Price
                    </div>
                    <div className="table-header quantity text-center">
                        Quantity
                    </div>
                    <div className="table-header total text-right">
                        Total
                    </div>
                    {cart && cart.items.map((item,key) => (
                        <Fragment key={key}>
                            <div className="sku">
                                <NavLink
                                    to={`/product/${item.product.slug}/view`}
                                >
                                    {item.product.sku}
                                </NavLink>
                            </div>
                            <div className="image">
                                <NavLink
                                    to={`/product/${item.product.slug}/view`}
                                >
                                    <img alt={item.product.title} src={`${extractAssetObj(item, ['product', 'image_paths', 'path'])}`} className="product-image" />
                                </NavLink>
                            </div>
                            <div className="description">
                                <h1 className="txt-bold txt-medium">
                                    <NavLink
                                        to={`/product/${item.product.slug}/view`}
                                    >
                                        {item.product.title}
                                    </NavLink>
                                </h1>
                                <div className="txt-small product-description" dangerouslySetInnerHTML={{__html: item.product.description}} />
                                <div className="mobile-controls flex align-center">
                                    <div className="quantity flex flex-1">
                                        <div>
                                            <div className="edit-qty-container">
                                                <button
                                                    className="btn btn-lgray"
                                                    onClick={() => {
                                                        if (item.quantity <= 1) {
                                                            return removeToCart(item.product);
                                                        }
                                                        return addToCart(item.product, -1, false);
                                                    }}
                                                >
                                                    {item.quantity <= 1 ?
                                                        <i className="fal fa-trash" />
                                                        :
                                                        <i className="fal fa-minus" />
                                                    }
                                                </button>
                                                <input
                                                    value={item.quantity}
                                                    min="1"
                                                    onChange={e => this.handleQtyInputChange(e, item.product)}
                                                />
                                                <button
                                                    className="btn btn-black"
                                                    onClick={() => addToCart(item.product, 1, false)}
                                                >
                                                    <i className="fal fa-plus" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total no-whitespace txt-bold text-right">
                                        <FormattedTotal 
                                            formattedPrice={item.product.formatted_price}
                                            qty={item.quantity}
                                        />                     
                                    </div>
                                </div>
                            </div>
                            <div className="item-price no-whitespace text-right">
                                <FormattedTotal 
                                    formattedPrice={item.product.formatted_price}
                                    qty={1}
                                /> 
                            </div>
                            <div className="quantity flex justify-center desktop-controls">
                                <div>
                                    <div className="edit-qty-container">
                                        <button
                                            className="btn btn-lgray"
                                            onClick={() => {
                                                if (item.quantity <= 1) {
                                                    return removeToCart(item.product);
                                                }
                                                return addToCart(item.product, -1, false);
                                            }}
                                        >
                                            {item.quantity <= 1 ?
                                                <i className="fal fa-trash" />
                                                :
                                                <i className="fal fa-minus" />
                                            }
                                        </button>
                                        <input
                                            value={item.quantity}
                                            min="1"
                                            onChange={e => this.handleQtyInputChange(e, item.product)}
                                        />
                                        <button
                                            className="btn btn-black"
                                            onClick={() => addToCart(item.product, 1, false)}
                                        >
                                            <i className="fal fa-plus" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="total no-whitespace txt-bold text-right desktop-controls">
                                <FormattedTotal 
                                    formattedPrice={item.product.formatted_price}
                                    qty={item.quantity}
                                />                     
                            </div>
                        </Fragment>
                    ))}
                </div>
				<div className="flex align-center margin-top-30 button-container">
                    <div className="flex-1">
                        <button
                            className="btn btn-clear black btn-pad-large btn-sqr"
                            onClick={() => history.push('/products?sale=1')}
                        >
                            Continue Shopping
                        </button>
                    </div>
					<button
						className="btn btn-black btn-pad-large btn-sqr"
						onClick={() => this.props.btnOnClickSummary()}
					>
						<span className="margin-right-20">
							PROCEED TO CHECKOUT
						</span>
						<i className="far fa-chevron-right" />
					</button>
				</div>
                {
                    loading ? (
                        <div className="screen-overlay flex align-center justify-center">
                            <div>
                                <div>
                                    <div className="circle-loader margin-auto">
                                        <div/>
                                        <div/>
                                        <div/>
                                    </div>
                                </div>
                                <div
                                    className="text-center margin-top-20 fade-in-down txt-white"
                                    style={{animationDelay: "5s"}}
                                >
                                    Please wait a moment. This may take a while.
                                </div>
                            </div>
                        </div>
                    ) : false
                }
            </div>
       );
    }
}


function mapStateToProps(state, ownProps) {
	return {
        cart: state.cart
	};
}

export default connect(mapStateToProps, null)(MyCartPage);
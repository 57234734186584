import React from 'react';
import { NavLink } from 'react-router-dom';
import ProductCard from '../ProductCard/ProductCard';

const ProductsMoreContainer = ({
    products = [],
    limit = 0,
    gridCol = 3,
    header = "",
    headerTextAlign = "center",
    moreBtnText = "",
    moreBtnLink = "",
    cardGap=20,
    id = ""
}) => {
    let limitedProducts = products.slice(0);
    limitedProducts = limitedProducts.slice(0, limit === 0 ? products.length : limit);

    return (
        <div className="more-products-container">
            {
                header ? (
                    <div className={`txt-header txt-bold text-${headerTextAlign}`}>
                        {header}
                    </div>
                ) : false
            }
            <div
                className={`grid grid-${gridCol}-auto grid-gap-${cardGap} margin-top-50 justify-center`}
            >
                {
                    limitedProducts.length > 0 && limitedProducts.map((product, index) => {
                        return (
                            <div
                                className="fade-in product-card-holder"
                                style={{
                                    animationDelay: `${index * 0.3}s`
                                }}
                                key={id + index}
                            >
                                <ProductCard product={product} />
                            </div>
                        );
                    })
                }
            </div>
            <div className="flex flex-end txt-xlarge margin-top-50 txt-link txt-bold">
                <NavLink
                    to={moreBtnLink ? moreBtnLink : "/"}
                    className="flex align-center"
                >
                    {moreBtnText ? moreBtnText : "GO TO"}
                    <i className="far fa-angle-right margin-left-30 txt-xlarge" />
                </NavLink>
            </div>
        </div>
    );
};

export default ProductsMoreContainer;
import axios from 'axios';
import toastr from 'toastr';
import * as config from '../constants/config';
import * as sessionHelper from './sessionHelper';


export function connect(showLog = true){
	let instance;
	toastr.options = {
		"positionClass": "toast-bottom-right",
		"preventDuplicates": true
	};
	
	if (sessionHelper.getUser()) {
		instance = axios.create({
			baseURL: config.API_URL,
			headers: {
						'Authorization': 'Bearer '+ ((sessionHelper.getUser().token) ? sessionHelper.getUser().token : ''),
						"stkn": config.STORE_KEY
					}
		});
	} else {
		instance = axios.create({
			headers: {
				"stkn": config.STORE_KEY
			},
			baseURL: config.API_URL
		});
	}

	instance.interceptors.response.use(function (response) {
		// Do something with response data
		if (response.data.message && showLog) {
			toastr.success(response.data.message);
		}
		//only return the data
		return response.data;
	}, function (error) {
		// Do something with response error
		if(error && error.response && error.response.data && error.response.data.message !== Array && error.response.data.message === "Token is Expired"){
			sessionHelper.logout();
			sessionHelper.removeCart();
			toastr.error.response("Token is expired! You have been logout");

			setInterval( function(){
				window.location.reload();
			},1000);
		}
		if (error && error.response && error.response.data && error.response.data.message === Array && showLog) {
			error.response.data.message.map((message, index) => (
				toastr.error(message)
			));
		} else if(showLog) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toastr.error(error.response.data.message);
			}
		}
		
		return Promise.reject(error.response);
	});
	return instance;
}

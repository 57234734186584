import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';


const Btn = ({ icon, ...props }) => {
    const loading = props.loading;
    delete props.loading;
    if (loading) {
        props.className = `${props.className} loading`;
    }
    return ( 
        <Button {...props}>
            {loading ?
                <span className="fal fa-spinner fa-spin" />
            :
                <>
                    {props.children}
                    {icon &&
                        <div className="icon-container">
                            {icon}
                        </div>
                    }
                </>
            }

        </Button>
    );
};

Btn.propTypes = {
    icon: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ])
};

export default Btn;
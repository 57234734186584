import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {toggleCollapse} from '../../../helpers/caboodleHelper';
import OutsideClickDetector from '../../_global/Elements/OutsideClickDetector';

class ProductFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: {},
            selectedTab: 0,
            selectedSubCat: 0,
            loaded: false,
            collapseCategory: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleOnWindowScroll);
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillMount() {
        window.removeEventListener('scroll', this.handleOnWindowScroll);
        window.removeEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(oldProps) {
        if (this.props.categories && this.props.categories.length > 0 && this.props.selectedCategory && !this.state.loaded) {
            let category = false;
            if (this.props.selectedCategory) {
                category = Object.assign({}, this.props.selectedCategory);
            }
            
            this.handleCollapseSubCategory(!category ? 0 : (category.parent != 0 ? category.parent : category.id), !category ? 0 : (category.parent != 0 ? category.id : 0));
        } else {
            if (this.props.categories && this.props.categories.length > 0 && this.props.selectedCategory) {
                if ((oldProps.selectedCategory && (oldProps.selectedCategory.slug !== this.props.selectedCategory.slug)) || oldProps.selectedCategory === null) {
                    this.handleSelectCategory(this.props.selectedCategory, false);
                }
            }
        }
    }

    handleWindowResize = () => {
        // if (window.outerWidth <= 767) {
        //     this.smController = this.smController.destroy(true);
        //     this.smController = null;
        // } else {
        //     this.scrollMagicInit();
        // }
    }

    handleOnWindowScroll = () => {
        const windowScroll = window.pageYOffset;
        const staticToggle = document.querySelector(".static-category-toggle");
        
        if (staticToggle) {
            let xPos = "100%";

            if (windowScroll > staticToggle.getBoundingClientRect().top) {
                xPos = "-20px"
            }

            document.querySelector(".sticky-category-toggle").style.transform = `translateX(${xPos})`;
        }
    }

    handleCollapseSubCategory = (id, subCatId) => {
        let targetCollapse = document.querySelector(`.subcat-collapse-${id}`),
            lastTarget = document.querySelector(`.subcat-collapse-${this.state.selectedTab}`);
            
        if (id != this.state.selectedTab) {
            if (targetCollapse) {
                toggleCollapse(false, `.subcat-collapse-${id}`);
            }
    
            if (lastTarget) {
                toggleCollapse(false, `.subcat-collapse-${this.state.selectedTab}`);
            }
        }

        this.setState({
            selectedTab: id,
            selectedSubCat: subCatId,
            loaded: true
        });
    }

    handleSelectCategory = (category, sale, bestSellers) => {
        this.handleCollapseSubCategory(category === 0 ? 0 : (category.parent != 0 ? category.parent : category.id), category === 0 ? 0 : (category.parent != 0 ? category.id : 0));

        if (this.props.onClickCategory) {
            this.props.onClickCategory((category === 0 ? "" : category), false, sale, bestSellers);
        }
    }

    handleCategoryToggle = (state) => {
        if (state === undefined) {
            state = !this.state.collapseCategory;
        }

        this.setState({
            collapseCategory: state
        });
    }

    render() {
        const {categories, loading} = this.props;

        if (loading) {
            return this.skeletonLoading();
        }

        return (
            <div id="categoryListOuterContainer" className="product-filter-category-list">
                <div id="toStick">
                    <OutsideClickDetector
                        onClickOutside={this.handleCategoryToggle}
                        handleParams={false}
                    >
                        <div className={`category-nav-list ${this.state.collapseCategory ? 'in' : ''}`}>
                            {/* <div className="mobile-category-toggle">
                                <div className="flex align-center margin-bottom-30">
                                    <div className="txt-large txt-bold-black flex-1">
                                        Categories
                                    </div>
                                    <div
                                        onClick={() => this.handleCategoryToggle()}
                                    >
                                        <i className="far fa-times txt-large" />
                                    </div>
                                </div>
                            </div> */}
                            <div 
                                    onClick={() => {
                                        this.handleSelectCategory(0, true, false);
                                    }}
                                    className="category-container"
                                >
                                    <div
                                        className={`category-title txt-bold clickable ${this.props.queryParams.sale && "txt-warning"}`}
                                    >
                                        Sale
                                    </div>
                            </div>
                            <div 
                                    onClick={() => {
                                        this.handleSelectCategory(0, false, true);
                                    }}
                                    className="category-container"
                                >
                                    <div className={`category-title txt-bold clickable ${this.props.queryParams.best_seller && "txt-warning"}`}>
                                        Best Sellers
                                    </div>
                            </div>
                            {
                                categories && categories.length > 0 && categories.map((cat, key) => {
                                    return (
                                        <Fragment
                                            key={key}
                                        >
                                            <div className="category-container">
                                                <div
                                                    className={`category-title txt-bold ${this.state.selectedTab === cat.id && "txt-warning"}`}
                                                    onClick={() => {
                                                        this.handleSelectCategory(cat);
                                                    }}
                                                >
                                                    {cat && cat.title}
                                                </div>
                                                {
                                                    cat.children.length > 0 && (
                                                        <div className={`sub-category-options subcat-collapse-${cat.id}`}>
                                                            <div>
                                                                {/* <div className={`sub-option txt-small ${this.state.selectedSubCat === 0 && this.state.selectedTab === cat.id && "txt-warning"}`}>
                                                                    <span>
                                                                        All
                                                                    </span>
                                                                </div> */}
                                                                {
                                                                    cat.children.map(subcat => {
                                                                        return(
                                                                            <div
                                                                                className={`sub-option txt-small ${this.state.selectedSubCat === subcat.id && "txt-warning"}`}
                                                                                key={subcat.id}
                                                                                onClick={() => this.handleSelectCategory(subcat)}
                                                                            >
                                                                                <span>
                                                                                    {subcat.title}
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>  
                                        </Fragment>
                                    );
                                })
                            }
                        </div>
                    </OutsideClickDetector>
                    <div className="mobile-category-toggle sticky-category-toggle">
                        <button
                            className={`btn btn-icon btn-violet scale-hover no-transition`}
                            onClick={() => this.handleCategoryToggle()}
                        >
                            <i className="far fa-ellipsis-h txt-xxlarge" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    skeletonLoading = () => {
        return (
            <div className="product-filter-category-list">
                <div className={`category-nav-list`}>
                    {[...Array(7)].map((cat, key) => {
                        return (
                            <div
                                className="category-container ph-item margin-0"
                                key={key}
                            >
                                <div className="ph-col-12 margin-0">
                                    <div className="ph-row margin-0">
                                        <div className={`ph-col-${key%2 === 0 ? '6' : '8'} margin-0`} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }
}

ProductFilter.propTypes = {
    categories: PropTypes.array,
    onClickCategory: PropTypes.func,
    activeCat: PropTypes.string,
    selectedCategory: PropTypes.object
};

export default ProductFilter;

/* eslint-disable no-undef */
const prod = {
	APP_KEY: process.env.REACT_APP_APP_KEY,
	API_URL: process.env.REACT_APP_API_URL,
	PAGE_SIZE: process.env.REACT_APP_PAGE_SIZE,
	PAGE_SIZE_COMPACT: process.env.REACT_APP_PAGE_SIZE_COMPACT,
	SITE_URL: process.env.REACT_APP_SITE_URL,
	ASSET_URL: process.env.REACT_APP_ASSET_URL,
	APP_URL: process.env.REACT_APP_APP_URL,
	FB_ID: process.env.REACT_APP_FB_ID,
	GOOGLE_ID: process.env.REACT_APP_GOOGLE_ID,
	REACT_APP_PAYPAL_CLIENT_ID_SANDBOX: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
	REACT_APP_PAYPAL_CLIENT_ID_PROD: process.env.REACT_APP_PAYPAL_CLIENT_ID_PROD,
	STORE_KEY: process.env.REACT_APP_STORE_KEY,
	PAYPAL_CID: process.env.REACT_APP_PAYPAL_CID,
	PAYPAL_ENV: process.env.REACT_APP_PAYPAL_ENV
};
const dev = {
	APP_KEY: process.env.REACT_APP_DEV_APP_KEY,
	API_URL: process.env.REACT_APP_DEV_API_URL,
	PAGE_SIZE: process.env.REACT_APP_DEV_PAGE_SIZE,
	PAGE_SIZE_COMPACT: process.env.REACT_APP_DEV_PAGE_SIZE_COMPACT,
	SITE_URL: process.env.REACT_APP_DEV_SITE_URL,
	ASSET_URL: process.env.REACT_APP_DEV_ASSET_URL,
	APP_URL: process.env.REACT_APP_DEV_APP_URL,
	FB_ID: process.env.REACT_APP_DEV_FB_ID,
	GOOGLE_ID: process.env.REACT_APP_DEV_GOOGLE_ID,
	PAYPAL_CLIENT_ID_SANDBOX: process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID_SANDBOX,
	PAYPAL_CLIENT_ID_PROD: process.env.REACT_APP_DEV_PAYPAL_CLIENT_ID_PROD,
	STORE_KEY: process.env.REACT_APP_DEV_STORE_KEY,
	PAYPAL_CID: process.env.REACT_APP_DEV_PAYPAL_CID,
	PAYPAL_ENV: process.env.REACT_APP_DEV_PAYPAL_ENV
};

const config = process.env.NODE_ENV === 'production'
  ? prod : dev;
	
export const APP_KEY = config.APP_KEY;
export const API_URL = config.API_URL;
export const PAGE_SIZE = config.PAGE_SIZE;
export const PAGE_SIZE_COMPACT = config.PAGE_SIZE_COMPACT;
export const SITE_URL = config.SITE_URL;
export const ASSET_URL = config.ASSET_URL;
export const APP_URL = config.APP_URL;
export const FB_ID = config.FB_ID;
export const GOOGLE_ID = config.GOOGLE_ID;
export const PAYPAL_CLIENT = {
	sandbox: process.env.REACT_APP_DEV_PAYPAL_CID,
	production: process.env.REACT_APP_PAYPAL_CID
};
export const PAYPAL_ENV = config.PAYPAL_ENV;
export const STORE_KEY = config.STORE_KEY;
import React from 'react';

const EllipsisLoader = ({dot="white"}) => {
    return (
        <div className={`ellipsis-loader dot-${dot}`}>
            <div />
            <div style={{animationDelay: "0.3s"}} />
            <div style={{animationDelay: "0.6s"}} />
        </div>
    )
};

export default EllipsisLoader;
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.products, action) {
    let slug = "";

    if (action.type.indexOf('---') > - 1) {
        action.type = action.type.split('---');
        slug = action.type[1];
        action.type = action.type[0] + action.type[2];
    }

    switch(action.type) {
        case types.LOAD_PRODUCTS + types.PENDING:
                return Object.assign({},
                    state,
                    {
                        list: [],
                        loading: true
                    }
                );
        case types.PRODUCTS_GET_FEATURED + types.PENDING:
        case types.FIND_PRODUCT + types.PENDING:
        case types.PRODUCTS_GET_NEW_ARRIVAL + types.PENDING:
        case types.PRODUCTS_GET_BEST_SELLER + types.PENDING: 
        case types.FIND_BY_TAG + types.PENDING:
            return Object.assign({},
                state,
                {
                    loading: true
                }
            );
        case types.PRODUCTS_GET_FEATURED + types.REJECTED:
        case types.LOAD_PRODUCTS + types.REJECTED:
        case types.FIND_PRODUCT + types.REJECTED:
        case types.PRODUCTS_GET_NEW_ARRIVAL + types.REJECTED:
        case types.PRODUCTS_GET_BEST_SELLER + types.REJECTED:
        case types.FIND_BY_TAG + types.REJECTED:
            return Object.assign({},
                state, 
                {
                    loading: false
                }
            );
        case types.FIND_BY_TAG + types.FULFILLED: 
            let tagged = state.tagged ? state.tagged : {};
            tagged = Object.assign({}, tagged, {[slug]: action.payload.data.list});
            return Object.assign({},
                state,
                {
                    loading: false,
                    tagged
                }
            );
        case types.PRODUCTS_GET_FEATURED + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    featured: action.payload.data
                },
                {
                    loading: false
                }
            );  
        case types.PRODUCTS_GET_BEST_SELLER + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    best_sellers: action.payload.data.data
                },
                {
                    loading: false
                }
            );
        case types.PRODUCTS_GET_NEW_ARRIVAL + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    new_arrivals: action.payload.data.data
                },
                {
                    loading: false
                }
            );
        case types.UNSELECT_PRODUCT:
            return Object.assign({},
                state,
                {
                    selected: {},
                },
                {
                    loading: false
                }
            );
        case types.FIND_PRODUCT + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    selected: action.payload.data.product
                },
                {
                    loading: false
                }
            );
        case types.LOAD_PRODUCTS + types.FULFILLED:
            return Object.assign({},
                state,
                {
                    list: action.payload.data.data
                },
                {
                    pagination: {
                        next: action.payload.data.next_page_url,
                        prev: action.payload.data.prev_page_url,
                        last_page: action.payload.data.last_page,
                        current_page: action.payload.data.current_page
                    }
                },
                {
                    loading: false
                }
            );
        case types.LOAD_RELATED_PRODUCTS + types.PENDING:
                return Object.assign({},
                    state,
                    {
                        related: {
                                    list: [],
                                    loading: true
                                }
                    }
                );
        case types.LOAD_RELATED_PRODUCTS + types.REJECTED:
                return Object.assign({},
                    state,
                    {
                        related: {
                            list: [],
                            loading: false
                        }
                    }
                );
        case types.LOAD_RELATED_PRODUCTS + "_RESET":
                return Object.assign({},
                    state,
                    {
                        related: {
                            list: [],
                            loading: true
                        }
                    }
                );
        case types.LOAD_RELATED_PRODUCTS + types.FULFILLED:
                return Object.assign({},
                    state,
                    {
                        related: {
                                    list: action.payload.data,
                                    loading: false
                                }
                    }
                );
        case types.GET_PRODUCT_BREADCRUMBS + types.PENDING:
                return Object.assign({},
                    state,
                    {
                        breadcrumbs: []
                    }
                );
        case types.GET_PRODUCT_BREADCRUMBS + types.REJECTED:
                return Object.assign({},
                    state,
                    {
                        breadcrumbs: []
                    }
                );
        case types.GET_PRODUCT_BREADCRUMBS + types.FULFILLED:
                return Object.assign({},
                    state,
                    {
                        breadcrumbs: action.payload.data
                    }
                );
        case types.CHANGE_PRODUCT_OBJECT: 
            return Object.assign({},
                state,
                {
                    selected: action.payload
                },
                {
                    loading: false
                }
            );
        case types.UPDATE_PRODUCT_REVIEW + types.FULFILLED: 
            return Object.assign({},
                state,
                {
                    selected: Object.assign(state.selected, {product_reviews: action.payload.data})
                },
                {
                    loading: false
                }
            );
        default: 
            return state;
    }
}
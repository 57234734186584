import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import logo from '../../assets/img/logo.png';

import {
        extractAssetObj,
        resetCart,
        resetOrder,
        resetShippingForm,
    } from '../../helpers/caboodleHelper';
import {
        updateOrderStatus
    } from '../../actions/checkout.actions';
import history from '../../History';

class OrderStatus extends React.PureComponent {
    state = {
        order: {}
    };

    componentWillMount() {
        this.setState({
            order: Object.assign({}, this.props.order)
        });
    }

    componentDidMount() {
        if (!this.props.order.hasOwnProperty("reference_token")) {
            history.push("/");
        }

        const {pathname} = this.props.location;
        let status = "error";
        
        if (pathname.indexOf("success") > -1) {
            status = "placed";
            resetOrder();
            resetCart();
        }
    }

    componentWillUnmount() {
        const {pathname} = this.props.location;
        if (pathname.indexOf("success") > -1) {
            resetOrder();
            resetShippingForm();
        }
    }
    
    render() {
        const { order } = this.state;
        const {pathname} = this.props.location;

        if (!order.reference_number) {
            return null;
        }

        let date = moment(order.date_delivery).format('ddd MMM D');
        let date_end = moment(order.date_delivery_end).format('ddd MMM D');

        return (
            <div className="order-status-page flex align-center justify-center">
                <div className="container text-center">
                    {
                        pathname.indexOf("failed") > -1 ? (
                            <>
                                <h1 className="txt-header margin-bottom-20">
                                    Order Failed!
                                </h1>
                                <p>
                                    Something went wrong with the connection. Please try again later.
                                </p>
                            </>
                        ) : (
                            <>
                                {/* <div className="text-center">
                                        <img src={logo} alt="logo" />
                                    </div> */}
                                <h1 className="txt-header margin-bottom-20">
                                    Thank you for your purchase!
                                </h1>
                                <p>
                                    Reference No. <span className="txt-warning bold">{order.reference_number}</span>
                                </p>
                                <p>
                                    We've sent a confirmation email to <span className="txt-success bold">{order.email_address}</span> with order details.
                                </p>
                                {order.date_delivery !== '0000-00-00 00:00:00' &&
                                    <p>
                                        Your order is estimated to be delivered on <span className="delivery-date">{date} to {date_end}</span>
                                    </p>
                                }
                                <ul className="order-image-list">
                                    {order && order.items.map((item, key) => {
                                        return (
                                            <li key={key} style={{ backgroundImage: `url(${extractAssetObj(item, ['product', 'image_paths', 'path'])})`}}/>
                                        );
                                    })}
                                </ul>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
	return {
        order: state.order
	};
}

OrderStatus.propTypes = {
    order: PropTypes.object
};

export default connect(mapStateToProps, null)(OrderStatus);


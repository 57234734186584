import React from 'react';

const AddressDetailsCard = ({
    fullname,
    firstname,
    lastname,
    city_text,
    custom_area,
    province_text,
    city_name,
    area_name,
    province_name,
    address_line_1,
    phone,
    zip,
    onClickEdit,
    pickup = false,
    pickupLocation,
    email
}) => {
    return (
        <div className="card-container margin-bottom-10">
            <h1 className="column-3 with-action">
                <span>
                    {pickup ? "Pickup Information" : "Shipping Address"}
                </span>
                <span className="action" onClick={onClickEdit}>
                    Edit
                </span>
            </h1>
            <div className="column-3 gray-bg pad-10">
                {
                    pickup ? (
                        <>
                            <div className="list-header">
                                Name: {fullname}
                            </div>
                            <div className="list-header">
                                Email: {email}
                            </div>
                            <div className="list-header">
                                Contact #: {phone}
                            </div>

                            <div className="address">
                                <div className="list-header">
                                    Pickup location:
                                </div>
                                {pickupLocation}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="list-header">
                                {firstname} {lastname}
                            </div>
                            <div className="address">
                                {address_line_1} {province_text || province_name} , {city_text || city_name} , {area_name} , {zip}
                            </div>
                            Mobile: {phone}
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default AddressDetailsCard;
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Checkbox from '@material-ui/core/Checkbox';
import TextInput from '../Form/TextInput';
import {
            validateFormObj,
            loginUser,
            userSocialMediaLogin,
            toggleRegistrationModal,
            toggleForgotPasswordModal,
            toggleModal
        } from '../../../helpers/caboodleHelper';

import * as config from '../../../constants/config';

class Login extends React.PureComponent {

    state = {
        loginForm: {
            email: "",
            password: "",
            remember: false
        },
        isLaoding: false,
        errorMessage: '',
        socialMediaOpen: false
    }

    componentDidMount() {}

    componentWillMount() {}

    onInputChange = (e) => {
        let loginForm = Object.assign({}, this.state.loginForm);
        
        if (e.target.name !== "remember") {
            loginForm[e.target.name] = e.target.value;
        } else {
            loginForm.remember = e.target.checked;
        }

        this.setState({
            loginForm
        });
    }

    openRegistrationModal() {
        toggleModal();
        setTimeout(() => {
            toggleRegistrationModal();
        }, 300);
    }
    
    openForgotPasswordModal() {
        toggleModal();
        setTimeout(() => {
            toggleForgotPasswordModal();
        }, 300);
    }

    doLogin = () => {
        const loginForm = Object.assign({}, this.state.loginForm),
                remember = loginForm.remember;
        
        delete loginForm.remember;

        this.setState({
            isLoading: true,
            errorMessage: ''
        }, () => {
            loginUser(loginForm)
                .then(res => {
                    toggleModal();
                    this.setState({
                        loginForm: {
                            email: "",
                            password: "",
                            remember: false
                        },
                        isLaoding: false,
                        errorMessage: ''
                    });
                }).catch(err => {
                    this.setState({
                        isLoading: false,
                        errorMessage: "Invalid email or password."
                    });
                });
        });
    }

    doSocialMediaLogin = (response, socialMedia) => {
        
        let creds = {
            social_media_name: socialMedia
        };

        if (socialMedia === "facebook") {
            creds.oauth_id = response.id;
            creds.firstname = response.first_name;
            creds.lastname = response.last_name;
            creds.email = response.email;
        } else {
            const {profileObj} = response;
            creds.oauth_id = profileObj.googleId;
            creds.firstname = profileObj.givenName;
            creds.lastname = profileObj.familyName;
            creds.email = profileObj.email;
        }

        creds.api_response = JSON.stringify(response);
        this.setState({
            isLoading: true,
            errorMessage: ''
        }, () => {
            userSocialMediaLogin(creds)
                .then(res => {
                    toggleModal();
                    this.setState({
                        loginForm: {
                            email: "",
                            password: "",
                            remember: false
                        },
                        isLaoding: false,
                        errorMessage: '',
                        socialMediaOpen: true
                    });
                }).catch(err => {
                    this.setState({
                        isLoading: false,
                        errorMessage: "Invalid email or password.",
                        socialMediaOpen: true
                    });
                });
        });
    }

    render() {
        return (
            <div className="login">
                <div className="icon-badge">
                    <i className="fal fa-sign-in-alt" />
                </div>
                <div className="login-header txt-header-2 text-center">
                    LOGIN
                </div>
                <div className="login-form">
                    <TextInput
                        placeholder="Email Address"
                        name="email"
                        value={this.state.loginForm.email}
                        onChange={this.onInputChange}
                        onKeyEnter={validateFormObj(this.state.loginForm) ? this.doLogin : false}
                    />
                    <TextInput
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={this.state.loginForm.password}
                        onChange={this.onInputChange}
                        onKeyEnter={validateFormObj(this.state.loginForm) ? this.doLogin : false}
                    />
                    <div className="text-center txt-error txt-small">
                        {this.state.errorMessage}
                    </div>
                    <div className="flex align-center login-options-container">
                        <div className="flex align-center remember-option">
                            {/* <Checkbox
                                onChange={this.onInputChange}
                                name="remember"
                                material-checkbox="primary"
                            />
                            <span className="txt-small">
                                Remember Me
                            </span> */}
                        </div>
                        <div className="text-right flex-1 forgot-password">
                            <a
                                className="arti-link txt-small clickable"
                                onClick={() => this.openForgotPasswordModal()}
                            >
                                Forgot Password
                            </a>
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-black btn-block btn-block-center btn-sqr submit-btn"
                            disabled={!validateFormObj(this.state.loginForm) || this.state.isLoading || this.state.socialMediaOpen}
                            onClick={() => this.doLogin()}
                        >
                            Login
                        </button>
                    </div>
                    <div className="flex align-center margin-top-20">
                        <div className="flex-1">
                            <FacebookLogin
                                appId={config.FB_ID}
                                autoLoad={false}
                                fields="first_name,last_name,name,email,picture"
                                scope="public_profile,email"
                                callback={(res) => this.doSocialMediaLogin(res, "facebook")}
                                onFailure={(err) => this.setState({socialMediaOpen: false})}
                                render={renderProps => (
                                    <button
                                        className="btn btn-fb btn-block btn-block-center btn-sqr"
                                        onClick={() => {
                                            this.setState({socialMediaOpen: true})
                                            renderProps.onClick();
                                        }}
                                        disabled={this.state.socialMediaOpen || this.state.isLoading}
                                    >
                                        <i className="margin-right fab fa-facebook-f" /> Login with Facebook
                                    </button>
                                )}
                            />
                        </div>
                        <div className="flex-1">
                            <GoogleLogin
                                clientId={config.GOOGLE_ID}
                                buttonText="Login"
                                onSuccess={(res) => this.doSocialMediaLogin(res, "google")}
                                onFailure={(err) => this.setState({socialMediaOpen: false})}
                                cookiePolicy={'single_host_origin'}
                                render={renderProps => (
                                    <button
                                        className="btn btn-google btn-block btn-block-center btn-sqr"
                                        onClick={() => {
                                            this.setState({socialMediaOpen: true})
                                            renderProps.onClick();
                                        }}
                                        disabled={this.state.socialMediaOpen || this.state.isLoading}
                                    >
                                        <i className="margin-right fab fa-google" /> Login with Google
                                    </button>
                                )}
                            />
                        </div>
                    </div>
                    <div className="registration-link text-center txt-small">
                        <span>Not a member? </span>
                        <a
                            className="arti-link clickable"
                            onClick={() => this.openRegistrationModal()}
                        >
                            <span className="txt-underlined">
                                Register
                            </span>
                        </a>
                    </div>
                </div>
            </div>
       )
    }
};

export default Login;
import React from 'react';
import { toggleModal } from '../../../helpers/caboodleHelper';

const SuccessMessage = ({title, message}) => {
    return(
        <div className="login">
            <div className="icon-badge">
                <i className="far fa-check" />
            </div>
            <div className="login-header txt-header-2 text-center">
                {title}
            </div>
            <div className="login-form">
                <div className="text-center">
                    {message}
                </div>
                <div className="text-center">
                    <button
                        className="btn btn-black btn-sqr btn-block btn-block-center submit-btn"
                        onClick={() => toggleModal()}
                    >
                        Okay
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SuccessMessage;
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { isEqual } from "lodash";
import {
        getCategoryTree,
        setSelectedCategory,
        setSelectedCategoryAndParents,
        loadProducts,
        makeASquare,
        extractAssetObj
    } from '../../helpers/caboodleHelper';
import {
        getRelatedProducts
    } from '../../actions/products.actions'
import CategoryFilter from './components/CategoryFilter';
import History from '../../History';
import FullWidthProductCard from '../_global/ProductCard/FullWidthProductCard';
import TextInput from '../_global/Form/TextInput';
import ProductList from '../_global/ProductList/ProductList';
import RelatedProductsSlider from '../_global/RelatedProducts/RelatedProductsSlider';

class Products extends React.Component {
    
    state = {
        sortBy: '',
        loading: false
    };

    componentDidMount () {
        const params = queryString.parse(this.props.location.search);

        // if (Object.keys(params).length === 0) {
        //     // this.filterByCategory(0, false, true);
        //     console.log(123);
        // } else {
            if (params.categories) {
                setSelectedCategory(params.categories).then(() => {
                    // eslint-disable-next-line
                    this.filterByCategory(this.props.categories.selected, (this.props.categories.selected.parent != 0));
                });
            }
            // this.props.getRelatedProducts({slug: ""}); 
            loadProducts(this.appendOrderByParams(params)).then(res => {
                // if (this.props.products.list.length > 0) {
                //     this.props.getRelatedProducts({slug: this.props.products.list[0].slug});
                // }
            });
        // }
        
        getCategoryTree();
        window.addEventListener("resize", this.initMakeASquare);
    }

    componentDidUpdate(oldProps) {
        let newParams = queryString.parse(this.props.location.search);
        let oldParams = queryString.parse(oldProps.location.search);
        const propsChanged = !isEqual(newParams, oldParams);

        if (propsChanged || oldProps.products.list.length === 0) {
            window.scrollTo(0, 0);
        }

        if (propsChanged) {
            this.setState({
                loading: true
            }, () => {
                // console.log(this.props.location.search);
                // this.props.getRelatedProducts({slug: ""}); 
                const findCategory = this.props.categories.list.find(cat => {return cat.slug === newParams.categories});
                if (findCategory) {
                    setSelectedCategory(findCategory.slug);
                }

                loadProducts(this.appendOrderByParams(newParams)).then(res => {
                    // if (this.props.products.list.length > 0) {
                    //     this.props.getRelatedProducts({slug: this.props.products.list[0].slug});   
                    // }

                    this.setState({
                        loading: false
                    });
                });
            });
        }
        
        this.initMakeASquare();
    }

    componentWillUnmount() {
        setSelectedCategory("");
        window.removeEventListener("resize", this.initMakeASquare);
    }
    
    initMakeASquare = () => {
        setTimeout(() => {
            makeASquare(document.getElementsByClassName(`product-container-image`));
        });
    }

    appendOrderByParams(params) {
        // params.sortBy = "created_at-DESC";

        return params;
    }

    onPaginate = (newPage) => {
        const { location } = this.props;
        let params = queryString.parse(location.search);
        
        params = Object.assign(params, {
            page: newPage //newPage['page'],
        });
                
        History.push({
            pathname: '/products',
            search: `?${queryString.stringify(params)}`
        });
    }

    filterByCategory = (category, newParents = false, sale = false, bestSellers = false) => {
        let search = queryString.parse(this.props.location.search);
        
        if (sale) {
            setSelectedCategory(category.slug);
            delete search.categories;
            delete search.best_seller;
            search.sale = 1;
        } else if (bestSellers) {
            setSelectedCategory(category.slug);
            delete search.categories;
            delete search.sale;
            search.best_seller = 1;
        } else {
            delete search.sale;
            delete search.best_seller;
            if (newParents) {
                setSelectedCategoryAndParents(category.slug);
            } else {
                setSelectedCategory(category.slug);
            }
            
            if (category.slug !== search['categories']) {
                search = Object.assign(search, {
                    categories: category.slug
                });
            }
    
            if (search.keyword) {
                this.setState({
                    keyword: search.keyword
                });
            }
        }
        
        delete search.page;
        delete search.keyword;

        History.push({
            pathname: '/products',
            search: `?${queryString.stringify(search)}`
        });
    }

    handleSort = (e) => {
        let params = queryString.parse(this.props.location.search);
        params = Object.assign(params, {
            sortBy: e.target.value
        });
        
        History.push({
            pathname: '/products',
            search: `?${queryString.stringify(params)}`
        });
    }

    getPage = () => {
        const { products } = this.props;
        if (!products || !products.pagination || !products.pagination.last_page) {
            return null;
        }

        if (products.pagination.last_page === 1) {
            return null;
        }

        const { current_page, last_page } = products.pagination;
        const to = last_page;
        const from = 1;
        const pages = [];

        for (let i = from; i <= to; i++) {
            pages.push(<div className={`${i === current_page ? 'active' : ''} margin-left margin-right txt-gray clickable page-link`} onClick={() => this.onPaginate(i)}>{i}</div>);
        }

        pages.push(
            <div
                className={`${current_page === 1 ? 'disabled' : ''}  page-arrow margin-left margin-right clickable page-link`}
                onClick={() => {
                    if (current_page === 1) {
                        return;
                    }
                    return this.onPaginate(current_page - 1);
                }}
            >
                <i className="far fa-angle-left" />
            </div>
        );
        pages.push(
            <div
                className={`${last_page === current_page ? 'disabled' : ''} page-arrow margin-left margin-right clickable page-link`}
                onClick={() => {
                    if (last_page === current_page) {
                        return;
                    }
                    return this.onPaginate(current_page + 1);
                }}
            >
                <i className="far fa-angle-right" />
            </div>
        );
        return pages;
    }

    render() {
        const { categories, products } = this.props;
        // const newestProduct = products.list.length > 0 ? products.list[0] : false;
        let queryParams = queryString.parse(this.props.location.search);
        const pagination = this.getPage();

        return (
            <div className="products-page page-body-container">
                <div className="page-container">
                    <div className="flex outer-container flex-rm-md">
                        <CategoryFilter
                            onClickCategory={this.filterByCategory}
                            categories={categories && categories.list}
                            selectedCategory={categories && categories.selected}
                            loading={categories && categories.loading}
                            queryParams={queryParams}
                        />
                        <div className="flex-1 main-products-content">
                            {
                                !this.state.loading && categories && categories.selected && categories.selected.asset != null && (
                                    <div
                                        className="category-header pad-50 flex align-center justify-center fade-in-up"
                                        key={"categoriesHeader" + categories.selected.id}
                                        style={{
                                            backgroundImage: `url(${extractAssetObj(categories.selected, ["asset", "path"])})`
                                        }}
                                    >
                                        <div className="text-center">
                                            {/* <h1 className="txt-oversized txt-white txt-bold">
                                                {categories.selected.title}
                                            </h1>
                                            <div
                                                className="txt-white txt-large"
                                                dangerouslySetInnerHTML={{__html: categories.selected.description}}
                                            /> */}
                                        </div>
                                    </div>
                                )
                            }
                            <div className="flex flex-end margin-top-50">
                                <div style={{width: "200px"}}>
                                    <TextInput
                                        type="select"
                                        value={queryParams.sortBy ? queryParams.sortBy : ''}
                                        onChange={this.handleSort}
                                        className="gray-control"
                                    >
                                        <option disabled value="">
                                            Sort by
                                        </option>
                                        <option value="price-asc">
                                            Price (Lowest to Highest)
                                        </option>
                                        <option value="price-desc">
                                            Price (Highest to Lowest)
                                        </option>
                                        <option value="title-asc">
                                            Alphabetical (A-Z)
                                        </option>
                                        <option value="title-desc">
                                            Alphabetical (Z-A)
                                        </option>
                                    </TextInput>
                                </div>
                            </div>
                            <div className="margin-top-100">
                                {
                                    (products && products.list && products.list.length === 0 && !products.loading) ? (
                                        <h1>
                                            No products found
                                        </h1>
                                    ) : (
                                        <ProductList
                                            loading={products.loading && products}
                                            products={products && products.list}
                                            placeholderCardLength={9}
                                            gridNumber={4}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center margin-top-50 margin-bottom-50">
                        <div className="flex align-center pagination-links">
                            {
                                pagination && pagination.map(page => {
                                    return (page);
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
        products: state.products,
        categories: state.categories
	};
}

Products.propTypes = {
    products: PropTypes.object,
    categories: PropTypes.object,
    getRelatedProducts: PropTypes.func
};

function mapDispatchToProps(dispatch) {
	return {
		getRelatedProducts: bindActionCreators(getRelatedProducts, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);


import React from 'react';
import moment from 'moment';
import {
    extractAssetObj
} from '../../../helpers/caboodleHelper';

import defaultImg from '../../../assets/img/default.png';

const ReviewCard = ({review, onFlagReview, userId, requestLoading}) => {
    let img = defaultImg

    if (review.customer_id > 0) {
        img = extractAssetObj(review.customer.user, ["asset", "path"], "path", defaultImg);
    }

    return (
        <div className="review-card flex">
            <div className="customer-details">
                <img src={img} className="customer-img" />
            </div>
            <div className="review-details flex-1 margin-left-20">
                <div className="flex align-center">
                    <div className="product-stars flex align-center">
                        {
                            [...Array(5)].map((star, index) => {
                                if ((index + 1) <= review.rating) {
                                    return (
                                        <i
                                            className="fas fa-star txt-xlarge scale-in"
                                            style={{
                                                animationDelay: `${(index * 0.2)}s`
                                            }}
                                            key={index}
                                        />
                                    );
                                } else {
                                    return (
                                        <i
                                            className="far fa-star txt-xlarge scale-in"
                                            style={{
                                                animationDelay: `${(index * 0.2)}s`
                                            }}
                                            key={index}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                    <div className="flex-1 flex flex-end">
                        <div className="txt-medium txt-gray">
                            {moment(review.create_at).format("MMM DD, YYYY")}
                        </div>
                    </div>
                </div>
                <div className="txt-medium">
                    {(review.customer_id === null || review.customer_id === 0) ? "Anonymous" : `${review.customer.firstname} ${review.customer.lastname}`}
                </div>
                <div className="margin-top-20 txt-large">
                    {review.content}
                </div>
                {
                    <div className="flex align-center flex-end margin-top-20">
                        {
                            review.flags.length > 0 && (
                                <div className="txt-error txt-small margin-right">
                                    This review was flagged ({review.flags.length} time{review.flags.length > 1 ? 's' : ''}) as a negative review.
                                </div>
                            )
                        }
                        {
                            userId && (
                                requestLoading ? (
                                    <i className="far fa-circle-notch fa-spin" />
                                ) : (
                                    <button
                                        className="btn btn-nostyles tooltipped"
                                        tooltiptext={checkUserFlagged(userId, review.flags) ? "You flagged this as a negative review" : "Flag this as a negative review"}
                                        onClick={() => onFlagReview(review.id)}
                                    >
                                        <i className={`fa${checkUserFlagged(userId, review.flags) ? 's' : 'r'} fa-flag txt-warning txt-large`} />
                                    </button>
                                )
                            )
                        }
                    </div>
                }
            </div>
        </div>
    );
};

//--- function to check if current user flagged this review
const checkUserFlagged = (userId, flags) => {
    let userFlagged = false;
    if (flags.length > 0 && userId) {
        const findUserFlags = flags.find(flag => {
                                    return flag.user_id === userId;
                                });
        
        if (findUserFlags) {
            userFlagged = true;
        }
    }
    
    return userFlagged;
}

export default ReviewCard;
import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.order, action) {
    switch(action.type) {
        case types.UPDATE_ORDER_OBJECT + types.PENDING:
            return Object.assign({},
                state,
                {
                    loading: true
                }
            );
        case types.UPDATE_ORDER_OBJECT + types.REJECTED:
            return Object.assign({},
                state, 
                {
                    loading: false
                }
            );
        case types.UPDATE_ORDER_OBJECT + types.FULFILLED:
            return Object.assign({},
                state,
                action.payload.data,
                {
                    loading: false
                }
            );
		case types.GET_SHIPPING_FEE + types.FULFILLED:
			return Object.assign({},
                state,
                {
					shipping_amount: action.payload.data.shipping_amount,
					shipping_zone_id: action.payload.data.shipping_zone_id
				}
            );
        case types.RESET_ORDER:
            return {}
        default: 
            return state;
    }
}
import React, { Component } from 'react';
import { Modal as BSModal } from 'reactstrap';
import PropTypes from 'prop-types';

class Modal extends Component {
    state = {
        animateOpen: false  
    }
    
    componentDidUpdate() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.props.isOpen && !this.state.animateOpen) {
            this.setState({
                animateOpen: true
            });
        } else if(!this.props.isOpen && this.state.animateOpen) {
            this.timeout = setTimeout(() => {
                this.setState({
                    animateOpen: false
                });
                this.timeout = null;
              }, 300);
        }
        
    }

    render() {
        const { children, isOpen, animateIn, animateOut } = this.props;
        const { animateOpen } = this.state;
        const userProps = Object.assign({} , this.props);
        delete userProps.className;
        delete userProps.animateIn;
        delete userProps.animateOut;

        return (
            <BSModal
                {...userProps}
				className={`modal-align-vertical animated ${isOpen ? animateIn : animateOut + ' closing'}`}
                isOpen={animateOpen}
                modalClassName={`${!isOpen ? 'pointer-events-none' : ''}`}
            >
                {children}
            </BSModal>
        );
    }
}

Modal.defaultProps = {
    animateIn: 'zoomIn',
    animateOut: 'zoomOut'
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string
    ]),
    isOpen: PropTypes.bool,
    animateIn: PropTypes.string,
    animateOut: PropTypes.string
};

export default Modal;
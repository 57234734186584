import { store } from '../index';
import * as apiHelper from '../helpers/apiHelper';
import * as types from '../constants/actionTypes';
// import { SITE_URL } from '../constants/config';

export function updateCustomerDetails(user) {
    return store.dispatch({
            type: types.UPDATE_USER,
            payload: user
    });
}

export function getCustomerDetails(identifiers) {
    return apiHelper.connect(false).post(`/customer/find`, identifiers);
}

export function removeWishlist(data) {
    return apiHelper.connect(false).post(`/customers/remove-wish-list`, data);
}

export function requestUpdate(data) {
    return apiHelper.connect(false).post(`/customers/update-details`, data);
}

export function createAddress(address) {
    return apiHelper.connect(false).post(`/customers/create-address`, address);
}

export function updateAddress(address) {
    return apiHelper.connect(false).post(`/customers/update-address`, address);
}

export function updatePassword(data) {
    return apiHelper.connect(false).post(`/customers/update-password`, data);
}

export function voidOrder(data) {
    return apiHelper.connect(false).post(`/orders/void`, data);
}